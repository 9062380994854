const DOMAIN_PREFIX = ''

export const STRINGS = {
  IBFT_ID: 'ZNJBaQBMy',
  AUTH_LOCAL_STORAGE_KEY: 'kt-auth-react-v',
  API_STATUS: {
    DEFAULT: 200,
    CREATE: 201,
    BAD_REQUEST: 400,
    FAIL: 1001,
    SUCCESS: 1000,
  },
  API_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IlpOSkJhUUJNeSIsImVtYWlsIjoibXVrYXJyYW1fc2hhcmlmQGhvdG1haWwuY29tIiwic3ViIjoibXVrYXJyYW1fc2hhcmlmQGhvdG1haWwuY29tIiwianRpIjoiNWZlOWM0MDgtNmNkZi00MmU3LTk1YjEtMDU0ZjU0MGYxZDlkIiwicm9sZSI6IlN5c3RlbSBBZG1pbiIsIm5iZiI6MTY3MTIxODQ0OSwiZXhwIjoxNjcxMjIyMDQ5LCJpYXQiOjE2NzEyMTg0NDl9.-eRvHvFe-6BQtfomof90agWub8i9uO9hoWyUHA177TM',
  ROUTES: {
    ROOT: `${DOMAIN_PREFIX}/`,
    AUTH: `${DOMAIN_PREFIX}/auth`,
    DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
    BRANCH_SCREEN: `${DOMAIN_PREFIX}/br`,
    CFC_SCREEN: `${DOMAIN_PREFIX}/cfc`,
    HISTORY_SCREEN: `${DOMAIN_PREFIX}/his`,
    CARD_EMBOSE: `${DOMAIN_PREFIX}/crdem`,
    ONE_LINK_PROCESS_DETAIL: `${DOMAIN_PREFIX}/crdem`,
    BRANCH: {
      INVENTORY: `${DOMAIN_PREFIX}/mis`,
      VIEW_MIS: `${DOMAIN_PREFIX}/mis/view_mis`,
      ADD_CARD: `${DOMAIN_PREFIX}/add_card`,
      CARDDETAILS: `${DOMAIN_PREFIX}/br_details`,
      INVENTORYADDCARD: `${DOMAIN_PREFIX}/br_inv/addcard`,
      EDITFORM: `${DOMAIN_PREFIX}/br_inv/edit`,
      SHREDFORM: `${DOMAIN_PREFIX}/shred`,
      DELIVERYFORM: `${DOMAIN_PREFIX}/delivery`,
      SENDTOCFCFORM: `${DOMAIN_PREFIX}/send_to_cfc`,
      SENDTOBRANCHFORM: `${DOMAIN_PREFIX}/send_to_branch`,
      MAIN: `${DOMAIN_PREFIX}/br_main`,
      HISTORY: `${DOMAIN_PREFIX}/br_his`,
      REQUEST_FORM: `${DOMAIN_PREFIX}/req_form`,
      MAIN_CARDS: `${DOMAIN_PREFIX}/card_main`,
      COMPLAINS: `${DOMAIN_PREFIX}/complains`,
      ADD_COMPLAINS: `${DOMAIN_PREFIX}/complains_add`,
      VIEW_COMPLAINS: `${DOMAIN_PREFIX}/complains_view`,
      UPDATE_MIS: `${DOMAIN_PREFIX}/mis/update_mis`,
    },
    CFC: {
      ADDCARDCFC: `${DOMAIN_PREFIX}/cfc/addcard`,
      MAIN: `${DOMAIN_PREFIX}/cfc`,
      DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      COMPLAINS: `${DOMAIN_PREFIX}/complains`,
      ADD_COMPLAINS: `${DOMAIN_PREFIX}/complains_add`,
      VIEW_COMPLAINS: `${DOMAIN_PREFIX}/complains_view`,
      INVENTORY: `${DOMAIN_PREFIX}/mis`,
      VIEW_MIS: `${DOMAIN_PREFIX}/mis/view_mis`,
      UPDATE_MIS: `${DOMAIN_PREFIX}/mis/update_mis`,
      ADD_MIS_RECORD: `${DOMAIN_PREFIX}/mis/add_mis_record`,
      CARD_TRACKING: `${DOMAIN_PREFIX}/card_tracking`,
    },
    ADMINISTRATOR: {
      ADMIN: `${DOMAIN_PREFIX}/admin`,
      CONFIGURE_USER_INFO: `${DOMAIN_PREFIX}/user_info`,
      CONFIGURE_DEPARTMENT: `${DOMAIN_PREFIX}/user_department`,
      CONFIGURE_DESIGNATION: `${DOMAIN_PREFIX}/user_designation`,
      CONFIGURE_BRANCH: `${DOMAIN_PREFIX}/user_branch`,
      CONFIGURE_CARD_TYPE: `${DOMAIN_PREFIX}/card_type`,
      CONFIGURE_NEW_REPLACEMENT: `${DOMAIN_PREFIX}/configure_new_replacement`,
      CONFIGURE_REGION: `${DOMAIN_PREFIX}/configure_region`,
      CONFIGURE_REQUEST_CHANNEL: `${DOMAIN_PREFIX}/configure_request_channels`,
      CONFIGURE_CARD_RETURNED: `${DOMAIN_PREFIX}/configure_card_returned`,
      CREATE_GROUP: `${DOMAIN_PREFIX}/create_group`,
      CREATE_ROLES: `${DOMAIN_PREFIX}/create_roles`,
      CARDS_TIMELINE: `${DOMAIN_PREFIX}/cards_timeline`,
      CALENDER: `${DOMAIN_PREFIX}/calender`,
      CONFIGURE_COUNTRY: `${DOMAIN_PREFIX}/configure_country`,
      CONFIGURE_PROVINCE: `${DOMAIN_PREFIX}/configure_province`,
      CONFIGURE_REGION: `${DOMAIN_PREFIX}/configure_region`,
      CONFIGURE_CITY: `${DOMAIN_PREFIX}/configure_city`,
      ON_APPROVAL: `${DOMAIN_PREFIX}/on_approval`,
    },
    SAMBACARE: {
      MAIN: `${DOMAIN_PREFIX}/sambacare`,
      INVENTORY: `${DOMAIN_PREFIX}/mis`,
      VIEW_MIS: `${DOMAIN_PREFIX}/mis/view_mis`,
      SAMBA_CARE_HISTORY: `${DOMAIN_PREFIX}/history`,
      COMPLAINS: `${DOMAIN_PREFIX}/complains`,
      ADD_COMPLAINS: `${DOMAIN_PREFIX}/complains_add`,
      VIEW_COMPLAINS: `${DOMAIN_PREFIX}/complains_view`,
    },
    CHECKER: {
      DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      MAIN_SCREEN: `${DOMAIN_PREFIX}/main`,
      HISTORY: `${DOMAIN_PREFIX}/history`,
      FORCE_TASK: `${DOMAIN_PREFIX}/force_task`,
    },
    MANAGEMENT: {
      // MANAGEMENT_DASHBOARD: `${DOMAIN_PREFIX}/dashboard`,
      MAIN: `${DOMAIN_PREFIX}/dashboard`,
      INVENTORY: `${DOMAIN_PREFIX}/mis`,
      VIEW_MIS: `${DOMAIN_PREFIX}/mis/view_mis`,
    },

    SETTINGS: {
      DEFAULT: `${DOMAIN_PREFIX}/settings`,
      CONFIG: {
        // CONFIGURE_INFO: `${DOMAIN_PREFIX}/settings/configure_info`,

        DEFAULT: `${DOMAIN_PREFIX}/settings/config`,
        MEMO: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/memo`,
          CARD_DES_MEMO: `${DOMAIN_PREFIX}/settings/config/memo/card_destruction_memo`,
          OTHER_BANK: `${DOMAIN_PREFIX}/settings/config/memo/samba_card_on_other_bank`,
          OTHER_BANK_CFC: `${DOMAIN_PREFIX}/settings/config/memo/other_bank_cfc_on_samba`,
        },
        MASTER: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/master`,
          CREATE_DESIGNATION: `${DOMAIN_PREFIX}/settings/config/master/create_designation`,
          USER_MANAGER: `${DOMAIN_PREFIX}/settings/config/master/user_manager`,
          GROUP_EMAIL: `${DOMAIN_PREFIX}/settings/config/master/group_email`,
        },
        ALERT_AND_ESCALATIONS: {
          DEFAULT: `${DOMAIN_PREFIX}/settings/config/alert_escalations`,
          CREATE_ALERT_DESINATION: `${DOMAIN_PREFIX}/settings/config/alert_escalations/create_alert_designation`,
          LIST: `${DOMAIN_PREFIX}/settings/config/create_alert_designation/list`,
        },
      },
    },
  },
}
