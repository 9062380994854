import {useState, useRef, FC, ChangeEvent, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../assets/ts/components'
import {useQuery} from "react-query";
// import {getProcessById} from "../../../../utils/_requests";
import {STRINGS} from "../../../../utils/Enums";
import DatePicker from "../../../../app/SharedComponent/DatePicker";
import {
    uploadFileRequest,
    getProcessById,
    startProcess,
    getProcessRunsById, resetRunProcessStart, resetPFilesList
} from "../../../../app/GlobalSlices/global.slice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, useAppSelector} from "../../../../utils/hook";
import {formatAMPM, getCurrentDate} from "../../../../utils/DefaultFunctions";

type Props = {
    show: boolean
    handleClose: () => void
}

type Slices = { globalSlice: any; };

const modalsRoot = document.getElementById('root-modals') || document.body

const StartNewProcessModal: FC<Props> = ({show, handleClose}) => {
    const dispatch = useDispatch<AppDispatch>();
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [fileId, setFileId] = useState<string>('')
    const [hasError, setHasError] = useState(false)
    const [selectedFile, setSelectedFile] = useState<File>();
    const {globalSlice} = useAppSelector(({Reducers}) => Reducers as Slices)
    // const {isLoading, data: list, error} = useQuery(
    //     `listing`,
    //     (e) => {
    //         return getProcessById(`${STRINGS.IBFT_ID}`)
    //     },
    //     {
    //         cacheTime: 0,
    //         enabled: true,
    //         onError: (err) => {
    //             console.error(err)
    //         },
    //     }
    // )

    useEffect(() => {
        if (selectedFile) {
            uploadFile()
        }
    }, [selectedFile])

    useEffect(() => {
        if (globalSlice.processFilesLists.length === 0) {
            dispatch(getProcessById(`${STRINGS.IBFT_ID}`))
        }
    }, [globalSlice.processFilesLists])

    useEffect(() => {
        if (globalSlice.startProcessSuccess) {
            handleClose();
            dispatch(getProcessRunsById(`${STRINGS.IBFT_ID}`));
            dispatch(resetRunProcessStart());
            dispatch(resetPFilesList([]));
        }
    }, [globalSlice.startProcessSuccess])

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    // const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    //     const updatedData = {...data, ...fieldsToUpdate}
    //     setData(updatedData)
    // }
    //
    const checkAppBasic = (): boolean => {
        // if (!data.appBasic.appName || !data.appBasic.appType) {
        //     return false
        // }
        return true
    }

    const checkAppDataBase = (): boolean => {
        // if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
        //     return false
        // }

        return true
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        stepper.current.goPrev()
    }

    const nextStep = () => {
        setHasError(false)
        if (!stepper.current) {
            return
        }

        if (stepper.current.getCurrentStepIndex() === 1) {
            if (!checkAppBasic()) {
                setHasError(true)
                return
            }
        }

        if (stepper.current.getCurrentStepIndex() === 3) {
            if (!checkAppDataBase()) {
                setHasError(true)
                return
            }
        }

        stepper.current.goNext()
    }

    const submit = () => {
        // window.location.reload()
    }

    const handleSubmit = () => {
        let processFilesIds = globalSlice.processFileIds.length !== 0 && globalSlice.processFileIds?.map((mp: any) => mp.process.id);
        let processStart = {
            "processId": `${STRINGS.IBFT_ID}`,
            "processFileIds": processFilesIds
        }
        dispatch(startProcess(processStart));
    }

    const handleOnChange = function (e: ChangeEvent<HTMLInputElement>, id: string) {
        const fileList = e.target.files;
        if (!fileList) return;
        setSelectedFile(fileList[0]);
        setFileId(id)
    }

    const uploadFile = function () {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile)
            formData.append('ProcessFileId', `${fileId}`)
            dispatch(uploadFileRequest(formData, fileId))
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px'
            show={show}
            onHide={handleClose}
            onEntered={loadStepper}
        >
            <div className="modal-header pb-0 border-0 justify-content-end">
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal"
                     onClick={() => {
                         handleClose();
                         dispatch(resetPFilesList([]))
                     }}>
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                    <span className="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                          transform="rotate(-45 6 17.3137)" fill="currentColor"/>
									<rect x="7.41422" y="6" width="16" height="2" rx="1"
                                          transform="rotate(45 7.41422 6)" fill="currentColor"/>
								</svg>
							</span>
                    {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
            </div>

            <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                {/*begin:Form*/}
                <div id="kt_modal_new_target_form" className="form">
                    {/*begin::Heading*/}
                    <div className="mb-100 text-center">
                        {/*begin::Title*/}
                        <h1 className="mb-3">Start New Process</h1>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="text-muted fw-semibold fs-5">Fill all the fields to start a new process
                            <a className="fw-bold link-primary"/>.
                        </div>
                        {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="d-flex flex-column fv-row">
                        {/*begin::Label*/}
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Date and Time</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                               title="Specify a target name for future usage and reference"/>
                        </label>
                        {/*end::Label*/}
                        <input disabled className="form-control form-control-solid"
                               placeholder={`${getCurrentDate()}, ${formatAMPM(new Date())}`}
                               name="due_date"/>
                    </div>
                    {/*end::Input group*/}
                    <br/>
                    {/*begin::Input group*/}
                    <div className="d-flex flex-column  fv-row">
                        {/*begin::Label*/}
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">File Upload</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                               title="All files are necessery to start a new process."/>
                        </label>
                        {/*end::Label*/}
                        <br/>
                        {/*begin::Form*/}
                        {/*begin::Form*/}
                        <div>
                            {/*begin::Input group*/}

                            {
                                globalSlice.processFilesLists.length !== 0 && (
                                    globalSlice.processFilesLists?.map(({id, fileName}: any) => {
                                        return (
                                            <div key={id} className="form-group " style={{padding: "5px"}}>
                                                {/*begin::Col*/}
                                                <input id={id} className="form-control form-control-solid" type="file"
                                                       onChange={(e) => handleOnChange(e, id)}
                                                       hidden/>
                                                <label htmlFor={id} className="btn btn-sm " id="custom-button"
                                                       style={{
                                                           backgroundColor: '#18a689',
                                                           color: 'white',
                                                           padding: '5px',
                                                           margin: '0 5px'
                                                       }}>{fileName}
                                                </label>
                                                {
                                                    globalSlice.processFileIds.length > 0 ?
                                                        globalSlice.processFileIds?.map((fl: any) => {
                                                            if (fl.process.isError === undefined && fl.selected_id === id) {
                                                                return (
                                                                    <span
                                                                        id="custom-text">Total Amount: {fl.process.totalAmount}, Total Records: {fl.process.totalRecords}</span>
                                                                )
                                                            }
                                                            if (fl.process.isError !== undefined && fl.selected_id === id) {
                                                                return (<span id="custom-text">{fl.process.message}</span>)
                                                            }
                                                        }) : <span id="custom-text">No file chosen, yet.</span>
                                                }
                                                {/*end::Col*/}
                                            </div>
                                        )
                                    })
                                )
                            }

                            {/*<div className="form-group " style={{padding: "5px"}}>*/}
                            {/*    /!*begin::Col*!/*/}
                            {/*    <input className="form-control form-control-solid" type="file" id="real-file"*/}
                            {/*           hidden/>*/}
                            {/*    <button className="btn btn-sm " type="button" id="custom-button"*/}
                            {/*            style={{backgroundColor: '#18a689', color: 'white', padding: '5px'}}>1-Link*/}
                            {/*        File*/}
                            {/*    </button>*/}
                            {/*    <span id="custom-text">No file chosen, yet.</span>*/}
                            {/*    /!*end::Col*!/*/}
                            {/*</div>*/}

                            {/*<div className="form-group " style={{padding: "5px"}}>*/}
                            {/*    /!*begin::Col*!/*/}
                            {/*    <input className="form-control form-control-solid" type="file" id="real-file2"*/}
                            {/*           hidden/>*/}
                            {/*    <button className="btn btn-sm" type="button" id="custom-button2"*/}
                            {/*            style={{backgroundColor: '#18a689', color: 'white', padding: '5px'}}>BPC*/}
                            {/*        ACQ File*/}
                            {/*    </button>*/}
                            {/*    <span id="custom-text2">No file chosen, yet.</span>*/}
                            {/*    /!*end::Col*!/*/}
                            {/*</div>*/}

                            {/*<div className="form-group " style={{padding: "5px"}}>*/}
                            {/*    /!*begin::Col*!/*/}
                            {/*    <input className="form-control form-control-solid" type="file" id="real-file3"*/}
                            {/*           hidden/>*/}
                            {/*    <button className="btn btn-sm" type="button" id="custom-button3"*/}
                            {/*            style={{backgroundColor: '#18a689', color: 'white', padding: '5px'}}>BPC*/}
                            {/*        ISS File*/}
                            {/*    </button>*/}
                            {/*    <span id="custom-text3">No file chosen, yet.</span>*/}
                            {/*    /!*end::Col*!/*/}
                            {/*</div>*/}

                            {/*<div className="form-group " style={{padding: "5px"}}>*/}
                            {/*    /!*begin::Col*!/*/}
                            {/*    <input className="form-control form-control-solid" type="file" id="real-file4"*/}
                            {/*           hidden/>*/}
                            {/*    <button className="btn btn-sm" type="button" id="custom-button4"*/}
                            {/*            style={{backgroundColor: '#18a689', color: 'white', padding: '5px'}}>EDGE-24*/}
                            {/*        File*/}
                            {/*    </button>*/}
                            {/*    <span id="custom-text4">No file chosen, yet.</span>*/}
                            {/*    /!*end::Col*!/*/}
                            {/*</div>*/}

                            {/*<div className="form-group " style={{padding: "5px"}}>*/}
                            {/*    /!*begin::Col*!/*/}
                            {/*    <input className="form-control form-control-solid" type="file" id="real-file5"*/}
                            {/*           hidden/>*/}
                            {/*    <button className="btn btn-sm" type="button" id="custom-button5"*/}
                            {/*            style={{backgroundColor: '#18a689', color: 'white', padding: '5px'}}>SAF*/}
                            {/*        File*/}
                            {/*    </button>*/}
                            {/*    <span id="custom-text5">No file chosen, yet.</span>*/}
                            {/*    /!*end::Col*!/*/}
                            {/*</div>*/}
                            {/*end::Input group*/}
                        </div>
                        {/*end::Form*/}
                        {/*end::Form*/}
                    </div>
                    {/*end::Input group*/}
                    <br/>
                    {/*begin::Input group*/}
                    <div className="row g-9">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Upload EDGE-24</span>
                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                               title="Specify a target priorty"/>
                        </label>
                        {/*begin::Col*/}
                        <div className="col-md-6 fv-row">
                            <label className="">Select Date</label>
                            {/*<script>*/}
                            {/*    $("#kt_datepicker_7").flatpickr({*/}
                            {/*    altInput: true,*/}
                            {/*    altFormat: "F j, Y",*/}
                            {/*    dateFormat: "Y-m-d",*/}
                            {/*    mode: "range"*/}
                            {/*});*/}
                            {/*</script>*/}
                            {/* <DatePicker className="form-control form-control-solid flatpickr-input"
                                        placeholder={"Pick date"} defaultDate={new Date()}/> */}
                            {/*<input className="form-control form-control-solid flatpickr-input"*/}
                            {/*       placeholder="Pick date" id="kt_datepicker_7" type="hidden" value=""/>*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-md-6 fv-row">
                            <label className="">Last Updated</label>
                            {/*begin::Input*/}
                            <div className="position-relative d-flex align-items-center">
                                {/*begin::Datepicker*/}
                                <input type="text" className="form-control form-control-solid"
                                       placeholder="18/05/2022 : 06:30 PM" name=""
                                       style={{pointerEvents: 'none'}}/>

                                {/*end::Datepicker*/}
                            </div>
                            {/*end::Input*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    <br/>
                    {/*begin::Actions*/}
                    <div className="text-center">
                        <button type="reset" id="kt_modal_new_target_cancel" onClick={() => {
                            handleClose();
                            dispatch(resetPFilesList([]))
                        }}
                                className="btn btn-light me-3">Cancel
                        </button>
                        {
                            (globalSlice.processFileIds?.filter((fl:any) => fl.process.isError === undefined).length !== 4) ? (
                                <span className="indicator-progress">You have to upload all files to start process
                                    {/*<span className="spinner-border spinner-border-sm align-middle ms-2"/>*/}
                                    </span>
                            ) : (!globalSlice.isPending) ?
                                <button onClick={handleSubmit} id="kt_modal_new_target_submit"
                                        className="btn btn-primar"
                                        style={{backgroundColor: '#18a689', color: 'white'}}>
                                    <span className="indicator-label">Start Process</span>
                                </button> : <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                                    </span>
                        }
                    </div>
                    {/*end::Actions*/}
                </div>
                {/*end:Form*/}
            </div>
        </Modal>,
        modalsRoot
    )
}
export {StartNewProcessModal}
