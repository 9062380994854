import {combineReducers} from "@reduxjs/toolkit";
import globalSlice from "../GlobalSlices/global.slice";
import authSlice from "../modules/auth/components/Slices/auth.slice";
import branchSlice from "../pages/BranchMain/BranchSlice/branch.slice";
import cfcSlice from "../pages/CFC/Slices/CFC.slice";

const Reducers = combineReducers({
    globalSlice: globalSlice,
    authSlice:authSlice,
    branchSlice:branchSlice,
    cfcSlice:cfcSlice
});

export default Reducers