import axios from 'axios'
import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import BranchComplainTable from '../../../SharedComponent/DataTable/BranchComplainTable'
import Loader from '../../../SharedComponent/Loader/Loader'
import {DatePicker} from 'antd' // Import DatePicker from Ant Design
import {API} from '../../../../utils/services'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {event} from 'jquery'
import {debounce} from 'lodash'
//import { GetCurrentUser } from '../../../utils/DefaultFunctions';
// filters = filtertypes,
//filterName=filtertype
//filtertype=filterwithkey
const {RangePicker} = DatePicker
const initalKeys = {
  value: '',
  label: '',
}
let initialSearch = {
  bankSearch: '',
  userSearch: '',
  atmSearch: '',
  complainSearch: '',
}

const Main = () => {
  //let auth = GetCurrentUser()
  let user = GetCurrentUser()
  const [complainCount, setComplainCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [searchParameter, setSearchParameter] = useState('')
  const [search, setSearch] = useState(initialSearch)
  const [misTypes, setMisTypes] = useState([])
  const [data, setData] = useState()
  const [loader, setLoader] = useState(true)
  const [refreshData, setRefreshData] = useState(true)
  const [filterType, setFilterType] = useState({})
  const [filterTypes, setFilterTypes] = useState([])
  const [filterWithKey, setFilterWithKey] = useState({})
  const [complainGet, setComplainGet] = useState()
  const PageSize = (value) => {
    setPageSize(value)
  }

  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const onChangeRangeDate = (dates) => {
    //dates[0].$d => date from
    //dates[1].$d => date to
    //here will set date from and to accordingly
    let requestDateFrom = filterType.columnKey
    let requestDateTo = requestDateFrom.replace('From', 'To')

    //setfilters
    setFilterWithKey({
      // [filterType.columnKey]: dayjs(dates[0].$d).format('DD-MM-YYYY'),
      // [requestDateTo]: dayjs(dates[1].$d).format('DD-MM-YYYY'),
      [filterType.columnKey]: dates[0].$d,
      [requestDateTo]: dates[1].$d,
    })
  }
  let auth = GetCurrentUser()
  const getColumns = () => {
    API.get(`/UserTaskMIS/columns`, {
      headers: {
        Authorization: `Bearer ${auth.api_token}`,
      },
    })
      .then((res) => {
        setFilterTypes(res.data.data)
      })
      .catch((err) => {
        console.log('err:', err)
      })
  }

  //let auth = GetCurrentUser()
  const getMisTypes = () => {
    if (auth && auth.api_token) {
      API.get(`Complaint`, {
        headers: {
          Authorization: `Bearer ${auth.api_token}`,
        },
      })
        .then((res) => {
          console.log('umar don', res)
          setMisTypes(res.data.data.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  useEffect(() => {
    getMisTypes()
    getColumns()

    //getMisTypes()
  }, [])
  //const handleFilterNameChange = (event) => {
  //if (event.target.value) {
  //   setFilterType(event.target.value);
  //   }
  // };

  // const handleFilterTypeChange = (event) => {
  //  if (event.target.value) {
  //      setFilterWithKey(event.target.value);
  //  }
  //};

  const setSearchFunc = useCallback(
    debounce((val, key) => {
      setSearch((prev) => {
        return {
          ...prev,
          [key]: val,
        }
      })
    }, 300),
    []
  )

  const getComplains = () => {
    API.get(
      `/Complaint?PageNumber=${pageNumber}&PageSize=${pageSize}&${searchParameter}=${search.complainSearch}`,
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        setComplainCount(res.data.data.totalRecords)

        // setUserCount(res.data.result.totalRecords)
        // setUserGet(res.data.result.data.$values)
        setComplainGet(res.data.data.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    getComplains()
  }, [search.complainSearch, pageNumber, pageSize])

  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/*<!--begin::Content container-->*/}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/*<!--begin::Tables Widget 12-->*/}
          <div class='card mb-5 mb-xl-8'>
            {/*<!--begin::Header-->*/}
            <div class='card-header border-0 pt-5'>
              <h3 class='card-title align-items-start flex-column'>
                <span class='card-label fw-bold fs-3 mb-1'>Details</span>
              </h3>
              {/* <div className='d-flex flex-column'>
                <label for='exampleInputEmail1' class='form-label'>
                  <strong>
                    <strong>Filter </strong>
                  </strong>
                </label>
                {filterType.column_type === 'date' ? (
                  // Render RangePicker for date columns
                  <RangePicker style={{height: '3em'}} onChange={onChangeRangeDate} />
                ) : (
                  // Render text input for other column types
                  <input
                    className='form-control form-control-solid flatpickr-input'
                    placeholder='Search'
                    style={{border: '1px solid darkgrey'}}
                    type='text'
                    onChange={(e) => {
                      //TODO: set filter with key filters
                      setFilterWithKey({
                        [filterType.columnKey]: e.target.value,
                      })
                    }}
                    disabled={!Object.keys(filterType).length ? true : false}
                    value={filterWithKey?.columnKey}

                    //value={filterType}

                    //onChange={handleFilterNameChange}

                    // Update filter value when input changes
                    //setFilterWithKey({
                    //[filterType.columnKey]: e.target.value,
                  />
                )}
              </div> */}

              <div class='card-toolbar'>
                {/*<!--begin::Menu-->*/}
                <i
                  class='fa fa-refresh my-1'
                  style={{
                    color: 'black',
                    fontSize: '24px',
                    cursor: 'pointer',
                    margin: '0px 1rem',
                    marginRight: '50px',
                  }}
                  aria-hidden='true'
                  onClick={() => {
                    setRefreshData(!refreshData)
                    // handleResetClick()
                    setLoader(true)
                  }}
                />
                <div
                  className='my-5 d-flex justify-content-end align-items-end me-5'
                  style={{marginLeft: '-2rem'}}
                >
                  <div style={{marginTop: '1rem'}}>
                    <select
                      class='form-select me-3 form-select-solid'
                      data-control='select2'
                      onChange={(e) => setSearchParameter(e.target.value)}
                      // value={searchParameter}
                      data-placeholder='Select Parameter'
                      data-hide-search='true'
                    >
                      <option selected hidden>
                        Select Parameter
                      </option>
                      <option value='customerName'>Customer Name</option>
                      <option value='cardNo'>Card Number</option>
                      <option value='accountNo'>Account No</option>
                      <option value='cnicNo'>CNIC</option>
                      <option value='cellNo'>Complain ID</option>
                      <option value='originator'>Originator</option>
                      <option value='complaintID'>System Complaint ID</option>
                    </select>
                  </div>
                  <div className='ms-3' style={{marginTop: '1rem'}}>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>Search</strong>
                    </label>
                    <div className='me-3'>
                      <input
                        class='form-control form-control-solid flatpickr-input'
                        placeholder='Search'
                        type='text'
                        onChange={(e) => setSearchFunc(e.target.value, 'complainSearch')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--end::Header-->*/}
            {/*<!--begin::Body-->*/}
            <div class='card-body py-3'>
              {/*<!--begin::Table container-->*/}
              <div class='table-responsive'>
                {/*<!--begin::Table-->*/}
                <BranchComplainTable
                  data={complainGet}
                  refreshData={refreshData}
                  loader={loader}
                  PageSize={PageSize}
                  PageNumber={PageNumber}
                  totalRecords={complainCount}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
                {/*<!--end::Table-->*/}
              </div>
              {/*<!--end::Table container-->*/}
            </div>
            {/*<!--begin::Body-->*/}
          </div>
        </div>
        {/*<!--end::Content container-->*/}
      </div>
    </>
  )
}

export default Main
