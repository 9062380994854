import {createSlice} from '@reduxjs/toolkit'
import {GetCurrentUser} from '../../utils/DefaultFunctions'
import {STRINGS} from '../../utils/Enums'
import {API} from '../../utils/services'

let user = GetCurrentUser()

export const uploadFileRequest = (formData, processId, type, token) => (dispatch) => {
  let url = ''

  if (type == 'shred') {
    url = `/Card/${processId}/discard/Upload`
  }
  if (type == 'sendToOrganization') {
    url = `/Card/${processId}/sendtoorganization/Upload`
  }
  if (type == 'sendToCfc') {
    url = `/Card/${processId}/sendTocfc/Upload`
  }
  if (type == 'sendToOtherCfc') {
    url = `/Card/${processId}/sendTootherbankcfc/Upload`
  }
  if (type == 'deliverToCustomer') {
    url = `/Card/${processId}/delivertocustomer/Upload`
  }
  if (type == 'sendToBranch') {
    url = `/Card/${processId}/sendtobranch/Upload`
  }

  url &&
    API.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      console.log('RESPONSEE Deliver TO customer ', res)
    })
}

export const startProcess = (data) => (dispatch) => {
  dispatch(requestPending(true))
  API.post(`/api/ProcessRun/Start`, data)
    .then((res) => {
      // console.log("globalSlice", res)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessRunStart(res.data))
        alert(res.data.message)
      } else {
        // console.log("GetUpload",res)
      }
    })
    .catch((ex) => {
      // console.log("globalSlice", ex)
      alert('Something went wrong')
      dispatch(requestPending(false))
      // dispatch(resetUploadFileResponse(true));
      // dispatch(isError(ex))
    })
}

export const getProcessById = (id) => (dispatch) => {
  API.get(`/api/ProcessFile/${id}`)
    .then((res) => {
      // console.log("globalSlice", res)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessFilesList(res.data))
      } else {
        // console.log("GetUpload",res)
      }
    })
    .catch((ex) => {
      // console.log("globalSlice", ex)
      alert('Something went wrong')
      // dispatch(resetUploadFileResponse(true));
      // dispatch(isError(ex))
    })
}

export const getProcessDetailById = (id) => (dispatch) => {
  API.get(`/api/ProcessRun/${id}`)
    .then((res) => {
      // console.log('globalSlice', res)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessDetails(res.data))
      } else {
        // console.log("GetUpload",res)
      }
    })
    .catch((ex) => {
      console.log('globalSlice', ex)
      alert('Something went wrong')
      // dispatch(resetUploadFileResponse(true));
      // dispatch(isError(ex))
    })
}

export const getProcessRunsById = (id) => (dispatch) => {
  API.get(`/api/ProcessRuns/${id}`)
    .then((res) => {
      // console.log("globalSlice", res)
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setProcessRunsList(res.data))
      } else {
        // console.log("GetUpload",res)
      }
    })
    .catch((ex) => {
      // console.log("globalSlice", ex)
      alert('Something went wrong')
      // dispatch(resetUploadFileResponse(true));
      // dispatch(isError(ex))
    })
}
export const GetAllDashboardData = (api_token, captBranchId) => (dispatch) => {
  // console.log('Capture Id ', captBranchId)
  // console.log('Capture tokennn ', api_token)
  if (captBranchId) {
    API.get(`/Card/dashboardBranchData?branchId=${captBranchId}`, {
      headers: {
        Authorization: `Bearer ${api_token}`,
      },
    })
      .then((res) => {
        // console.log('globalSlice============', res)
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
          dispatch(setDashboardDataList(res.data.result.$values))
        }
      })
      .catch((ex) => {
        alert('Something went wrong')
      })
  }
  if (captBranchId === undefined) {
    API.get(`/Card/dashboardCFCData`, {
      headers: {
        Authorization: `Bearer ${api_token}`,
      },
    })
      .then((res) => {
        if (res.status === STRINGS.API_STATUS.DEFAULT) {
          dispatch(setDashboardDataList(res.data.result.$values))
        }
      })
      .catch((ex) => {
        alert('Something went wrong')
      })
  }
}

export const GetAllDashboardBranches = (api_token, captBranchId) => (dispatch) => {
  API.get(`/Branch/getCurrentUserBranches`, {
    headers: {
      Authorization: `Bearer ${api_token}`,
    },
  })
    .then((res) => {
      if (res.status === STRINGS.API_STATUS.DEFAULT) {
        dispatch(setDashboardBranchDataList(res.data.result.$values))
      }
    })
    .catch((ex) => {
      alert('Something went wrong')
    })
}

export const MisTask = () => (dispatch) => {
  API.get(`UserTasks`).then((res) => {
    dispatch(misData(res.data.data))
  })
}

export const confirmation = (payload) => async (dispatch) => {
  dispatch(confirmDialog(payload))
}
export const openSnackBar = (payload) => async (dispatch) => {
  dispatch(setSnackBar(payload))
}
export const resetUploadResponse = (payload) => async (dispatch) => {
  dispatch(resetUploadFileResponse(payload))
}
export const resetRunProcessStart = (payload) => async (dispatch) => {
  dispatch(resetProcessRunStart(payload))
}
export const resetPFilesList = (payload) => async (dispatch) => {
  dispatch(resetProcessFilesList(payload))
}

export const resetDashboard = (payload) => async (dispatch) => {
  dispatch(resetDashboardData(payload))
}

export const misData = (payload) => async (dispatch) => {
  dispatch(setMisData(payload))
}

export const shredFormSlice = (payload) => async (dispatch) => {
  dispatch(setShredded(payload))
}

const initialState = {
  DashboardData: [],
  DashboardBranchesData: [],
  isError: false,
  message: '',
  variant: '',
  selectedId: '',
  isPending: false,
  startProcessSuccess: false,
  // ---confirmation---
  confirmationText: '',
  openConfirmationDialog: false,
  alertIcon: '',
  alertBtnText: '',
  showCancelButton: false,
  //
  fileUploadFailed: false,
  fileUploadSuccess: null,
  processFileIds: [],
  processFilesLists: [],
  processRunsList: [],
  processDetails: {},
  snackProps: {
    open: false,
    duration: 4000,
    message: '',
    action: false,
    actionLbl: 'Submit',
  },
  misDataResponse: [],
  isShredded: false,
}

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setDashboardDataList: (state, {payload}) => {
      state.DashboardData = payload
    },
    setDashboardBranchDataList: (state, {payload}) => {
      state.DashboardBranchesData = payload
    },
    confirmDialog: (state, {payload}) => {
      state.confirmationText = payload.message
      state.openConfirmationDialog = payload.open
      state.alertIcon = payload.alertIcon
      state.showCancelButton = payload.showCancelButton
      state.alertBtnText = payload.alertBtnText
    },
    ErrorAlert: (state, {payload}) => {
      state.isError = true
    },
    requestPending: (state, {payload}) => {
      state.isPending = payload
    },
    selectStatus: (state, {payload}) => {
      state.selectedBox = payload
    },
    setDeviceIp: (state, {payload}) => {
      state.deviceIPAddress = payload.data.ip
    },
    setProcessDetails: (state, {payload}) => {
      state.processDetails = payload.data
    },
    uploadFileSuccess: (state, {payload}) => {
      state.fileUploadSuccess = true
      state.fileData = payload.data
      state.selectedId = payload.selectedId
      state.processFileIds = [
        ...state.processFileIds.filter((fl) => fl.selected_id !== payload.selectedId),
        {
          process: payload.data.data,
          selected_id: payload.selectedId,
        },
      ]
    },
    setProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload.data
    },
    resetProcessFilesList: (state, {payload}) => {
      state.processFilesLists = payload
      state.processFileIds = payload
    },
    setProcessRunsList: (state, {payload}) => {
      state.processRunsList = payload.data
    },
    setProcessRunStart: (state, {payload}) => {
      state.isPending = false
      state.startProcessSuccess = true
    },
    resetProcessRunStart: (state, {payload}) => {
      state.startProcessSuccess = false
    },
    uploadBulkFilesSuccess: (state) => {
      state.fileBulkUploadSuccess = true
    },
    resetUploadFileResponse: (state, {payload}) => {
      state.fileUploadFailed = payload.failed
      state.fileUploadSuccess = null
      state.PO_ID = null
    },
    resetUploadBulkFilesResponse: (state) => {
      state.fileBulkUploadSuccess = false
    },
    setGeneratedOtp: (state, {payload}) => {
      state.otp_generated = payload === 0
    },
    setVerifiedOtp: (state, {payload}) => {
      state.otp_verified = payload === 0
    },
    resetDashboardData: (state, {payload}) => {
      state.DashboardData = []
    },
    resetOtp: (state) => {
      state.otp_verified = null
      state.otp_generated = null
    },
    setSnackBar: (state, {payload}) => {
      state.snackProps = {
        open: payload.open,
        message: payload.message,
        duration: payload.duration === undefined ? 4000 : state.snackProps.duration,
        action: payload.action !== undefined ? payload.action : state.snackProps.action,
        actionLbl: payload.actionLbl !== undefined ? payload.actionLbl : state.snackProps.actionLbl,
      }
    },
    setMisData: (state, {payload}) => {
      state.misDataResponse = payload
    },
    setShredded: (state, action) => {
      state.isShredded = action.payload
    },
  },
})

export const {
  requestPending,
  selectStatus,
  confirmDialog,
  setSnackBar,
  uploadFileSuccess,
  resetUploadFileResponse,
  uploadBulkFilesSuccess,
  resetUploadBulkFilesResponse,
  setVerifiedOtp,
  setProcessFilesList,
  resetProcessFilesList,
  setProcessRunsList,
  setProcessRunStart,
  resetProcessRunStart,
  setProcessDetails,
  setDashboardDataList,
  setDashboardBranchDataList,
  resetOtp,
  resetDashboardData,
  setMisData,
  setShredded,
} = globalSlice.actions

export default globalSlice.reducer
