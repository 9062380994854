import {createSlice} from '@reduxjs/toolkit'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {API} from '../../../../utils/services'
import {uploadFileRequest} from '../../../GlobalSlices/global.slice'
// import { uploadFileRequest } from '../../../data/global.slice'

const initialState = {
  CFCMessage: '',
  cardDetails: {},
  cardHoldResponse: '',
  sendToBranchResp: '',
  sendToCFCResp: '',
  sendToBranchSaveResp: '',
  sendToCFCSaveResp: '',
  sendToBranchSubmitResp: '',
  sendToCFCSubmitResp: '',
  receiveAtBranchResp: false,
  aging: 0,
  cardAddCFCResult: false,
  cardAddMessage: '',
  cardActiveData: {},
  sendToOtherCFCResp: '',
  sendToOtherCFCSaveResp: '',
  sendToOtherCFCSubmitResp: '',
  receiveAtCfcResp: '',
  sendToOrganizationPrintResp: '',
  sendToOrganizationSaveResp: '',
  sendToOrganizationSubmitResp: '',
}

let user = GetCurrentUser()

export const CardDetailsById = (id, token) => (dispatch) => {
  API.get(`/UserTaskMIS/getById?Id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    dispatch(CardDetails(res.data.data))
  })
}

export const AddCardCFC = (data, token) => (dispatch) => {
  let {
    convertedDate,
    receivingDateISO,
    customerName,
    number,
    expiry,
    cif,
    cnic,
    cardStatusId,
    currentCardTypeId,
    issuerBranchId,
    cfcId,
    currentCardChannelId,
    reason,
    acquirerBankId,
    acquirerBranchId,
    issuerBankId,
    note,
  } = data

  API.post(
    '/card/create',
    {
      capturedDate: '',
      capturedBranchId: '',
      customerName: customerName,
      cardNumber: `${number}`,
      cardExpiry: '',
      cifNumber: `${cif}`,
      // "cardTypeId":currentCardTypeId,
      cnic: `${cnic}`,
      nativeBranchId: issuerBranchId,
      cardOwnerBankId: '',
      cfcId: cfcId,
      // "cardStatusId": '',
      // "reasonForCaptured": reason,
      notes: note,
      address1: 'testing',
      address2: 'testing',
      cellNumber: '91019201902',
      accountNumber: '2782738237283782',

      // "receivingDate": receivingDateISO,
      cardChannelId: '',
      cardAcquirerBankId: '',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(async (res) => {
    dispatch(CardAddResponse(res.data))
  })
}

export const CardActiveModal =
  ({note, id, token, toBeHot}) =>
  (dispatch) => {
    // debugger;
    API.put(
      `/Card/process/tobehot/submit`,
      {
        capturedCardId: id,
        tobeHot: toBeHot,
        note: note,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(CardActive(res.data))
    })
  }
export const CardHoldModal =
  ({values, id, token}) =>
  (dispatch) => {
    API.put(
      `/Card/process/hold/save`,
      {
        capturedCardId: id,
        reasonForHold: values.reason,
        daysToHold: values.days_to_hold,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      // console.log("res",res);
      dispatch(CardHold(res.data))
    })
  }

export const CardAddResponse = (payload) => async (dispatch) => {
  dispatch(setCardAddResponse(payload))
}

export const SendToOrganizationPrint = (id, token) => (dispatch) => {
  const isoStr1 = new Date().toLocaleString()
  const date1 = new Date(isoStr1)

  API.put(
    `/Card/process/sendtoorganization/print`,
    {
      capturedCardId: id,
      printDate: date1,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    dispatch(SendToOrganizationPrintData(res.data))
  })
}
export const SendToOtherCFCPrint = (id, token) => (dispatch) => {
  const isoStr1 = new Date().toLocaleString()
  const date1 = new Date(isoStr1)

  API.put(
    `/Card/process/sendtootherbankcfc/print`,
    {
      capturedCardId: id,
      printDate: date1,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    dispatch(SendToOtherCFCPrintData(res.data))
  })
}
export const SendToCFCPrint = (id, token) => (dispatch) => {
  const isoStr1 = new Date().toLocaleString()
  const date1 = new Date(isoStr1)

  API.put(
    `/Card/process/sendtocfc/print`,
    {
      capturedCardId: id,
      printDate: date1,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    dispatch(SendToCFCPrintData(res.data))
  })
}

export const SendToBranchPrint = (id, token) => (dispatch) => {
  const isoStr1 = new Date().toLocaleString()
  const date1 = new Date(isoStr1)

  API.put(
    `/Card/process/sendtobranch/print`,
    {
      capturedCardId: id,
      printDate: date1,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    dispatch(SendToBranchPrintData(res.data))
  })
}

export const SendToOrganizationSave = (id, note, token, selectedFile) => (dispatch) => {
  API.put(
    `/Card/process/sendtoorganization/save`,
    {
      capturedCardId: id,
      note: note,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileRequest(formData, res.data.data, 'sendToOrganization', token))

    dispatch(SendToOrganizationSaveData(res.data))
  })
}
export const SendToOtherCFCSave = (id, note, token, selectedFile) => (dispatch) => {
  API.put(
    `/Card/process/sendtootherbankcfc/save`,
    {
      capturedCardId: id,
      note: note,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileRequest(formData, res.data.data, 'sendToOtherCfc', token))

    dispatch(SendToOtherCFCSaveData(res.data))
  })
}
export const SendToCFCSave = (id, note, token, selectedFile) => (dispatch) => {
  API.put(
    `/Card/process/sendtocfc/save`,
    {
      capturedCardId: id,
      note: note,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    dispatch(uploadFileRequest(formData, res.data.data, 'sendToCfc', token))

    dispatch(SendToCFCSaveData(res.data))
  })
}

export const SendToBranchSave = (id, note, token, selectedFile) => (dispatch) => {
  API.put(
    `/Card/process/sendtobranch/save`,
    {
      capturedCardId: id,
      note: note,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((res) => {
    const formData = new FormData()
    formData.append('file', selectedFile)

    dispatch(uploadFileRequest(formData, res.data.data, 'sendToBranch', token))

    dispatch(SendToBranchSaveData(res.data))
  })
}

export const SendToOtherCFCSubmit =
  (id, values, riderName, courierCompany, token, selectedRecord, date) => (dispatch) => {
    API.put(
      `/Card/process/sendtootherbankcfc/courier`,
      {
        capturedCardId: id,
        fromCourier: selectedRecord == 1 ? true : false,
        fromBranch: selectedRecord == 2 ? true : false,
        courierCompany: courierCompany,
        courierNumber: values.courierNumber,
        riderName: riderName,
        dispatchTime: date,
        // dispatchTime: values.dispatchTime ? values.dispatchTime : values.dispatchTimeRider,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(SendToOtherCFCSubmitData(res.data))
    })
  }
export const SendToOrganizationSubmit =
  (id, values, riderName, courierCompany, token, selectedRecord, date) => (dispatch) => {
    API.put(
      `/Card/process/sendtoorganization/courier`,
      {
        capturedCardId: id,
        fromCourier: selectedRecord == 1 ? true : false,
        fromBranch: selectedRecord == 2 ? true : false,
        courierCompany: courierCompany,
        courierNumber: values.courierNumber,
        riderName: riderName,
        // dispatchTime: values.dispatchTime ? values.dispatchTime : values.dispatchTimeRider,
        dispatchTime: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(SendToOrganizationSubmitData(res.data))
    })
  }
export const SendToCFCSubmit =
  (id, values, riderName, courierCompany, token, selectedRecord, date) => (dispatch) => {
    // alert("ASdasd")

    API.put(
      `/Card/process/sendtocfc/courier`,
      {
        capturedCardId: id,
        fromCourier: selectedRecord == 1 ? true : false,
        fromBranch: selectedRecord == 2 ? true : false,
        courierCompany: courierCompany,
        courierNumber: values.courierNumber,
        riderName: riderName,
        // dispatchTime: values.dispatchTime ? values.dispatchTime : values.dispatchTimeRider,
        dispatchTime: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(SendToCFCSubmitData(res.data))
    })
  }
export const SendToBranchSubmit =
  (id, values, riderName, courierCompany, token, selectedRecord, date) => (dispatch) => {
    API.put(
      `/Card/process/sendtobranch/courier`,
      {
        capturedCardId: id,
        fromCourier: selectedRecord == 1 ? true : false,
        fromBranch: selectedRecord == 2 ? true : false,
        courierCompany: courierCompany,
        courierNumber: values.courierNumber,
        riderName: riderName,
        //   dispatchTime: values.dispatchTime ? values.dispatchTime : values.dispatchTimeRider,
        dispatchTime: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(SendToBranchSubmitData(res.data))
    })
  }

export const ReceiveAtCfc =
  (cardDetails, values, record, capturedBranchId, token, info) => (dispatch) => {
    API.put(
      `/Card/process/receivedatcfc/save`,
      {
        fromBranchId: capturedBranchId ? capturedBranchId : null,
        capturedCardId: cardDetails.id,
        fromCourier: record == 1 ? true : false,
        fromBranch: record == 2 ? true : false,
        courierCompany: '',
        courierNumber: info ? info : values.courier_num,
        riderName: info ? info : values.rider_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(ReceiveAtCfcFunc(res.data))
    })
  }
export const ReceiveAtBranch =
  (cardDetails, values, record, capturedBranchId, token, info) => (dispatch) => {
    API.put(
      `/Card/process/receivedatbranch/save`,
      {
        fromBranchId: capturedBranchId ? capturedBranchId : null,
        capturedCardId: cardDetails.id,
        fromCourier: record == 1 ? true : false,
        fromBranch: record == 2 ? true : false,
        courierCompany: '',
        courierNumber: info ? info : values.courier_num,
        riderName: info ? info : values.rider_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      dispatch(ReceiveAtBranchFunc(res.data))
    })
  }

export const CardActive = (days) => (dispatch) => {
  dispatch(setCardActiveResp(days))
}

export const RemainingDays = (days) => (dispatch) => {
  dispatch(RemainingDaysFunc(days))
}

export const ReceiveAtCfcFunc = (payload) => (dispatch) => {
  dispatch(setReceiveAtCfc(payload))
}
export const ReceiveAtBranchFunc = (payload) => (dispatch) => {
  dispatch(setReceiveAtBranch(payload))
}

export const CardHold = (payload) => async (dispatch) => {
  dispatch(setCardHold(payload))
}
export const SendToCFCPrintData = (payload) => async (dispatch) => {
  dispatch(setSendToCFCPrint(payload))
}
export const SendToOrganizationPrintData = (payload) => async (dispatch) => {
  dispatch(setSendToOrganizationPrint(payload))
}
export const SendToOtherCFCPrintData = (payload) => async (dispatch) => {
  dispatch(setSendToOtherCFCPrint(payload))
}
export const SendToBranchPrintData = (payload) => async (dispatch) => {
  dispatch(setSendToBranchPrint(payload))
}
export const SendToOtherCFCSaveData = (payload) => async (dispatch) => {
  dispatch(setSendToOtherCFCSave(payload))
}
export const SendToCFCSaveData = (payload) => async (dispatch) => {
  dispatch(setSendToCFCSave(payload))
}
export const SendToBranchSaveData = (payload) => async (dispatch) => {
  dispatch(setSendToBranchSave(payload))
}
export const SendToOrganizationSaveData = (payload) => async (dispatch) => {
  dispatch(setSendToOrganizationSave(payload))
}

export const SendToCFCSubmitData = (payload) => async (dispatch) => {
  dispatch(setSendToCFCSubmit(payload))
}
export const SendToOtherCFCSubmitData = (payload) => async (dispatch) => {
  dispatch(setSendToOtherCFCSubmit(payload))
}
export const SendToOrganizationSubmitData = (payload) => async (dispatch) => {
  dispatch(setSendToOrganizationSubmit(payload))
}
export const SendToBranchSubmitData = (payload) => async (dispatch) => {
  dispatch(setSendToBranchSubmit(payload))
}

export const CardDetails = (payload) => async (dispatch) => {
  dispatch(setCardDetails(payload))
}

export const ResetState = () => async (dispatch) => {
  dispatch(setResetState())
}

export const RemainingDaysFunc = (payload) => (dispatch) => {
  dispatch(setRemainingDays(payload))
}

const cfcSlice = createSlice({
  name: 'cfcSlice',
  initialState,
  reducers: {
    setCardDetails: (state, {payload}) => {
      state.cardDetails = payload
    },
    setCardHold: (state, {payload}) => {
      state.cardHoldResponse = payload.success
    },
    setSendToCFCPrint: (state, {payload}) => {
      state.sendToCFCResp = payload.success
    },
    setSendToOtherCFCPrint: (state, {payload}) => {
      state.sendToOtherCFCResp = payload.success
    },
    setSendToOrganizationPrint: (state, {payload}) => {
      state.sendToOrganizationPrintResp = payload.success
    },
    setSendToBranchPrint: (state, {payload}) => {
      state.sendToBranchResp = payload.success
    },
    setSendToCFCSave: (state, {payload}) => {
      state.sendToCFCSaveResp = payload.success
    },
    setSendToOtherCFCSave: (state, {payload}) => {
      state.sendToOtherCFCSaveResp = payload.success
    },
    setSendToBranchSave: (state, {payload}) => {
      state.sendToBranchSaveResp = payload.success
    },
    setSendToOrganizationSave: (state, {payload}) => {
      state.sendToOrganizationSaveResp = payload.success
    },
    setSendToBranchSubmit: (state, {payload}) => {
      state.sendToBranchSubmitResp = payload.success
    },
    setSendToCFCSubmit: (state, {payload}) => {
      state.sendToCFCSubmitResp = payload.success
    },
    setSendToOtherCFCSubmit: (state, {payload}) => {
      state.sendToOtherCFCSubmitResp = payload.success
    },
    setSendToOrganizationSubmit: (state, {payload}) => {
      state.sendToOrganizationSubmitResp = payload.success
    },
    setResetState: (state, {payload}) => {
      state.sendToBranchResp = ''
      state.sendToBranchSaveResp = ''
      state.sendToOrganizationSaveResp = ''
      state.sendToBranchSubmitResp = ''
      state.sendToCFCResp = ''
      state.sendToCFCSaveResp = ''
      state.sendToCFCSubmitResp = ''
      state.cardDetails = {}
      state.receiveAtBranchResp = false
      state.cardAddCFCResult = false
      state.sendToOtherCFCResp = ''
      state.sendToOtherCFCSaveResp = ''
      state.sendToOrganizationPrintResp = ''
      state.sendToOtherCFCSubmitResp = ''
      state.sendToOrganizationSubmitResp = ''
    },
    setReceiveAtBranch: (state, {payload}) => {
      state.receiveAtBranchResp = payload.success
    },
    setReceiveAtCfc: (state, {payload}) => {
      state.receiveAtCfcResp = payload.success
    },
    setRemainingDays: (state, {payload}) => {
      state.aging = payload
    },
    setCardAddResponse: (state, {payload}) => {
      state.cardAddCFCResult = payload.result
      state.cardAddMessage = payload.message
    },
    setCardActiveResp: (state, {payload}) => {
      // state.cardAddCFCResult = payload.result
      state.cardActiveData = payload
    },
  },
})

export const {
  setCardActiveResp,
  setCardDetails,
  setCardHold,
  setSendToBranchPrint,
  setSendToCFCPrint,
  setSendToOtherCFCPrint,
  setSendToOrganizationPrint,
  setSendToBranchSave,
  setSendToOrganizationSave,
  setSendToBranchSubmit,
  setResetState,
  setSendToCFCSave,
  setReceiveAtBranch,
  setSendToCFCSubmit,
  setSendToOtherCFCSubmit,
  setSendToOrganizationSubmit,
  setRemainingDays,
  setCardAddResponse,
  setReceiveAtCfc,
  setSendToOtherCFCSave,
} = cfcSlice.actions

export default cfcSlice.reducer
