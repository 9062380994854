import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {API} from '../../../utils/services'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../utils/Enums'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import './index.css'
import {GetCurrentUser, convertISODateWithSlashed} from '../../../utils/DefaultFunctions'

const MisUpdate = () => {
  let navigate = useNavigate()
  let current_id = new URL(window.location).searchParams.get('id')

  const initialData = {
    id: current_id,
    cifNumber: '',
    consignmentNo: '',
    customerName: '',
    addressOne: '',
    addressTwo: '',
    cityId: '',
    branchId: '',
    cityName: '',
    cellNo: '',
    cardNo: '',
    branch: '',
    notes: '',
    sendCompanyName: '',
    bkgDate: '2023-05-28T13:36:07.309Z',
    bkgDate2: '2023-05-28T13:36:07.309Z',
    quantity: 0,
    remarks: '',
    remarks2: '',
    status: '',
    status2: '',
    receiverName: '',
    receiverName2: '',
    cnicNo: '',
    rowKey: '',
    relation: '',
    address3: '',
    consignmentNo2: '',
    branch2: '',
    deliveryDate: '',
    deliveryDate2: '',
    waivedOrRecovered: '',
    accountStatusRemarks: '',
    newOrReplacement: '',
    accountNumber: '',
    category: '',
    requestDate: '',
    dispatchDate: '',
    requestChannel: '',
    cityAndCountry: '',
    sambaMDCPanNo: '',
    channel: '',
    accountProduct: '',
    ccy: '',
    returnCN: '',
    returnBranchName: '',
    returnDispatchDate: '',
    finalReturnedCardStatus: '',
    deliveryOrShreddedDate: '',
    region: '',
    returnedBranchRemarks: '',
    cardStatus: '',
    returnedRemarksOPS: '',
    rsAging: 0,
    isActive: true,
    id: '',
  }

  const [data, setData] = useState(initialData)
  const [branchId, setBranchId] = useState()
  const [allBranches, setAllBranches] = useState()
  const [cardTypes, setCardTypes] = useState()

  // toast functions
  const notifySuccess = () =>
    toast.success('Record Updated Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const notifyError = () =>
    toast.error('Something Went Wrong!', {
      position: 'bottom-right',
      theme: 'colored',
    })
  // end toast functions

  const onDateChange = (field, newValue) => {
    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let convertedDate = new Date(`${selectedDate} UTC`).toISOString()
    setData((prevData) => ({
      ...prevData,
      [field]: convertedDate,
    }))
    // setDateOfPurchasing(convertedDate)
  }

  const updateRecord = () => {
    API.put(`/UserTaskMIS`, data)
      .then((res) => {
        if (res.status === 201) {
          console.log('Update Data', res)
          notifySuccess()
          setTimeout(() => {
            navigate(`${STRINGS.ROUTES.CFC.VIEW_MIS}`)
          }, 1000)
        } else if (res.status !== 204) {
          notifyError()
        }
      })
      .catch((error) => {
        if (error.response && error.response.status !== 204) {
          notifyError()
        }
      })
  }

  let user = GetCurrentUser()
  useEffect((id) => {
    API.get(`/UserTaskMIS/getById?Id=${current_id}`).then((res) => {
      console.log('MIS by ID', res)
      setData(res.data.data)
    })

    API.get(`/Branch`).then((res) => {
      setAllBranches(res.data.data.data)
    })

    API.get(`/City`).then((res) => {
      // setAllCities(res.data.data.data)
    })

    API.get(`/CardType`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setCardTypes(res.data.data.data)
    })
  }, [])
  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/* <!--begin::Content container-->  */}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/* <!--begin::Row-->             */}
          {/* <!--Mytabcontent start--> */}
          <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
            {/* <!--Mytabcontent start tab1--> */}
            <div class='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {/* <!--begin::Col--> */}
              <div class='card mb-5 mb-xl-10'>
                {/* <!--begin::Card header--> */}
                <div
                  class='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  {/* <!--begin::Card title--> */}
                  <div class='card-title m-0'>
                    <h3 class='fw-bold m-0'>Update Record</h3>
                  </div>

                  {/* <!--end::Card title--> */}
                </div>
                <hr />
                {/* <!--begin::Card header--> */}
                {/* <!--begin::Content--> */}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/* <!--begin::Form--> */}
                  <form class='' style={{backgroundColor: 'white', padding: '50px'}}>
                    <h1>Day 1 Info</h1>
                    <hr />
                    <div class='mb-3 my-3' style={{display: 'inline-flex', width: '100%'}}>
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Card Type</strong>
                          </strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              category: e.target.value,
                            }))
                          }
                          value={data.category}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select Card Type
                          </option>
                          {cardTypes &&
                            cardTypes.map((item) => {
                              return <option value={item.cardType}>{item.cardType}</option>
                            })}
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CIF/REF</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='123456'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cifNumber: e.target.value,
                            }))
                          }}
                          value={data.cifNumber}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Request Date</strong>
                          </strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('requestDate', newValue)}
                              value={data.requestDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Production/Dispatch Date</strong>
                          </strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('dispatchDate', newValue)}
                              value={data.dispatchDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Charges Waived or Recovered</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Recovered/Waived'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={20}
                          minLength={16}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              waivedOrRecovered: e.target.value,
                            }))
                          }}
                          value={data.waivedOrRecovered}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Charges/Reason for Waiver</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='ABC/000'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountStatusRemarks: e.target.value,
                            }))
                          }}
                          value={data.accountStatusRemarks}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Account Number</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='1234567890'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountNumber: e.target.value,
                            }))
                          }}
                          value={data.accountNumber}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Customer Name</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Abdul Rauf'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              customerName: e.target.value,
                            }))
                          }}
                          value={data.customerName}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>New/Replacement/Renewel</strong>
                          </strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          style={{border: '1px solid darkgrey'}}
                          data-placeholder='Select Option'
                          data-hide-search='true'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              newOrReplacement: e.target.value,
                            }))
                          }}
                          value={data.newOrReplacement}
                        >
                          <option value='1' selected hidden>
                            Select Option
                          </option>
                          <option>New</option>
                          <option>Replacement</option>
                          <option>Renewel</option>
                        </select>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Requests Channel</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          style={{border: '1px solid darkgrey'}}
                          data-placeholder='Select Option'
                          data-hide-search='true'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              requestChannel: e.target.value,
                            }))
                          }}
                          value={data.requestChannel}
                        >
                          <option selected hidden>
                            Select Request Channel
                          </option>
                          <option>BRANCH</option>
                          <option>SAMBA PHONE</option>
                          <option>AMD</option>
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Name</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='GULSHAN E IQBAL'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              branch: e.target.value,
                            }))
                          }}
                          value={data.branch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Account Product</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='SAMBA CURRENT ACCOUNT(PKR)'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountProduct: e.target.value,
                            }))
                          }}
                          value={data.accountProduct}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CCY</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='PKR/USD'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              ccy: e.target.value,
                            }))
                          }}
                          value={data.ccy}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Address-1</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='House No 123, ABC Society'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              addressOne: e.target.value,
                            }))
                          }}
                          value={data.addressOne}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Address-2</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='House No 123, ABC Society'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              addressTwo: e.target.value,
                            }))
                          }}
                          value={data.addressTwo}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>City & Country</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Karachi Pakistan'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cityAndCountry: e.target.value,
                            }))
                          }}
                          value={data.cityAndCountry}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Contact Number</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='+92-2678923033'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={30}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cellNo: e.target.value,
                            }))
                          }}
                          value={data.cellNo}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Samba MDC PAN #</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='534564XXXXXX7012'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={20}
                          minLength={16}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              sambaMDCPanNo: e.target.value,
                            }))
                          }}
                          value={data.sambaMDCPanNo}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Courier Channel</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Leoperd/Tcs'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              channel: e.target.value,
                            }))
                          }}
                          value={data.channel}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Day 2 Info</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>1st Consignment #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='03218976543'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              consignmentNo: e.target.value,
                            }))
                          }}
                          value={data.consignmentNo}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Day 3 Info</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Receiver CNIC #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='4210156789181'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cnicNo: e.target.value,
                            }))
                          }}
                          value={data.cnicNo}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Received By (Relation)</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Brother/Sister etc'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              relation: e.target.value,
                            }))
                          }}
                          value={data.relation}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Receiver Name / Reason For RTN</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Irfan'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              receiverName: e.target.value,
                            }))
                          }}
                          value={data.receiverName}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>DELIVERY/RTN DATE</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('deliveryDate', newValue)}
                              value={data.deliveryDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>1st Delivery Status</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='OK'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              status: e.target.value,
                            }))
                          }}
                          value={data.status}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>2nd Consignment #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='KI866994341'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnCN: e.target.value,
                            }))
                          }}
                          value={data.returnCN}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Name: For Card Send To Branch & CFC</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='GULSHAN E IQBAL'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnBranchName: e.target.value,
                            }))
                          }}
                          value={data.returnBranchName}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Address</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='D-103 Model Town Lahore...'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              address3: e.target.value,
                            }))
                          }}
                          value={data.address3}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Date: Dispatched to Br by Courier/CFC</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('returnDispatchDate', newValue)}
                              value={data.returnDispatchDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Packet QTY</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='1'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              quantity: e.target.value,
                            }))
                          }}
                          value={data.quantity}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Receiver Name</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Umar'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              receiverName2: e.target.value,
                            }))
                          }}
                          value={data.receiverName2}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Date: Deliver To Br by Courier/CFC</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('deliveryDate2', newValue)}
                              value={data.deliveryDate2}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Status: Deliver to Br by Courier/CFC </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='OK'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              status2: e.target.value,
                            }))
                          }}
                          value={data.status2}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Other Details</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Final Returned Card Branch Status</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='UNDELIVERED STILL IN BRANCH'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              finalReturnedCardStatus: e.target.value,
                            }))

                          }}
                          value={data.finalReturnedCardStatus}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Received at branch: Date</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) =>
                                onDateChange('deliveryOrShreddedDate', newValue)
                              }
                              value={data.deliveryOrShreddedDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Region</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='South'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              region: e.target.value,
                            }))
                          }}
                          value={data.region}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Card Status</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder=''
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cardStatus: e.target.value,
                            }))
                          }}
                          value={data.cardStatus}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>CFC Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnedRemarksOPS: e.target.value,
                            }))
                          }}
                          value={data.returnedRemarksOPS}
                        ></textarea>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnedBranchRemarks: e.target.value,
                            }))
                          }}
                          value={data.returnedBranchRemarks}
                        ></textarea>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              remarks: e.target.value,
                            }))
                          }}
                          value={data.remarks}
                        ></textarea>
                      </div>
                    </div>

                    <button
                      type='button'
                      style={{float: 'right'}}
                      class='mb-5 my-5 btn btn-primary requestformitem'
                      onClick={(e) => {
                        updateRecord()
                      }}
                    >
                      Update
                    </button>
                  </form>
                  {/* <!--end::Form--> */}
                </div>
                {/* <!--end::Content--> */}
              </div>
            </div>
          </div>
          {/* <!--Mytabcontent end--> */}
          {/* <!--end::Row--> */}
          {/* <!--begin::Table--> */}
        </div>
        {/* <!--end::Content container--> */}
      </div>
      <ToastContainer />
    </>
  )
}

export default MisUpdate
