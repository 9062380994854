import React, {useEffect, useState} from 'react'
import CityTable from '../../../../SharedComponent/DataTable/CityTable'
import {API} from '../../../../../utils/services'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import BranchTable from '../../../../SharedComponent/DataTable/BranchTable'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Card, Col, Row} from 'antd'

const CreateRoles = () => {
  let user = GetCurrentUser()

  const [countryId, setCountryId] = useState('')
  const [cityId, setCityId] = useState('')
  const [bankId, setBankId] = useState('')
  const [bankName, setBankName] = useState('')
  const [branchName, setBranchName] = useState('')
  const [branchCode, setBranchCode] = useState('')
  const [branchAccountNumber, setBranchAccountNumber] = useState('')
  const [bank, setBank] = useState([])
  const [branch, setBranch] = useState([])
  const [city, setCity] = useState([])
  const [manager, setManager] = useState([])
  const [users, setUsers] = useState([])
  const [userName, setUserName] = useState('')

  const [resp, setResp] = useState()

  const successNotify = () =>
    toast.success('Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const errorNotify = () =>
    toast.error('Wrong Input!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const addBank = () => {
    API.post(
      '/Bank/create',
      {
        description: bankName,
        bankBins: [
          {
            bankId: '12356',
            binNumber: '12233',
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setResp('success')
          setBankName('')
          successNotify()
        }
      })
      .catch((e) => {
        errorNotify()
      })
  }

  const AddBranch = () => {
    API.post(
      '/Branch/create',
      {
        branchName: branchName,
        branchCode: branchCode,
        branchAccountNumber: branchAccountNumber,
        bankId: bankId,
        managerName: userName,
        cityId: cityId,
        insuranceLimit: 50000,
        contactNumber: '',
        branchAddress: '',
      },
      {
        headers: {
          Authorization: `Bearer ${user.api_token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          setResp('success')
          setBranchName('')
          setUserName('')
          setBranchCode('')
          setBranchAccountNumber('')
          setBankId('')
          setCityId('')
          successNotify()
        }
      })
      .catch((e) => {
        errorNotify()
      })
  }

  useEffect(() => {
    API.get(`/Bank`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      // setCountryMsg(res.data.message)
      setBank(res.data.result.data.$values)
    })

    API.get(`/Branch`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      // setCountryMsg(res.data.message)
      setBranch(res.data.result.data.$values)
    })

    API.get(`/City`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      // setCityMsg(res.data.message)
      setCity(res.data.result.data.$values)
    })

    API.get(`/User`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setUsers(res.data.result.data.$values)
    })
  }, [resp])

  const [init, setInit] = useState({
    tabs: ['Assign Roles', 'List'],
    selectedTab: 0,
  })

  const {tabs, selectedTab} = init

  return (
    <>
      <div id='kt_app_content_container' class='app-container container-xx'>
        {/*<!--begin::Navbar-->*/}
        <div class='card mb-3' style={{height: '70px', borderBottom: '1px solid #C6C7CC'}}>
          <div class='my-3' style={{paddingTop: '0.25rem !important', paddingLeft: '1.5rem'}}>
            {/*<!--begin::Details-->*/}
            <div class='d-flex flex-wrap flex-sm-nowrap mb-6'>
              {/*<!--begin::Nav-->*/}
              <ul class='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {/*<!--begin::Nav item-->*/}
                {tabs?.map((tb, index) => {
                  return (
                    <li
                      class='nav-item'
                      onClick={() => {
                        setInit({
                          ...init,
                          selectedTab: index,
                        })
                      }}
                    >
                      <div
                        class={`nav-link text-active-primary py-5 me-6 ${
                          selectedTab === index && 'active'
                        }`}
                        data-bs-toggle='tab'
                        href='#kt_tab_pane_1'
                        aria-selected='false'
                        role='tab'
                        tabindex='-1'
                      >
                        {tb}
                      </div>
                    </li>
                  )
                })}
              </ul>
              {/*<!--end::Nav-->*/}
            </div>
            {/*<!--end::Details-->*/}
          </div>
        </div>
        {/*<!--end::Navbar-->*/}
        {/*<!--begin::Row-->*/}
        {/*<!--Mytabcontent start-->*/}
        <div class='row g-6 g-xl-12 tab-content' id='myTabContent'>
          {/*<!--Mytabcontent start tab1-->*/}
          {selectedTab === 0 && (
            <div className='group'>
              <div class='card mb-5 mb-xl-10'>
                {/*<!--begin::Card header-->*/}

                {/*<!--begin::Card header-->*/}
                {/*<!--begin::Content-->*/}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/*<!--begin::Form-->*/}
                  <form
                    id='kt_account_profile_details_form'
                    class='form fv-plugins-bootstrap5 fv-plugins-framework'
                    novalidate='novalidate'
                  >
                    {/*<!--begin::Card body-->*/}
                    <div className='my-3' style={{padding: '10px'}}>
                      {/* second  */}
                      <div
                        className=''
                        style={{
                          backgroundColor: 'white',
                          border: '1px solid #E4E4E4',
                          borderRadius: '10px',
                        }}
                      >
                        <div className='my-5'>
                          <div className='d-flex my-5'>
                            <div className='me-3' id='w-500'>
                              <label for='exampleInputEmail1' className='form-label'>
                                <strong>Role Name</strong>
                              </label>
                              <input
                                class='form-control form-control-solid flatpickr-input me-3'
                                placeholder='Enter Role Name'
                                value={branchName}
                                onChange={(e) => setBranchName(e.target.value)}
                                type='text'
                              />
                            </div>
                          </div>
                          {/* first  */}
                          <div className='d-flex'>
                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>Dashboard:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Admin:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Management:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>CFC:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch Checker:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Samba Phone:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>Main Screen:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Management:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>CFC:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch Checker:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch Creator:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <h4 className='ms-5'>View Branch & CFC</h4>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Only Branch:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch & CFC:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />
                          {/* second  */}
                          <div className='d-flex'>
                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>History:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Management:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>CFC:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch Creator:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch Checker:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Samba Phone:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>Configuration:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Department:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Designation:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Job Title:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Profile:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          {/* third  */}
                          <div className='d-flex'>
                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>Config Bank & Branch:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Bank:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Branch:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className='me-3'
                              style={{
                                backgroundColor: 'white',
                                width: '50%',
                                border: '1px solid grey',
                                borderRadius: '10px',
                              }}
                            >
                              <div>
                                <div className='d-flex my-10'>
                                  <div className='me-3' id='w-500'>
                                    <label for='exampleInputEmail1' className='form-label'>
                                      <h2>
                                        <strong>Config Escalation & Notification:</strong>
                                      </h2>
                                    </label>
                                  </div>
                                </div>

                                <div className='d-flex my-5'>
                                  <div className='me-3 d-flex' id='w-500'>
                                    <div className='me-5' style={{width: '50px'}}>
                                      <label
                                        for='exampleInputEmail1'
                                        className='form-label me-5 my-1'
                                      >
                                        <h6>
                                          <strong>Escaltions:</strong>
                                        </h6>
                                      </label>
                                    </div>
                                    <div class='fv-row container ms-5'>
                                      {/*<!--begin::Label-->*/}
                                      {/*<!--end::Label-->*/}
                                      {/*<!--begin::Input-->*/}
                                      <div class='form-check form-check-custom form-check-solid mb-2'>
                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onApp}
                                          // onChange={(e) => setInit({...init, onApp: !onApp})}
                                        />
                                        <label className='form-check-label me-3'>View </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='Email'
                                          style={{border: '2px solid grey'}}
                                          // defaultChecked={item.onEmail}
                                          id='email'
                                          // onChange={(e) => setInit({...init, onEmail: !onEmail})}
                                        />
                                        <label className='form-check-label me-3'>Create </label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label me-3'>Update</label>

                                        <input
                                          class='form-check-input'
                                          type='checkbox'
                                          value='expiration'
                                          id='expiration'
                                          style={{border: '2px solid grey'}}
                                          // onChange={(e) =>
                                          //   // setInit({...init, beforeExpiration: !beforeExpiration})
                                          // }
                                        />
                                        <label class='form-check-label'>Delete</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            // onClick={AddBranch}
                            style={{float: 'right'}}
                            type='button'
                            className='btn btn-primary btn-sm my-5'
                          >
                            Add
                          </button>
                        </div>
                        <div className=' my-3 mb-5'>
                          {/* <CityTable data={country} type="country" /> */}
                        </div>
                      </div>
                    </div>
                    <input type='hidden' />
                    <div></div>
                  </form>
                  {/*<!--end::Form-->*/}
                </div>
                {/*<!--end::Content-->*/}
              </div>
            </div>
          )}
          ,{/*<!--endtab1-->*/}
          {/*<!--begintab2-->*/}
          {selectedTab === 1 && (
            <>
              <div className='my-3' style={{padding: '10px'}}>
                <div
                  className='me-3'
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #E4E4E4',
                    borderRadius: '10px',
                    padding: '15px',
                  }}
                >
                  <div className='my-5'>
                    <label for='exampleInputEmail1' className='form-label'>
                      <strong>Create Bank</strong>
                    </label>
                    <div className='d-flex'>
                      <div className='me-3 d-flex' style={{width: '500%'}}>
                        {/* <select
                      class='form-select me-3 form-select-solid'
                      data-control='select2'
                      onChange={(e)=>setCountryId(e.target.value)}
                      value={countryId}
                      data-placeholder='Select City'
                      data-hide-search='true'
                    >
                      <option selected hidden>
                        Select Country
                      </option>
                      {
                        country && country.map(item=>{
                          return <option value={item.id}>{item.country}</option>
                          })
                      }
                    </select> */}

                        <input
                          class='form-control form-control-solid flatpickr-input'
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          placeholder='Enter Bank Name'
                          type='text'
                        />
                      </div>
                      <button onClick={addBank} className='btn btn-primary btn-sm'>
                        Add
                      </button>
                    </div>
                  </div>
                  <div className=' my-3 mb-5'>
                    <BranchTable data={bank} type='bank' />
                    {/* <CityTable data={country} type="country" /> */}
                  </div>
                </div>
              </div>
            </>
          )}
          {/*<!--endtab2-->*/}
        </div>
        {/*<!--Mytabcontent end-->*/}
        {/*<!--end::Row-->*/}
        {/*<!--begin::Table-->*/}
      </div>

      <div>
        {/* first */}

        {/* second  */}

        {/* Third  */}
      </div>
      <ToastContainer />
    </>
  )
}

export default CreateRoles
