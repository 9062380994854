import React, {useState, useEffect} from 'react'
import {API} from '../../../../utils/services'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'

const BranchComplainForm = () => {
  const [customerName, setCustomerName] = useState()
  const [authType, setAuthType] = useState('');
  const [address, setAddress] = useState()
  const [cellNo, setCellNo] = useState()
  const [cardNo, setCardNo] = useState()
  const [accountNo,setAccountNo] =useState()
  const [branch, setBranch] = useState()
  const [complaintNote, setComplaintNote] = useState()
  const [resolutionRemarks, setResolutionRemarks] = useState()
  const [resolved, setResolved] = useState(false)
  const [cnicNo, setCnicNo] = useState()
  const [allBranches, setAllBranches] = useState()
  const [branchId, setBranchId] = useState()
  const [allCities, setAllCities] = useState()
  const [cityId, setCityId] = useState()
  const [sambaPhoneRemarks, setSambaPhoneRemarks] = useState()
  const auth = GetCurrentUser()
  const type = auth.type
  const validation = () => {
    if (!customerName) {
      notifyError('Customer Name is Required!')
      return false
    }
    if (!accountNo) {
      notifyError('Account Number is Required!')
      return false
    }
    if (auth.type==='cfc' && !complaintNote) {
      notifyError('CFC Note is Required!')
      return false
    }
    if (auth.type==='branch' && !resolutionRemarks) {
      notifyError('Branch Note is Required!')
      return false
    }
    if (auth.type==='samba phone' && !sambaPhoneRemarks) {
      notifyError(' Samba Phone/Care Note is Required!')
      return false
    }

    return true
  }
  
  // toast functions
  const notifySuccess = () =>
    toast.success('Complain Updated Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })
  const notifyMessage = () => {
    if (customerName.trim() === '') {
      toast.error('Customer Name is mandatory!', {
        position: 'bottom-right',
        theme: 'colored',
      });
    }
  }
  const notifyAccess = () =>{
    if (accountNo.trim() === ''){
    toast.error('accountNo is mandatory!', {
      position: 'bottom-right',
      theme: 'colored',
    })
    }
  };
  const notifyError = (value) =>
    toast.error(value, {
      position: 'bottom-right',
      theme: 'colored',
    })    
    



  // end toast functions

  //   addComplain Function API
  const addComplain = () => {
    const valid = validation()
    if (!valid) {
      return
    }
    API.post(`Complaint`, {
      customerName: customerName,
      address: address,
      cellNo: cellNo,
      customerCityId: cityId,
      cardNo: cardNo,
      accountNo:accountNo,
      branchId: branchId,
      complaintNote: complaintNote,
      resolutionRemarks: resolutionRemarks,
      resolved: resolved,
      cnicNo: cnicNo,
      sambaPhoneRemarks: sambaPhoneRemarks,
      
    })
      .then((res) => {
        setCustomerName('')
        setAddress('')
        setCellNo('')
        setCardNo('')
        setAccountNo('')
        setBranchId('')
        setComplaintNote('')
        setCnicNo('')
        setResolutionRemarks('')
        setSambaPhoneRemarks('')
        notifySuccess()
        
        
      })
      .catch((err) => {
        console.log(err, 'err')
        
        //notifyAccess(accountNo)
        //notifyMessage(customerName)
      })
  }
  // end addComplain Function API

  let body = {
    pageNumber: 1,
    pageSize: 100,
  }

  // Get all Branches
  useEffect(() => {
    API.post('/Branch/Search', body).then((res) => {
      setAllBranches(res.data.data.data)
    })
    API.get(`/City`).then((res) => {
      setAllCities(res.data.data.data)
    })
  }, [])
  // end Get All Branches
  //const handleBlur=()=>{
    //if (accountNo===""){

      // (notifyError());
   // }
  //}
  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/* <!--begin::Content container-->  */}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/* <!--begin::Row-->             */}
          {/* <!--Mytabcontent start--> */}
          <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
            {/* <!--Mytabcontent start tab1--> */}
            <div class='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {/* <!--begin::Col--> */}
              <div class='card mb-5 mb-xl-10'>
                {/* <!--begin::Card header--> */}
                <div
                  class='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  {/* <!--begin::Card title--> */}
                  <div class='card-title m-0'>
                    <h3 class='fw-bold m-0'>Add Complain Form</h3>
                  </div>

                  {/* <!--end::Card title--> */}
                </div>
                <hr />
                {/* <!--begin::Card header--> */}
                {/* <!--begin::Content--> */}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/* <!--begin::Form--> */}
                  <form class='' style={{backgroundColor: 'white', padding: '50px'}}>
                    <div class='mb-' style={{display: 'inline-flex', width: '100%'}}>
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CIF/Customer Name</strong>{'  '}
                          </strong>
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Abdul Wasay'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => setCustomerName(e.target.value)}
                          value={customerName}
                          //required
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Card Number</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='11133355556666'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={20}
                          minLength={16}
                          type='text'
                          onChange={(e) => setCardNo(e.target.value)}
                          value={cardNo}
                        />
                      </div>
                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Account Number</strong>{' '}
                          </strong>
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='0009991188811'
                          type='text'
                          onChange={(e) => setAccountNo (e.target.value)}
                          value={accountNo}
                          //onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div className='me-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>City</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => setCityId(e.target.value)}
                          style={{border: '1px solid darkgrey'}}
                          // value={allBranches}
                          data-placeholder='Select Designation'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select City
                          </option>
                          {allCities &&
                            allCities.map((item) => {
                              return <option value={item.id}>{item.city}</option>
                            })}
                        </select>
                      </div>
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Complain ID</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='03218976543'
                          type='text'
                          onChange={(e) => setCellNo(e.target.value)}
                          value={cellNo}
                        />
                      </div>
                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>CNIC</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='4210115678952'
                          maxLength={13}
                          type='text'
                          onChange={(e) => setCnicNo(e.target.value)}
                          value={cnicNo}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Consignment no</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='D-103 Model Town Lahore...'
                          type='text'
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </div>
                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch</strong>
                        </label>
                        {/* <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Shahra e Quaiden'
                          type='text'
                          onChange={(e) => setBranch(e.target.value)}
                          value={branch}
                        /> */}
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => setBranchId(e.target.value)}
                          style={{border: '1px solid darkgrey'}}
                          // value={allBranches}
                          data-placeholder='Select Designation'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select Branch
                          </option>
                          {allBranches &&
                            allBranches.map((item) => {
                              return <option value={item.id}>{item.branchName}</option>
                            })}
                        </select>
                      </div>
                    </div>
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                        <strong>CFC Note</strong> {auth.type === 'cfc' && <span style={{ color: 'red' }}>*</span>}
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          
                          onChange={(e) => setComplaintNote(e.target.value)}
                          value={complaintNote}
                          disabled={auth.type !== "cfc"}
                        ></textarea>
                      </div>
                    </div>
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                        <strong>Branch Note</strong> {auth.type === 'branch' && <span style={{ color: 'red' }}>*</span>}
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          
                          
                          onChange={(e) => setResolutionRemarks(e.target.value)}
                          value={resolutionRemarks}
                          disabled={auth.type !== "branch"}
                        ></textarea>
                      </div>
                    </div>


                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                        <strong>Samba Phone/Care Note</strong> {auth.type === 'samba phone' && <span style={{ color: 'red' }}>*</span>}
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => setSambaPhoneRemarks(e.target.value)}
                          value={sambaPhoneRemarks}
                          disabled={auth.type !== "samba phone"}
                        ></textarea>
                      </div>
                    </div>


                    <button
                      type='button'
                      style={{float: 'right'}}
                      class='mb-5 my-5 btn btn-primary requestformitem'
                      onClick={(e) => {
                        addComplain()
                      }}
                    >
                      Submit
                    </button>
                  </form>
                  {/* <!--end::Form--> */}
                </div>
                {/* <!--end::Content--> */}
              </div>
            </div>
          </div>
          {/* <!--Mytabcontent end--> */}
          {/* <!--end::Row--> */}
          {/* <!--begin::Table--> */}
        </div>
        {/* <!--end::Content container--> */}
      </div>
      <ToastContainer />
    </>
  )
}

export default BranchComplainForm
