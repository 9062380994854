import React, {useEffect, useState} from 'react'
import MisTable from '../../../../SharedComponent/DataTable/MisTable'
import MisUploadTable from '../../../../SharedComponent/DataTable/MisUploadTable'
import Button from '@mui/material/Button'
import UploadMis from '../Modal/UploadMis'
import Loader from '../../../../SharedComponent/Loader/Loader'
import {Modal} from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import {message, Upload} from 'antd'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'
import {Select, Space, DatePicker} from 'antd'
import {API} from '../../../../../utils/services'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../../../utils/Enums'
import dayjs from 'dayjs'
//import { GetCurrentUser } from '../../../utils/DefaultFunctions';

const {RangePicker} = DatePicker

const initalKeys = {
  value: '',
  label: '',
}
const ViewMis = () => {
  let auth = GetCurrentUser()
  let navigate = useNavigate()
  // let userTask_id = new URL(window.location).searchParams.get('id')
  const [refreshData, setRefreshData] = useState(true)
  const [loader, setLoader] = useState(true)
  const [showUpload, setShowUpload] = useState()
  const [file, setFile] = useState()
  const [headerIncluded, setHeaderIncluded] = useState(1)
  const [uploadType, setUploadType] = useState()
  const [misTypes, setMisTypes] = useState()
  const [filterType, setFilterType] = useState({})
  const [filterWithKey, setFilterWithKey] = useState({})
  const [selectionData, setSelectionData] = useState([])
  const [filterTypes, setFilterTypes] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(30)

  const setPageSizeFunc = (value) => {
    setPageSize(value)
  }

  const setPageNumberFunc = (value) => {
    setPageNumber(value)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleAddClick = (event) => {
    event.preventDefault()
    navigate(`${STRINGS.ROUTES.CFC.ADD_MIS_RECORD}`)
  }

  console.log(filterType, 'filterType')

  useEffect(() => {
    if (Object.keys(filterType).length > 0) {
      setFilterWithKey({
        [filterType.columnKey]: '',
      })
    }
  }, [filterType])

  const handleOk = () => {
    setIsModalOpen(false)
    // submitFile()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setFile('')
  }
  const [addMISUpdate, setAddMISUpdate] = useState(false)
  const addMISFunc = (value) => {
    setAddMISUpdate(value)
  }

  const returnURL = () => {
    if (window.location.hostname === 'corm.3em.tech' || window.location.hostname === 'localhost')
      return 'http://api.corm.3em.tech/api/UserTaskMIS/upload'
    //else return 'https://apicorm.samba.com.pk/api/UserTaskMIS/upload'
     else return 'http://10.21.28.129:8086/api/UserTaskMIS/upload'
  }

  const {Dragger} = Upload
  const props = {
    name: 'file',
    multiple: false,
    // action: 'http://10.21.28.129:8086/api/UserTaskMIS/upload',
    action: returnURL(),
    // action: 'http://api.corm.3em.tech/api/UserTaskMIS/upload',
    data: {
      UploadType: uploadType === 'Master' ? 10 : uploadType === 'Day 2' ? 11 : 12,
      HeaderIncluded: headerIncluded,
    },
    onChange(info) {
      console.log('data123', info);
      const {status} = info.file
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
        setTimeout(() => {
          handleCancel()
        }, 1000)
      } else if (status === 'error') {
        message.error(`${info.file.response.message}`)
      }
      setAddMISUpdate(!addMISUpdate)
    },
  }

  const getColumns = () => {
    API.get(`/UserTaskMIS/columns`, {
      headers: {
        Authorization: `Bearer ${auth.api_token}`,
      },
    })
      .then((res) => {
        setFilterTypes(res.data.data)
      })
      .catch((err) => {
        console.log('err:', err)
      })
  }
  

  const getMisTypes = () => {
    API.get(`/UserTaskMIS/mistypes`, {
      headers: {
        Authorization: `Bearer ${auth.api_token}`,
      },
    })
      .then((res) => {
        setMisTypes(res.data.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getMisTypes()
    getColumns()
  }, [])

  // const filterTypes = [
  //   {value: 1, label: 'Consignment No'},
  //   {value: 2, label: 'Customer Name'},
  //   {value: 3, label: 'City'},
  //   {value: 4, label: 'Account Number'},
  //   // {value: 5, label: 'Branch'},
  //   {value: 6, label: 'CIF Number'},
  // ]

  // useEffect for data set for selection field i.e branch and city
  // useEffect(() => {
  //   if (Object.keys(filterType).length > 0) {
  //     if (filterType.value == 3) {
  //       // fetch cities and setSelectionData
  //       API.get(`/City`, {
  //         headers: {
  //           Authorization: `Bearer ${auth.api_token}`,
  //         },
  //       })
  //         .then((res) => {
  //           if (res.data.success) {
  //             setSelectionData(res.data.data.data)
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err)
  //         })
  //     } else if (filterType.value == 5) {
  //       //fetch branch and setSelectionData
  //       API.get('/Branch', {
  //         headers: {Authorization: `Bearer ${auth.api_token}`},
  //       })
  //         .then((res) => {
  //           setSelectionData(res.data.data.data)
  //         })
  //         .catch((err) => {
  //           console.log(err)
  //         })
  //     }
  //   }
  // }, [filterType])

  function hasTrueValues(obj) {
    for (let key in obj) {
      if (obj[key]) {
        return true
      }
    }
    return false
  }

  const exportToExcel = () => {
    let url = `/UserTaskMIS/print/excel`
    const activeBranch = sessionStorage.getItem('selectedBranchName')
    if (auth.type) {
      url = `/UserTaskMIS/print/excel`
    }
    console.log(filterWithKey, 'filterWithKey')
    let requestObject = {
      branch:activeBranch,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sort: {
        descending: false,
      },
    }
    let newObj = {}
    if (hasTrueValues(filterWithKey)) {
      newObj = {
        ...requestObject,
        ...filterWithKey,
      }
    } else {
      newObj = requestObject
    }
    // return
    API.post(url, newObj, {
      headers: {
        Authorization: `Bearer ${auth.api_token}`,
      },
    })
      .then((res) => {
        if (res.data.success) {
          //TODO: excel export work
          const link = document.createElement('a')
          link.href = `${res.data.data}`
          link.download = `cards.xlsx`
          link.target = '_blank'
          link.click()
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const onChangeRangeDate = (dates) => {
    //dates[0].$d => date from
    //dates[1].$d => date to
    //here will set date from and to accordingly
    let requestDateFrom = filterType.columnKey
    let requestDateTo = requestDateFrom.replace('From', 'To')

    //setfilters
    setFilterWithKey({
      // [filterType.columnKey]: dayjs(dates[0].$d).format('DD-MM-YYYY'),
      // [requestDateTo]: dayjs(dates[1].$d).format('DD-MM-YYYY'),
      [filterType.columnKey]: dates[0].$d,
      [requestDateTo]: dates[1].$d,
    })
  }

  return (
    <>
      <div class='tab-pane fade active show' id='kt_tab_pane_3' role='tabpanel'>
        {/*<!--begin::Col-->*/}
        <div class='card mb-xl-10'>
          {/*<!--begin::Card header-->*/}
          <div
            class='card-header d-flex gap-2 border-0 cursor-pointer mb-'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <div class='card-title m-0'>
              <h3 class='fw-bold m-0'>MIS View</h3>
            </div>

            <div className='my-5'>
              <i
                class='fa fa-refresh my-3'
                style={{
                  color: 'black',
                  fontSize: '24px',
                  cursor: 'pointer',
                  margin: '0px 1rem',
                }}
                aria-hidden='true'
                onClick={() => {
                  setRefreshData(!refreshData)
                  // handleResetClick()
                  // setLoader(true)
                }}
              />

              {auth.type === 'cfc' && (
                <Button
                  className='text-white me-3'
                  style={{backgroundColor: '#18A689'}}
                  type='primary'
                  onClick={() => {
                    navigate(`${STRINGS.ROUTES.CFC.ADD_MIS_RECORD}`)
                  }}
                >
                  Add Record
                </Button>
              )}
              {auth.type === 'cfc' && (
                <Button
                  className='text-white'
                  style={{backgroundColor: '#18A689'}}
                  type='primary'
                  onClick={showModal}
                >
                  Import MIS
                </Button>
              )}
              <Button
                className='text-white ms-3'
                style={{backgroundColor: '#18A689'}}
                type='primary'
                onClick={exportToExcel}
              >
                Export To Excel
              </Button>
              <Modal
                title='Import MIS'
                open={isModalOpen}
                // onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                  <Button key='back' onClick={handleCancel}>
                    Cancel
                  </Button>,
                ]}
              >
                <hr />
                <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                  <label class='fs-6 fw-semibold required form-label mb-2'>MIS Type</label>
                  <select
                    class='form-select me-3 form-select-solid'
                    data-control='select2'
                    onChange={(e) => setUploadType(e.target.value)}
                    style={{border: '1px solid darkgrey'}}
                    value={uploadType}
                    data-placeholder='Select Designation'
                    data-hide-search='true'
                  >
                    <option selected hidden>
                      Select MIS Type
                    </option>
                    {misTypes &&
                      misTypes.map((item) => {
                        return <option value={item.description}>{item.description}</option>
                      })}
                  </select>
                  <div class='fv-plugins-message-container invalid-feedback'></div>
                </div>
                <Dragger {...props}>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                  <p className='ant-upload-hint'>
                    Support for a single or bulk upload. Strictly prohibited from uploading company
                    data or other banned files.
                  </p>
                </Dragger>
              </Modal>
            </div>
          </div>
          {/* <hr /> */}
          {/*<!--begin::Card header-->*/}
          {/*<!--begin::Content-->*/}
          <hr />
          <div id='kt_app_content' class='app-content flex-column-fluid'>
            {/*<!--begin::Content container-->*/}
            <div id='kt_app_content_container' class='app-container container-xx'>
              {/*<!--begin::Tables Widget 12-->*/}
              <div class='card mb-5 mb-xl-8'>
                {/*<!--end::Header-->*/}
                {/*<!--begin::Body-->*/}
                <div class='card-body py-3'>
                  {/*<!--begin::Table container-->*/}
                  {/** Search Div Start */}
                  <div
                    className='d-flex justify-content-end gx-5 my-5 align-items-end'
                    style={{gridColumnGap: '2em'}}
                  >
                    <div className='d-flex flex-column'>
                      <label for='exampleInputEmail1' class='form-label'>
                        <strong>
                          <strong>Filter </strong>
                        </strong>
                      </label>
                      {filterType.column_type === 'date' ? (
                        <RangePicker style={{height: '3em'}} onChange={onChangeRangeDate} />
                      ) : (
                        <input
                          className='form-control form-control-solid flatpickr-input'
                          placeholder='Search'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            //TODO: set filter with key filters
                            setFilterWithKey({
                              [filterType.columnKey]: e.target.value,
                            })
                          }}
                          disabled={!Object.keys(filterType).length ? true : false}
                          value={filterWithKey?.columnKey}
                        />
                      )}

                      {/* {filterType.value === 3 ? (
                        <select
                          className='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => {
                            //setFilterWithKey
                            setFilterWithKey({
                              key: 'City Id',
                              value: e.target.value,
                            })
                          }}
                          data-placeholder='Select Filter Type'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select
                          </option>
                          {selectionData.length > 0 &&
                            selectionData?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.city}
                              </option>
                            ))}
                        </select>
                      ) : filterType.value === 5 ? (
                        <select
                          className='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => {
                            setFilterWithKey({
                              key: 'branch',
                              value: e.target.value,
                            })
                          }}
                          data-placeholder='Select Filter Type'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select
                          </option>
                          {selectionData.length > 0 &&
                            selectionData?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.branchName}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <input
                          className='form-control form-control-solid flatpickr-input'
                          placeholder='Search'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            //TODO: set filter with key filters

                            setFilterWithKey({
                              key: filterType.label,
                              value: e.target.value,
                            })
                          }}
                          disabled={!Object.keys(filterType).length ? true : false}
                          value={filterWithKey?.value}
                        />
                      )} */}
                    </div>
                    <div>
                      <label for='exampleInputEmail1' class='form-label'>
                        <strong>
                          <strong>Filter Type</strong>
                        </strong>
                      </label>
                      <select
                        class='form-select me-3 form-select-solid'
                        data-control='select2'
                        onChange={(e) => {
                          //reset on every change
                          console.log(e.target.value, 'event')
                          // setFilterWithKey(initalKeys)
                          const filtered = filterTypes.filter(
                            (el) => el.columnKey == e.target.value
                          )
                          setFilterType(filtered[0])
                        }}
                        // value={data.category}
                        data-placeholder='Select Filter Type'
                        data-hide-search='true'
                      >
                        <option selected hidden>
                          Select Filter Type
                        </option>
                        {filterTypes?.map((item) => {
                          return <option value={item.columnKey}>{item.description}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  {/** Search Div End */}
                  <div class='table-responsive'>
                    {/*<!--begin::Table-->*/}
                    {/* <SubjectTable /> */}
                    <MisUploadTable
                      addMISUpdate={addMISUpdate}
                      refreshData={refreshData}
                      filterData={filterWithKey}
                      pageNumber={pageNumber}
                      pageSize={pageSize}
                      setPageSize={setPageSizeFunc}
                      setPageNumber={setPageNumberFunc}
                    />
                    {/*<!--end::Table-->*/}
                  </div>
                  {/*<!--end::Table container-->*/}
                </div>
                {/*<!--begin::Body-->*/}
              </div>
            </div>
            {/*<!--end::Content container-->*/}
          </div>
          {/*<!--end::Content-->*/}
        </div>
      </div>
      {/* <UploadMis show={showUpload} close={() => setShowUpload(false)} /> */}
    </>
  )
}

export default ViewMis
