import React, {useState} from 'react'
import UserConfigTable from '../../../../SharedComponent/DataTable/UserConfigTable'

const CreateGroup = () => {
    const [userGet, setUserGet] = useState()
  return (
    <div className='group'>
      <div class='card mb-5 mb-xl-10'>
        {/*<!--begin::Card header-->*/}

        {/*<!--begin::Card header-->*/}
        {/*<!--begin::Content-->*/}
        <div id='kt_account_settings_profile_details' class='collapse show'>
          {/*<!--begin::Form-->*/}
          <form
            id='kt_account_profile_details_form'
            class='form fv-plugins-bootstrap5 fv-plugins-framework'
            novalidate='novalidate'
          >
            {/*<!--begin::Card body-->*/}
            <div className='d-flex my-3 '>
              {/* first  */}
              <div
                className='container me-3'
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #E4E4E4',
                  borderRadius: '10px',
                  width: '50%',
                }}
              >
                <div className='my-5' style={{marginLeft: '-2rem'}}>
                  <label
                    for='exampleInputEmail1'
                    className='form-label'
                    style={{marginLeft: '2rem'}}
                  >
                    <strong>User's List</strong>
                  </label>
                </div>
                <div className=' my-3 mb-5'>
                  <UserConfigTable data={userGet} type='user' />
                </div>
              </div>

              {/* second  */}
              <div
                className='container me-3'
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #E4E4E4',
                  borderRadius: '10px',
                }}
              >
                <div className='my-5' style={{marginLeft: '-2rem'}}>
                  <div className='d-flex mb-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>First Name</strong>
                      </label>
                      <input
                        class='form-control form-control-solid flatpickr-input'
                        placeholder='Enter First Name'
                        type='text'
                        // value={firstName}
                        // onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Last Name</strong>
                      </label>
                      <input
                        class='form-control form-control-solid flatpickr-input'
                        placeholder='Enter Last Name'
                        type='text'
                        // value={lastName}
                        // onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='d-flex my-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Email Address</strong>
                      </label>
                      <input
                        class='form-control form-control-solid flatpickr-input'
                        placeholder='user@samba.com.pk'
                        type='text'
                        // value={email}
                        // id="one"
                        // name="one"
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='d-flex my-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Designation</strong>
                      </label>
                      <select
                        class='form-select me-3 form-select-solid'
                        data-control='select2'
                        // onChange={(e) => setDesignationId(e.target.value)}
                        // value={designationId}
                        data-placeholder='Select Designation'
                        data-hide-search='true'
                      >
                        <option selected hidden>
                          Select Designation
                        </option>
                        {/* {designation &&
                          designation.map((item) => {
                            return <option value={item.id}>{item.designation}</option>
                          })} */}
                      </select>
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Department</strong>
                      </label>
                      <select
                        class='form-select me-3 form-select-solid'
                        data-control='select2'
                        // value={departmentName}
                        // onChange={(e) => setDepartmentName(e.target.value)}
                        data-placeholder='Select Department'
                        data-hide-search='true'
                      >
                        <option selected hidden>
                          Select Department
                        </option>
                        <option value='1'>IT</option>
                        {/* {
                                                              country && country.map(item=>{
                                                              return <option value={item.id}>{item.country}</option>
                                                              })
                                                          } */}
                      </select>
                      {/* <input
                                                      type='text'
                                                      class='form-control form-control-solid'
                                                      id='exampleDropdownFormPassword2'
                                                      placeholder='Enter Department'
                                                      value={departmentName}
                                                      onChange={(e) => setDepartmentName(e.target.value)}
                                                    /> */}
                    </div>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Job Title</strong>
                      </label>
                      <select
                        class='form-select me-3 form-select-solid'
                        data-control='select2'
                        // value={departmentName}
                        // onChange={(e) => setDepartmentName(e.target.value)}
                        data-placeholder='Select Job Title'
                        data-hide-search='true'
                      >
                        <option selected hidden>
                          Select Job Title
                        </option>
                        <option value='2'>IT Head</option>
                        {/* {
                                                              country && country.map(item=>{
                                                              return <option value={item.id}>{item.country}</option>
                                                              })
                                                          } */}
                      </select>
                      {/* <input
                                                      type='text'
                                                      class='form-control form-control-solid'
                                                      id='exampleDropdownFormPassword2'
                                                      placeholder='Enter Job Title'
                                                      value={departmentName}
                                                      onChange={(e) => setDepartmentName(e.target.value)}
                                                    /> */}
                    </div>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>UserType</strong>
                      </label>
                      <select
                        class='form-select me-3 form-select-solid'
                        data-control='select2'
                        // onChange={(e) => setUserTypeId(e.target.value)}
                        // value={userTypeId}
                        data-placeholder='Select Department'
                        data-hide-search='true'
                      >
                        <option selected hidden>
                          Select UserType
                        </option>
                        {/* {userType &&
                          userType.map((item) => {
                            return <option value={item.id}>{item.description}</option>
                          })} */}
                      </select>
                    </div>
                  </div>

                  <div className='d-flex my-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Phone Number</strong>
                      </label>
                      <input
                        type='text'
                        class='form-control form-control-solid'
                        id='exampleDropdownFormPassword2'
                        placeholder='0xxx-3xxxxxx'
                        // value={phoneNumber}
                        // onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='d-flex my-5'>
                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>UserName</strong>
                      </label>
                      <input
                        type='text'
                        class='form-control form-control-solid'
                        placeholder='Enter UserName'
                        // value={userId}
                        readOnly
                        // onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>

                    <div className='me-3' id='w-500'>
                      <label for='exampleInputEmail1' className='form-label'>
                        <strong>Password</strong>
                      </label>
                      <input
                        type='text'
                        class='form-control form-control-solid'
                        id='exampleDropdownFormPassword2'
                        placeholder='Enter Password'
                        // value={password}
                        // onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className='btn btn-primary btn-sm mb-5'
                    style={{float: 'right'}}
                    // onClick={(e) => {
                    //   addUser()
                    // }}
                  >
                    Submit
                  </div>
                </div>
                <div className=' my-3 mb-5'>
                  {/* <CityTable data={country} type="country" /> */}
                </div>
              </div>
            </div>
            <input type='hidden' />
            <div></div>
          </form>
          {/*<!--end::Form-->*/}
        </div>
        {/*<!--end::Content-->*/}
      </div>
    </div>
  )
}

export default CreateGroup
