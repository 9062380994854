import {useState, useEffect, useRef} from 'react'
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import {Button} from '@mui/material'
import Loader from '../Loader/Loader'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  convertISODate,
  disabledDeliverButton,
  disabledShredButton,
  disabledUpdateButton,
  GetCurrentUser,
  toCamelCase,
} from '../../../utils/DefaultFunctions'
import Box from '@mui/material/Box'
import {Popconfirm} from 'antd'
import {STRINGS} from '../../../utils/Enums'
import {API} from '../../../utils/services'
import {Link} from 'react-router-dom'
import './style.css'

const MisTable = ({
  refreshData,
  addMISUpdate,
  filterData = {},
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
}) => {
  // const {globalSlice} = useSelector(({Reducers}) => Reducers)
  console.log(filterData, 'sss')
  const activeBranch = sessionStorage.getItem('selectedBranchName')

  let current_id = new URL(window.location).searchParams.get('id')
  const auth = GetCurrentUser()
  const type = auth.type
  //   const [pageSize, setPageSize] = React.useState(30)
  const [data, setData] = useState()
  const [loader, setLoader] = useState(true)
  // const [pageNumber, setPageNumber] = useState(1)
  // const [pageSize, setPageSize] = useState(30)
  const [acquirerBranch, setAcquirerBranch] = useState('')
  const [selectedRowId, setSelectedRowId] = useState(null)
  const gridRef = useRef(null)

  const handleRowClick = (params) => {
    setSelectedRowId(params.id)
  }

  const handleClickOutside = (event) => {
    if (gridRef.current && !gridRef.current.contains(event.target)) {
      setSelectedRowId(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const getRowClassName = (params) => {
    return params.id === selectedRowId ? 'selected-row' : ''
  }

  const PageSize = (value) => {
    setPageSize(value)
  }

  const PageNumber = (value) => {
    setPageNumber(value)
  }

  const [importCount, setImportCount] = useState(0)
  let navigate = useNavigate()

  const notifySuccess = () =>
    toast.success('Deleted Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const notifyError = () =>
    toast.error('Error Ocurred!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const handleEditClick = (event, row, id) => {
    // alert('hello')
    event.preventDefault()

    if (auth.type === 'cfc') {
      navigate(`${STRINGS.ROUTES.CFC.ADD_MIS_RECORD}?id=${row.id}`)
    }
    if (auth.type === 'branch') {
      navigate(`${STRINGS.ROUTES.BRANCH.UPDATE_MIS}?id=${row.id}`)
    }
  }

  const deleteRecord = async (event, row) => {
    event.preventDefault()

    try {
      const response = await API.delete(`/UserTaskMIS`, {
        data: {
          id: row.row.id,
        },
      })
        .then((res) => {
          notifySuccess()
          API.post(`/UserTaskMIS/search`, {
            pageNumber: pageNumber,
            pageSize: pageSize,
            sort: {
              descending: false,
              columnName: '',
            },
            consignmentNo: '',
            customerName: '',
            cityId: '',
            cellNo: '',
            branch: '',
          }).then((res) => {
            setData(res.data.data.data)
          })
        })
        .catch((e) => {
          notifyError()
        })
    } catch (error) {
      // Handle error
      console.error(error)
    }
  }

  const columns = [
    // Day 1 cloumn
    {
      field: 'SNo',
      headerName: 'S.no',
      width: 50,
      border: '2px solid black',
      headerClassName: 'day1-header',
      disableExport: true,
    },
    {field: 'category', headerName: 'CARD TYPE', width: 150, headerClassName: 'day1-header'},
    {field: 'cifNumber', headerName: 'CIF/REF', width: 150, headerClassName: 'day1-header'},
    {field: 'requestDate', headerName: 'REQUEST DATE', width: 150, headerClassName: 'day1-header'},
    {field: 'receivedAging', headerName: 'Received at Branch Aging', width: 150, headerClassName: 'day1-header'},
    {
      field: 'dispatchDate',
      headerName: 'PRODUCTION DATE/DISPATCHED DATE',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'waivedOrRecovered',
      headerName: 'CHARGES WAIVED OR RECOVERED',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'accountStatusRemarks',
      headerName: 'CHARGES/REASON FOR WAIVER',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'accountNumber', headerName: 'A/C NUMBER', width: 150, headerClassName: 'day1-header'},
    {
      field: 'customerName',
      headerName: 'CUSTOMER NAMES',
      width: 500,
      headerClassName: 'day1-header',
    },
    {
      field: 'newOrReplacement',
      headerName: 'NEW/REPLACEMENT',
      width: 150,
      headerClassName: 'day1-header',
    },
    {
      field: 'requestChannel',
      headerName: 'REQUESTS CHANNEL',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'branch', headerName: 'BRANCH NAME', width: 400, headerClassName: 'day1-header'},
    {
      field: 'accountProduct',
      headerName: 'ACCOUNT PRODUCT',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'ccy', headerName: 'CCY', width: 50, headerClassName: 'day1-header'},
    {field: 'addressOne', headerName: 'ADDRESS-1', width: 500, headerClassName: 'day1-header'},
    {field: 'addressTwo', headerName: 'ADDRESS-2', width: 500, headerClassName: 'day1-header'},
    {
      field: 'cityAndCountry',
      headerName: 'CITY & COUNTRY',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'cellNo', headerName: 'CONTACT NO', width: 150, headerClassName: 'day1-header'},
    {
      field: 'sambaMDCPanNo',
      headerName: 'SAMBA MDC PAN #',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'channel', headerName: 'COURIER CHANNEL', width: 150, headerClassName: 'day1-header'},

    // Day 2 cloumn
    {
      field: 'consignmentNo',
      headerName: '1st CONSIGNMENT #',
      width: 150,
      headerClassName: 'day2-header',
    },

    // Day 3 cloumn
    {
      field: 'deliveryAging',
      headerName: 'DELIVERY/RETURN AGING',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'cnicNo',
      headerName: 'RECEIVER CNIC NUMBER',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'relation',
      headerName: 'RECEIVED BY (RELATION)',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'receiverName',
      headerName: 'RECEIVER NAME/REASON FOR RTN',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY/RTN DATE',
      width: 150,
      headerClassName: 'day3-header',
    },
    {
      field: 'status',
      headerName: '1st DELIVERY STATUS',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnCN',
      headerName: '2nd CONSIGNMENT #',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnBranchName',
      headerName: 'BRANCH NAME: FOR CARD SEND TO BRANCH/CFC',
      width: 350,
      headerClassName: 'day3-header',
    },
    {
      field: 'address3',
      headerName: 'BRANCH ADDRESS',
      width: 250,
      renderCell: (params) => <div style={{whiteSpace: 'normal'}}>{params.value}</div>,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnDispatchDate',
      headerName: 'DATE: DISPATCHED TO BR BY COURIER/CFC',
      width: 265,
      headerClassName: 'day3-header',
    },
    {field: 'quantity', headerName: 'PACKET QTY', width: 150, headerClassName: 'day3-header'},
    {
      field: 'receiverName2',
      headerName: 'BRANCH RECEIVER NAME',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate2',
      headerName: 'DATE: DELIVER TO BR BY COURIER/CFC',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'status2',
      headerName: 'STATUS: DELIVER TO BR BY COURIER/CFC',
      width: 260,
      headerClassName: 'day3-header',
    },
    {field: 'rsAging', headerName: 'RTN AGING', width: 80, headerClassName: 'day3-header'},

    // Other Columns
    {
      field: 'finalReturnedCardStatus',
      headerName: 'CONSIGNMENT FINAL STATUS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {
      field: 'deliveryOrShreddedDate',
      headerName: 'RECIEVED AT BRANCH DATE',
      width: 250,
      headerClassName: 'grey-header',
    },
    {
      field: 'deliveryOrShreddedDate2',
      headerName: 'DELIVERY/SHRED DATE',
      width: 250,
      headerClassName: 'grey-header',
    },
    {field: 'region', headerName: 'REGION', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedRemarksOPS',
      headerName: 'CFC REMARKS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'cardStatus', headerName: 'CARD STATUS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedBranchRemarks',
      headerName: 'BRANCH REMARKS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'remarks', headerName: 'REMARKS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'remarksDeliverShred',
      headerName: 'REMARKS DELIVERED / REMARKS SHREDDED',
      width: 200,
      headerClassName: 'grey-header',
    },
    // Action
    {
      field: 'view',
      headerName: 'VIEW',
      headerClassName: 'grey-header',
      disableExport: true,
      // width: 550,
      renderCell: (row) => {
        if (auth.type === 'cfc' || auth.type === 'branch') {
          return (
            <Button
              variant='contained'
              size='small'
              style={{backgroundColor: '#18A689'}}
              onClick={(e) => navigate(`${STRINGS.ROUTES.CFC.CARD_TRACKING}/${row.id}`)}
              component={Link}
              to={`${STRINGS.ROUTES.CFC.CARD_TRACKING}/${row.id}`}
              // target='_blank'
              rel='noopener noreferrer'
            >
              View
            </Button>
          )
        } else {
          return null // Return null if auth.type is not 'cfc'
        }
      },
    },

    {
      field: 'action',
      headerName: 'UPDATE',
      headerClassName: 'grey-header',
      disableExport: true,
      // width: 550,
      renderCell: (row) => {
        console.log(row, 'row')
        // Check if the button should be disabled based on the new and existing conditions
        const isDisabled =
          (row.row.status === null && auth.type === 'branch') ||
          (row.row.status === 'IP' && auth.type === 'branch') ||
          (row.row.status === 'OK' && auth.type === 'branch') ||
          (row.row.finalReturnedCardStatus === 'Re-dispatched to customer address' &&
            auth.type === 'branch') ||
          (row.row.finalReturnedCardStatus === 'Shredded' &&
            auth.type === 'branch') ||
          (row.row.finalReturnedCardStatus === 'Delivered to customer' &&
            auth.type === 'branch') ||
          (row.row.finalReturnedCardStatus === 'Send to other branch' && auth.type === 'branch')
          // disabledUpdateButton(row.row)

        if (auth.type === 'cfc' || auth.type === 'branch') {
          return (
            <Button
              variant='contained'
              size='small'
              style={{backgroundColor: '#18A689'}}
              disabled={isDisabled} // Use the isDisabled flag here
              onClick={(e) => handleEditClick(e, row)}
              component={Link}
              to={
                auth.type === 'cfc'
                  ? `${STRINGS.ROUTES.CFC.ADD_MIS_RECORD}?id=${row.id}`
                  : auth.type === 'branch'
                  ? `${STRINGS.ROUTES.BRANCH.UPDATE_MIS}?id=${row.id}`
                  : ''
              }
              // target='_blank'
              rel='noopener noreferrer'
            >
              Update
            </Button>
          )
        } else {
          return null // Return null if auth.type is not 'cfc'
        }
      },
    },

    {
      field: 'delete',
      headerName: 'DELETE',
      headerClassName: 'grey-header',
      disableExport: true,
      // width: 550,
      renderCell: (row) => {
        if (auth.type === 'cfc') {
          return (
            <>
              <Popconfirm
                title='Delete Record from MIS?'
                description='Are you sure to delete this Record?'
                onConfirm={(e) => deleteRecord(e, row)}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  variant='contained'
                  size='small'
                  style={{backgroundColor: '#E7423F'}}
                  // onClick={(e) => {
                  //   // alert("Are You Sure You want to Delete This Record?")
                  //   // deleteRecord(e, row)
                  // }}
                >
                  Delete
                </Button>
              </Popconfirm>
            </>
          )
        } else {
          return null // Return null if auth.type is not 'cfc'
        }
      },
    },

    {
      field: 'shred',
      headerName: 'SHRED',
      headerClassName: 'grey-header',
      disableExport: true,
      // width: 550,
      renderCell: (row) => {
        const isDisabled =
          !(row.row.finalReturnedCardStatus === 'Received at Branch') && // Enable if the status is "Received at branch"
          ((row.row.status === null && auth.type === 'branch') ||
            (row.row.status === 'RS' && auth.type === 'branch') ||
            (row.row.status === 'IP' && auth.type === 'branch') ||
            (row.row.status === 'OK' && auth.type === 'branch') ||
            (row.row.finalReturnedCardStatus === 'Re-dispatched to customer address' &&
              auth.type === 'branch') ||
            (row.row.finalReturnedCardStatus === 'Send to other branch' &&
              auth.type === 'branch') ||
            disabledShredButton(row.row))

        if (auth.type === 'branch') {
          return (
            <>
              <Button
                variant='contained'
                size='small'
                style={{backgroundColor: '#E7423F'}}
                // disabled={disabledShredButton(row.row)}
                disabled={isDisabled} // Use the isDisabled flag here
                onClick={(e) => navigate(`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${row.id}`)}
                component={Link}
                to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${row.id}`}
                // target='_blank'
                rel='noopener noreferrer'
              >
                Shred
              </Button>
            </>
          )
        } else {
          return null // Return null if auth.type is not 'cfc'
        }
      },
    },
    // isDelivered isShredded finalReturnedCardStatus

    {
      field: 'deliver',
      headerName: 'DELIVER',
      headerClassName: 'grey-header',
      disableExport: true,
      // width: 550,
      renderCell: (row) => {
        const isDisabled =
          !(row.row.finalReturnedCardStatus === 'Received at Branch') && // Enable if the status is "Received at branch"
          ((row.row.status === null && auth.type === 'branch') ||
            (row.row.status === 'RS' && auth.type === 'branch') ||
            (row.row.status === 'IP' && auth.type === 'branch') ||
            (row.row.status === 'OK' && auth.type === 'branch') ||
            (row.row.finalReturnedCardStatus === 'Re-dispatched to customer address' &&
              auth.type === 'branch') ||
            (row.row.finalReturnedCardStatus === 'Send to other branch' &&
              auth.type === 'branch') ||
            disabledDeliverButton(row.row))
        if (auth.type === 'branch') {
          return (
            <Button
              variant='contained'
              size='small'
              style={{backgroundColor: '#18A689'}}
              // disabled={disabledDeliverButton(row.row)}
              disabled={isDisabled} // Use the isDisabled flag here
              onClick={(e) => navigate(`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${row.id}`)}
              component={Link}
              to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${row.id}`}
              // target='_blank'
              rel='noopener noreferrer'
            >
              Deliver
            </Button>
          )
        } else {
          return null // Return null if auth.type is not 'cfc'
        }
      },
    },
  ]
  const bookData = []

  // useEffect(() => {
  //   API.post(`/UserTaskMIS/search`, {
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //     sort: {
  //       descending: false,
  //       columnName: '',
  //     },
  //     consignmentNo: '',
  //     customerName: '',
  //     cityId: '',
  //     cellNo: '',
  //     branch: '',
  //   }).then((res) => {
  //     setImportCount(res.data.data.totalRecords)
  //     setData(res.data.data.data)
  //     setLoader(false)
  //     console.log('new apiiiii', res)
  //   })
  // }, [addMISUpdate, refreshData, pageNumber, pageSize])

  function hasTrueValues(obj) {
    for (let key in obj) {
      if (obj[key]) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    let requestObject = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sort: {
        descending: false,
      },
      // consignmentNo: '',
      // customerName: '',
      cityId: '',
      branch: auth.type === 'branch' ? activeBranch : auth.type === 'cfc' ? '' : '',
      // cellNo: '',
      // branch: '',
    }
    let newObj = {}
    if (hasTrueValues(filterData)) {
      newObj = {
        ...requestObject,
        ...filterData,
      }
    } else {
      newObj = requestObject
    }

    API.post(`/UserTaskMIS/search`, newObj).then((res) => {
      setImportCount(res.data.data.totalRecords)
      setData(res.data.data.data)
      setLoader(false)
    })
  }, [filterData, addMISUpdate, refreshData, pageNumber, pageSize, current_id, acquirerBranch])

  data?.forEach((element, index) => {
    bookData.push({
      id: element.id,
      SNo: index + 1 + (pageNumber - 1) * pageSize,
      cifNumber: element.cifNumber,
      consignmentNo: element.consignmentNo,
      customerName: element.customerName,
      address1: element.address1,
      address2: element.address2,
      cityName: element.cityName,
      cellNo: element.cellNo,
      cardNo: element.cardNo,
      branch: element.branch,
      notes: element.notes,
      sendCompanyName: element.sendCompanyName,
      bkgDate: `${element.bkgDate ? convertISODate(element.bkgDate) : ''}`,
      cnicNo: element.cnicNo,
      receiverName: element.receiverName,
      status: element.status,
      remarks: element.remarks,
      branch: element.branch,
      bkgDate2: `${element.bkgDate2 ? convertISODate(element.bkgDate2) : ''}`,
      receiverName2: element.receiverName2,
      status2: element.status2,
      quantity: element.quantity ? element.quantity : '',
      relation: element.relation,
      consignmentNo2: element.consignmentNo2,
      branch2: element.branch2,
      address3: element.address3,
      deliveryDate: `${element.deliveryDate ? convertISODate(element.deliveryDate) : ''}`,
      deliveryDate2: `${element.deliveryDate2 ? convertISODate(element.deliveryDate2) : ''}`,
      requestDate: `${element.requestDate ? convertISODate(element.requestDate) : ''}`,
      dispatchDate: `${element.dispatchDate ? convertISODate(element.dispatchDate) : ''}`,
      waivedOrRecovered: element.waivedOrRecovered,
      accountStatus: element.accountStatus,
      accountNumber: element.accountNumber,
      newOrReplacement: element.newOrReplacement,
      requestChannel: element.requestChannel,
      channel: element.channel,
      branch: element.branch,
      accountProduct: element.accountProduct,
      ccy: element.ccy,
      addressOne: element.addressOne,
      addressTwo: element.addressTwo,
      category: element.category,
      receivedAging:element.receivedAging,
      cityAndCountry: element.cityAndCountry,
      sambaMDCPanNo: element.sambaMDCPanNo,
      cnicNo: element.cnicNo ? element.cnicNo : '',
      returnCN: element.returnCN,
      returnBranchName: element.returnBranchName,
      returnDispatchDate: `${
        element.returnDispatchDate ? convertISODate(element.returnDispatchDate) : ''
      }`,
      finalReturnedCardStatus:
        element.finalReturnedCardStatus ||
        (element.dispatchDate && (element.status === '' || element.status === null)
          ? 'Delivered to Courier'
          : element.dispatchDate && element.status && element.deliveryDate2
          ? 'To be received by branch'
          : element.dispatchDate && element.status === 'RS'
          ? 'Consignment Return'
          : element.dispatchDate && element.status === 'OK'
          ? 'Consignment Delivered'
          : element.dispatchDate && element.status === 'IP'
          ? 'Status Pending'
          : ''),
      // finalReturnedCardStatus: element.finalReturnedCardStatus,
      deliveryOrShreddedDate: `${
        element.deliveryOrShreddedDate ? convertISODate(element.deliveryOrShreddedDate) : ''
      }`,
      deliveryOrShreddedDate2: `${
        element.deliveryOrShreddedDate2 ? convertISODate(element.deliveryOrShreddedDate2) : ''
      }`,
      region: element.region,
      cardStatus: element.cardStatus,
      branchRemarks: element.branchRemarks,
      rsAging: element.rsAging,
      accountStatusRemarks: element.accountStatusRemarks,
      returnedRemarksOPS: element.returnedRemarksOPS,
      returnedBranchRemarks: element.returnedBranchRemarks,
      deliveryAging: element.deliveryAging,
      isDelivered: element.isDelivered,
      isShredded: element.isShredded,
      remarksDeliverShred: element.remarksDeliver ? element.remarksDeliver : element.remarksShred,
    })
  })

  const CustomerToolBar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    )
  }

  console.log('mis data', data)

  return (
    <>
      <div style={{height: '75vh', width: '100%'}}>
        {loader ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: '75vh',
              width: '100%',
              '& .brown-header': {
                backgroundColor: '#FEE598',
              },
              '& .day1-header': {
                backgroundColor: '#00B0F0',
              },
              '& .grey-header': {
                backgroundColor: '#DBDBDB',
              },
              '& .day2-header': {
                backgroundColor: '#FFFF3E',
              },
              '& .day3-header': {
                backgroundColor: '#FFC000',
              },
            }}
          >
            <div style={{height: 600, width: '100%'}} ref={gridRef}>
              <DataGrid
                components={{Toolbar: CustomerToolBar}}
                showCellRightBorder={true}
                rows={bookData}
                rowCount={importCount}
                columns={columns}
                experimentalFeatures={{newEditingApi: true}}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[30, 50, 70, 100]}
                pagination
                page={pageNumber - 1}
                pageSize={pageSize}
                paginationMode='server'
                onPageChange={(newPage) => {
                  setPageNumber(newPage + 1)
                }}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize)
                }}
                onRowClick={handleRowClick}
                getRowClassName={getRowClassName}
                {...data}
              />
            </div>
          </Box>
        )}
      </div>
      <ToastContainer />
    </>
  )
}

export default MisTable
