import React from 'react';

const DefaultTitleBar = ({ title, showActions = false, rightContent }) => {
    return (
        <div id="kt_app_toolbar" class="app-toolbar my-3 " style={{background:"white", height: "46px"}}>
            {/*<!--begin::Toolbar container-->*/}
            <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack" >
                {/*<!--begin::Page title-->*/}
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    {/*<!--begin::Title-->*/}
                    <h1
                        class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        {title}</h1>
                    {/*<!--end::Title-->*/}

                    {/*<!--begin::Breadcrumb-->*/}
                    {/*<ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li class="breadcrumb-item text-muted">
                            <a href="/cfc/index.html"
                                class="text-muted text-hover-primary">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li class="breadcrumb-item text-muted">Branch Screen</li>
                    </ul>*/}
                    {/*<!--end::Breadcrumb-->*/}
                </div>
                {/*<!--end::Page title-->*/}
                {/*<!--begin::Actions-->*/}
                {
                    showActions && (
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            {/*<!--begin::Filter menu-->*/}
                            <div id="kt_app_toolbar_container"
                                class="app-container container-fluid d-flex flex-stack">
                                {/*<!--begin::Page title-->*/}
                                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3"/>
                                {/*<!--end::Page title-->*/}
                                {/*<!--begin::Actions-->*/}
                                {
                                    rightContent() !== undefined && (
                                        rightContent()
                                    )
                                }
                                {/*<!--end::Actions-->*/}
                            </div>
                        </div>
                    )
                }

                {/*<!--end::Actions-->*/}
            </div>
            {/*<!--end::Toolbar container-->*/}
        </div>
    );
};

export default DefaultTitleBar;