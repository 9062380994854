import React from 'react'

const FilterMenu = () => {
  return (
    <div>
      <select
        style={{width: '110px', height: '37px'}}
        className='form-select form-select-solid form-select-sm'
        data-control='select2'
        data-dropdown-css-className='w-200px'
        data-placeholder='Select an option'
        data-hide-search='true'
      >
        <option value='1' selected hidden>
          Filter By
        </option>
        <option value='2'>Today</option>
        <option value='3'>OverDue</option>
        <option value='4'>OverAll</option>
      </select>
    </div>
  )
}

export default FilterMenu
