import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import Loader from '../Loader/Loader'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../utils/Enums'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const TimelineTable = () => {
  let navigate = useNavigate()
  const [pageSize, setPageSize] = React.useState(10)
  const [data, setData] = useState()
  const [loader, setLoader] = useState(true)

  // const handleEditClick = (event, row , id) => {
  //     // alert('hello')
  //     event.preventDefault();
  //     console.log("event",row.row)
  //     navigate(`${STRINGS.ROUTES.BRANCH.EDIT_BOOK}?id=${row.id}`)
  // }

  const columns = [
    // { field: 'id', headerName: 'ID', width: 50 },
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'bookTitle', headerName: 'Remaining Days', width: 100},
    {field: 'quantity', headerName: 'Aging', width: 50},
    {field: 'subjectName', headerName: 'Task Status', width: 100},
    {field: 'authorName', headerName: 'Card Status', width: 100},
    {field: 'edition', headerName: 'Destruction Reason', width: 150},
    {field: 'languageName', headerName: 'Reason For Captured', width: 150},
    {field: 'bookTypeName', headerName: 'Payment Type', width: 100},
    {field: 'yearofPublishing', headerName: 'Expiry', width: 100},
    {field: 'publisherName', headerName: 'CIF', width: 100},
    {field: 'barcode', headerName: 'Customer Name', width: 150},
    {field: 'cardNumber', headerName: 'Card Number', width: 150},
    {field: 'captureDate', headerName: 'Capture Date', width: 100},
    {field: 'captureTime', headerName: 'Capture Time', width: 100},
    {field: 'acquiringBank', headerName: 'Acq Bank', width: 130},
    {field: 'acquiringBranch', headerName: 'Acq Branch', width: 130},
    {field: 'issuerBank', headerName: 'ISS Bank', width: 130},
    {field: 'issuerBranch', headerName: 'ISS Branch', width: 130},
    {field: 'note', headerName: 'Note', width: 130},
  ]
  const bookData = []

  // useEffect(() =>{
  //     axios.get(`https://lms.3em.tech/Book/Get`)
  //     .then((res) =>{
  //         setData(res.data)
  //         setLoader(false)
  //         console.log('booksssssssss', res);
  //     })
  // },[])

  data?.forEach((element, index) => {
    bookData.push({
      id: element.id,
      SNo: index + 1,
      bookTitle: element.bookTitle,
      quantity: element.quantity,
      subjectName: element.subjectName,
      authorName: element.authorName,
      edition: element.edition,
      languageName: element.languageName,
      bookTypeName: element.bookTypeName,
      yearofPublishing: element.yearofPublishing,
      publisherName: element.publisherName,
      barcode: element.barcode,
      cardNumber: element.cardNumber,
      captureDate: element.captureDate,
      captureTime: element.captureTime,
      acquiringBank: element.acquiringBank,
      acquiringBranch: element.acquiringBranch,
      issuerBank: element.issuerBank,
      issuerBranch: element.issuerBranch,
      note: element.note,
    })
  })
  //     data && data.map((item,index)=>{

  //    })
  return (
    <div style={{height: 200, width: '100%'}}>
      {/* { loader ? <Loader /> : */}
      <DataGrid
        rows={bookData}
        columns={columns}
        showCellRightBorder={true}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        {...data}
      />
      {/* } */}
    </div>
  )
}

export default TimelineTable
