import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const cardSearchSchema = Yup.object({
    card_no: Yup.string()
        .min(16, 'Card number must have 16 digit')
        .required('Enter Card Number')

})

const initialValues = {
    card_no: '',
}


const CardSearch = () => {

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues,
        validationSchema: cardSearchSchema,
        onSubmit: (values) => {
            console.log(values);
        }
    })
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class='d-flex align-items-center gap-2 gap-lg-3'>
                    {/*<!--begin::Secondary button-->*/}
                    {/*<!--end::Secondary button-->*/}
                    {/*<!--begin::Primary button-->*/}
                    <div class='d-flex align-items-center position-relative my-1'>
                        {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                        <span class='svg-icon svg-icon-3 position-absolute ms-3'>
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <rect
                                    opacity='0.5'
                                    x='17.0365'
                                    y='15.1223'
                                    width='8.15546'
                                    height='2'
                                    rx='1'
                                    transform='rotate(45 17.0365 15.1223)'
                                    fill='currentColor'
                                ></rect>
                                <path
                                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                    fill='currentColor'
                                ></path>
                            </svg>
                        </span>
                        {/*<!--end::Svg Icon-->*/}
                        <div>
                            <input
                                type='text'
                                id='card_no'
                                name='card_no'
                                class='form-control form-control-solid form-select-sm mb-3 mb-lg-0 ps-9'
                                placeholder='Enter Card number'
                                maxlength="16"
                                value={values.card_no}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            className='btn btn-primary btn-sm'
                            type='onSubmit'
                        >
                            Search
                        </button>
                    </div>
                    {/*<!--end::Primary button-->*/}
                </div>

                {errors.card_no && touched.card_no ? (
                    <p className='form-error text-danger my-1'>{errors.card_no}</p>
                ) : null}
            </form>
        </>
    )
}

export default CardSearch
