import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {OneLinkWrapper} from '../pages/one-link/OneLinkWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {STRINGS} from '../../utils/Enums'
import {GetCurrentUser} from '../../utils/DefaultFunctions'
import EditForm from '../pages/BranchMain/Inventory/forms/EditForm'
import SendToCFC from '../pages/CFC/Forms/SendToCFC'
import AddCFCForm from '../pages/CFC/CFCScreen/forms/AddCFCForm'
import AddFormInventory from '../pages/BranchMain/Inventory/forms/AddFormInventory'
import {useSelector} from 'react-redux'
import {useAppSelector} from '../../utils/hook'
import CreateGroup from '../pages/Administrator/configuration/GroupCreate'
import CardTimeline from '../pages/Administrator/configuration/CardsTimelne/CardTimeline'
import SambaCare from '../pages/SambaCare/Main Screen'
import SambaCareHistory from '../pages/SambaCare/history/SambaCareHistory'
import AdminCalender from '../pages/Administrator/configuration/Calender/AdminCalender'
import CreateRoles from '../pages/Administrator/configuration/CreateRoles/CreateRoles'
import ConfigureOrganizationInfo from '../pages/Administrator/configuration/Organization'
import ComplainBranchDefault from '../pages/ComplainsBranch/ComplainsBranchDefault'
import BranchComplainForm from '../pages/ComplainsBranch/Forms/BranchComplainForm'
import ViewComplain from '../pages/ComplainsBranch/Forms/ViewComplain'
import ViewMis from '../pages/BranchMain/Inventory/forms/ViewMis'
import MisMain from '../pages/BranchMain/Inventory/items/Main'
import BranchAddCard from '../pages/BranchMain/Inventory/forms/BranchAddCard'
import MisUpdate from '../pages/Mis/MisUpdate'
import AddMisRecord from '../pages/Mis/AddMisRecord'
// import CardTracking from
// import AdminDashboard from '../pages/Administrator/Dashboard'

// import { CFCDashboard } from '../pages/CFC';
// import { BranchDefault } from '../pages/Branch';
// import { CFCDefault } from '../pages/CFC/CFCScreen';
// import { HistoryDefault } from '../pages/History';
// import { CardEmboseDefault } from '../pages/CardEmbose';
// import { SettingsDefault } from '../pages/Settings';

type Slices = {authSlice: any; branchSlice: any}

const PrivateRoutes = () => {
  const auth = GetCurrentUser()
  let branchId = sessionStorage.getItem('branchId')
  const ProcessDetailPage = lazy(() => import('../modules/processDetail/ProcessDetailPage'))
  const {authSlice, branchSlice} = useAppSelector(({Reducers}) => Reducers as Slices)

  // CFC Screens
  const CFCDashboard = lazy(() => import('../pages/CFC'))
  const BranchDefault = lazy(() => import('../pages/Branch'))
  const CFCDefault = lazy(() => import('../pages/CFC/CFCScreen'))
  const HistoryDefault = lazy(() => import('../pages/History'))
  const CardEmboseDefault = lazy(() => import('../pages/CardEmbose'))
  const SettingsDefault = lazy(() => import('../pages/Settings'))
  const AddCardCFC = lazy(() => import('../pages/CFC/CFCScreen/forms/AddCFCForm'))
  const CardTracking = lazy(() => import('../pages/CFC/CardTracking'))

  // Branch Screens
  const BranchMainDashboard = lazy(() => import('../pages/BranchMain'))
  const BranchMain = lazy(() => import('../pages/BranchMain/BranchScreen/index'))
  const InventoryDefault = lazy(() => import('../pages/BranchMain/Inventory/InventoryDefault'))
  const BranchRequestForm = lazy(() => import('../pages/BranchMain/RequestForm/RequestForm'))
  const EditForm = lazy(() => import('../pages/BranchMain/Inventory/forms/EditForm'))
  {
    /*added by jahanzaib*/
  }
  const ShredForm = lazy(() => import('../pages/CFC/Forms/ShredForm'))
  {
    /*added by jahanzaib*/
  }
  const DeliveryForm = lazy(() => import('../pages/CFC/Forms/DeliveryForm'))
  {
    /*added by jahanzaib*/
  }
  const SendToCFC = lazy(() => import('../pages/CFC/Forms/SendToCFC'))
  {
    /*added by jahanzaib*/
  }
  const SendToBranch = lazy(() => import('../pages/CFC/Forms/SendToBranch'))
  {
    /*added by jahanzaib*/
  }
  const Branch = lazy(() => import('../pages/BranchMain/BranchScreen/index'))
  {
    /*added by jahanzaib*/
  }
  const InventoryAddCard = lazy(
    () => import('../pages/BranchMain/Inventory/forms/AddFormInventory')
  )
  {
    /*added by jahanzaib*/
  }

  // console.log('GetCurrentUser', auth)

  // ADMINISTRATOR
  const AdminDashboard = lazy(() => import('../pages/Administrator/Dashboard'))

  const ConfigureRequestChannel = lazy(
    () => import('../pages/Administrator/configuration/RequestChannelConfig/index')
  )
  const ConfigureRegion = lazy(
    () => import('../pages/Administrator/configuration/RegionConfig/index')
  )

  const ConfigureCity = lazy(() => import('../pages/Administrator/configuration/CityConfig/index'))

  const ConfigureNewReplacement = lazy(
    () => import('../pages/Administrator/configuration/newReplacementConfig/index')
  )
  const ConfigureCardType = lazy(
    () => import('../pages/Administrator/configuration/CardTypeConfig/index')
  )

  const ConfigureCountry = lazy(
    () => import('../pages/Administrator/configuration/CountryConfig/index')
  )

  const ConfigureProvince = lazy(
    () => import('../pages/Administrator/configuration/ProvinceConfig/index')
  )

  const ConfigureReturnCard = lazy(
    () => import('../pages/Administrator/configuration/CardReturnConfig/index')
  )
  const ConfigureCardEmboseUserInfo = lazy(
    () => import('../pages/Administrator/configuration/UserConfig/index')
  )

  const ConfigureBranchInfo = lazy(
    () => import('../pages/Administrator/configuration/BranchConfig/index')
  )
  const ConfigureDepartment = lazy(
    () => import('../pages/Administrator/configuration/DepartmentConfig')
  )
  const ConfigureDesignation = lazy(
    () => import('../pages/Administrator/configuration/DesignationConfig')
  )

  //SAMBACARE
  const SambaCare = lazy(() => import('../pages/SambaCare/Main Screen/index'))

  //Checker
  const CheckerDashboard = lazy(() => import('../pages/Checker/Dashboard/index'))
  const CheckerMainScreen = lazy(() => import('../pages/Checker/Processes/MainScreen'))
  const ForceTask = lazy(() => import('../pages/Checker/Processes/ForceTask'))
  const CheckerHistory = lazy(() => import('../pages/Checker/Processes/History'))

  const OnApproval = lazy(
    () => import('../pages/Administrator/configuration/ForApproval/OnApproval')
  )

  // import Dashboard from

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper/>}/> */}
        {auth.type === 'cfc' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.CFC.CARD_TRACKING}/:id`}
              element={
                <SuspensedView>
                  <CardTracking />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.DASHBOARD}`}
              element={
                <SuspensedView>
                  <CFCDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORY}`}
              element={
                <SuspensedView>
                  <MisMain />
                  {/* <InventoryDefault /> */}
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH_SCREEN}`}
              element={
                <SuspensedView>
                  <BranchDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC_SCREEN}`}
              element={
                <SuspensedView>
                  <CFCDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.HISTORY_SCREEN}`}
              element={
                <SuspensedView>
                  <HistoryDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CARD_EMBOSE}`}
              element={
                <SuspensedView>
                  <CardEmboseDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.EDITFORM}`}
              element={
                <SuspensedView>
                  <EditForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/:id`}
              element={
                <SuspensedView>
                  <ShredForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/:id`}
              element={
                <SuspensedView>
                  <DeliveryForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToCFC />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToBranch />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.CFC.ADDCARDCFC}`}
              element={
                <SuspensedView>
                  <AddCFCForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.CFC.COMPLAINS}`}
              element={
                <SuspensedView>
                  <ComplainBranchDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.ADD_COMPLAINS}`}
              element={
                <SuspensedView>
                  <BranchComplainForm />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.VIEW_COMPLAINS}`}
              element={
                <SuspensedView>
                  <ViewComplain />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.VIEW_MIS}`}
              element={
                <SuspensedView>
                  <ViewMis />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.UPDATE_MIS}`}
              element={
                <SuspensedView>
                  <MisUpdate />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.ADD_MIS_RECORD}`}
              element={
                <SuspensedView>
                  <AddMisRecord />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'branch' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.DASHBOARD}`}
              element={
                <SuspensedView>
                  <BranchMainDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.CARD_TRACKING}/:id`}
              element={
                <SuspensedView>
                  <CardTracking />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORY}`}
              element={
                <SuspensedView>
                  <MisMain />
                  {/* <InventoryDefault /> */}
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.EDITFORM}`}
              element={
                <SuspensedView>
                  <EditForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.INVENTORYADDCARD}`}
              element={
                <SuspensedView>
                  <AddFormInventory />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/:id`}
              element={
                <SuspensedView>
                  <ShredForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/:id`}
              element={
                <SuspensedView>
                  <DeliveryForm />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToCFC />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/:id`}
              element={
                <SuspensedView>
                  <SendToBranch />
                </SuspensedView>
              }
            />{' '}
            {/*added by jahanzaib*/}
            <Route
              path={`${STRINGS.ROUTES.BRANCH.MAIN}`}
              element={
                <SuspensedView>
                  <BranchMain />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.CARDDETAILS}`}
              element={
                <SuspensedView>
                  <Branch />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`}
              element={
                <SuspensedView>
                  <BranchRequestForm />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.HISTORY}`}
              element={
                <SuspensedView>
                  <HistoryDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`}
              element={
                <SuspensedView>
                  <CardEmboseDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.COMPLAINS}`}
              element={
                <SuspensedView>
                  <ComplainBranchDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.ADD_COMPLAINS}`}
              element={
                <SuspensedView>
                  <BranchComplainForm />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.VIEW_COMPLAINS}`}
              element={
                <SuspensedView>
                  <ViewComplain />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.VIEW_MIS}`}
              element={
                <SuspensedView>
                  <ViewMis />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.ADD_CARD}`}
              element={
                <SuspensedView>
                  <BranchAddCard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.BRANCH.UPDATE_MIS}`}
              element={
                <SuspensedView>
                  <AddMisRecord />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'administrator' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_COUNTRY}`}
              element={
                <SuspensedView>
                  <ConfigureCountry />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.ON_APPROVAL}`}
              element={
                <SuspensedView>
                  <OnApproval />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_PROVINCE}`}
              element={
                <SuspensedView>
                  <ConfigureProvince />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_REGION}`}
              element={
                <SuspensedView>
                  <ConfigureRegion />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CITY}`}
              element={
                <SuspensedView>
                  <ConfigureCity />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH}`}
              element={
                <SuspensedView>
                  <ConfigureBranchInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.ADMIN}`}
              element={
                <SuspensedView>
                  <AdminDashboard />
                </SuspensedView>
              }
            />

            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureCardEmboseUserInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CARD_TYPE}`}
              element={
                <SuspensedView>
                  <ConfigureCardType />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_DEPARTMENT}`}
              element={
                <SuspensedView>
                  <ConfigureDepartment />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_DESIGNATION}`}
              element={
                <SuspensedView>
                  <ConfigureDesignation />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CARD_RETURNED}`}
              element={
                <SuspensedView>
                  <ConfigureReturnCard />
                </SuspensedView>
              }
            />

            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_REQUEST_CHANNEL}`}
              element={
                <SuspensedView>
                  <ConfigureRequestChannel />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_NEW_REPLACEMENT}`}
              element={
                <SuspensedView>
                  <ConfigureNewReplacement />
                </SuspensedView>
              }
            />
            {/* <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureCardType />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureCardEmboseUserInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureBranchInfo />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_ORGANIZATION_INFO}`}
              element={
                <SuspensedView>
                  <ConfigureOrganizationInfo />
                </SuspensedView>
              }
            /> */}
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_GROUP}`}
              element={
                <SuspensedView>
                  <CreateGroup />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_ROLES}`}
              element={
                <SuspensedView>
                  <CreateRoles />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}`}
              element={
                <SuspensedView>
                  <CardTimeline />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'samba phone' && (
          <>
            {/* <Route path={`${STRINGS.ROUTES.SAMBACARE.MAIN}`} element={<SuspensedView><SambaCare /></SuspensedView>} /> */}
            <Route
              path={`${STRINGS.ROUTES.SAMBACARE.MAIN}`}
              element={
                <SuspensedView>
                  <SambaCare />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.CARD_TRACKING}/:id`}
              element={
                <SuspensedView>
                  <CardTracking />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.SAMBACARE.VIEW_MIS}`}
              element={
                <SuspensedView>
                  <ViewMis />
                  {/* <InventoryDefault /> */}
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.SAMBACARE.SAMBA_CARE_HISTORY}`}
              element={
                <SuspensedView>
                  <SambaCareHistory />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.COMPLAINS}`}
              element={
                <SuspensedView>
                  <ComplainBranchDefault />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.ADD_COMPLAINS}`}
              element={
                <SuspensedView>
                  <BranchComplainForm />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.VIEW_COMPLAINS}`}
              element={
                <SuspensedView>
                  <ViewComplain />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'management' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.MANAGEMENT.VIEW_MIS}`}
              element={
                <SuspensedView>
                  <ViewMis />
                  {/* <InventoryDefault /> */}
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.MANAGEMENT.MAIN}`}
              element={
                <SuspensedView>
                  <SambaCare />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CFC.CARD_TRACKING}/:id`}
              element={
                <SuspensedView>
                  <CardTracking />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.HISTORY_SCREEN}`}
              element={
                <SuspensedView>
                  <HistoryDefault />
                </SuspensedView>
              }
            />
          </>
        )}
        {auth.type === 'branch checker' && (
          <>
            <Route
              path={`${STRINGS.ROUTES.CHECKER.DASHBOARD}`}
              element={
                <SuspensedView>
                  <CheckerDashboard />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.MAIN_SCREEN}`}
              element={
                <SuspensedView>
                  <CheckerMainScreen />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.FORCE_TASK}`}
              element={
                <SuspensedView>
                  <ForceTask />
                </SuspensedView>
              }
            />
            <Route
              path={`${STRINGS.ROUTES.CHECKER.HISTORY}`}
              element={
                <SuspensedView>
                  <CheckerHistory />
                </SuspensedView>
              }
            />
          </>
        )}
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.CARD_DES_MEMO}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MEMO.OTHER_BANK_CFC}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.CREATE_DESIGNATION}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.USER_MANAGER}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.MASTER.GROUP_EMAIL}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route
          path={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`}
          element={
            <SuspensedView>
              <SettingsDefault />
            </SuspensedView>
          }
        />
        <Route path='oneLink/IBFT' element={<OneLinkWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path={`${STRINGS.ROUTES.ONE_LINK_PROCESS_DETAIL}/*`}
          element={
            <SuspensedView>
              <ProcessDetailPage />
            </SuspensedView>
          }
        />
        {/*<Route*/}
        {/*    path='crafted/pages/profile/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <ProfilePage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    path='crafted/pages/wizards/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <WizardsPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    path='crafted/widgets/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <WidgetsPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    path='crafted/account/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <AccountPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    path='apps/chat/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <ChatPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    path='apps/user-management/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <UsersPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/* Page Not Found */}
        <Route
          path='*'
          element={
            <Navigate
              to={`${
                auth.type === 'branch'
                  ? `${STRINGS.ROUTES.DASHBOARD}?id=${branchSlice.captureBranchId}`
                  : auth.type === 'administrator'
                  ? `${STRINGS.ROUTES.ADMINISTRATOR.ADMIN}`
                  : auth.type === 'samba phone'
                  ? `${STRINGS.ROUTES.CFC.COMPLAINS}`
                  : auth.type === 'cfc'
                  ? `${STRINGS.ROUTES.CFC.DASHBOARD}`
                  : auth.type === 'branch checker'
                  ? `${STRINGS.ROUTES.CHECKER.DASHBOARD}`
                  : auth.type === 'management'
                  ? `${STRINGS.ROUTES.MANAGEMENT.VIEW_MIS}`
                  : ``
              }`}
            />
          }
        />{' '}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
