import * as Yup from "yup";

export const Schema = Yup.object({
    note: Yup.string().required("Enter Note"),
    deliveryDate: Yup.string().required("Select Delivery Date"),
    deliveryTime: Yup.string().required("Select Delivery Time"),
})

export const recievedThroughSchema = Yup.object({
    courier_num: Yup.string().min(2).max(20).required("Enter Courier Number"),
})

export const cardHoldSchema = Yup.object({
    reason: Yup.string().required("Enter Reason"),
    days_to_hold: Yup.string().required("Enter Days to Hold"),
})

export const sendToCfcNote = Yup.object({
    cfcNote: Yup.string().required("Enter Note"),
})

export const sendToCfcCourierDetail = Yup.object({
    cfcDetail: Yup.string().required("Enter Details "),
    courierNumber: Yup.string().required("Enter Courier Number "),
    // dispatchTime: Yup.string().required("Enter Dispatch Time "),
})
export const sendToCfcRiderDetail = Yup.object({
    cfcDetail: Yup.string().required("Enter Details "),
    // dispatchTimeRider: Yup.string().required("Enter Dispatch Time "),
})

export const sendToBranchNote = Yup.object({
    branchNote: Yup.string().required("Enter Note"),
})

export const sameBank = Yup.object({
    customerName: Yup.string().required("Enter Customer Name"),
    cardNumber: Yup.number().required("Enter Card Number"),
    expiry: Yup.string().required("Enter Expiry Date").min(4).max(5),
    cardType: Yup.string().required("Select Card Type"),
    note: Yup.string().required("Enter Note"),
    cif: Yup.string().required("Enter cif").min(6).max(6),
    status: Yup.string().required("Select Card Status"),
    number: Yup.string().required("Enter 16-20 Digits ").min(16).max(20),
    captureReason: Yup.string().required("Enter Captured Reason"),
    issueBranch: Yup.string().required("Select Issuer Branch"),
    issueBank: Yup.string().required("Select Issuer Bank"),
    cnic: Yup.string().required("Enter 13 Digits ").min(13).max(13),
    captureDate: Yup.string().required("Select Capture Date & Time"),
})

export const sendToBranchForCourierDetail = Yup.object({
    branchDetail: Yup.string().required("Enter Details "),
    courierNumber: Yup.string().required("Enter Courier Number "),
    dispatchTime: Yup.string().required("Enter Dispatch Time "),
    customerName: Yup.string().required("Enter Customer Name"),
    cardNumber: Yup.number().required("Enter Card Number"),
    expiry: Yup.string().required("Enter Expiry Date").min(4).max(5),
    cardType: Yup.string().required("Select Card Type"),
    note: Yup.string().required("Enter Note"),
    cif: Yup.string().required("Enter cif"),
    status: Yup.string().required("Select Card Status"),
    number: Yup.string().required("Enter 16 Digits ").min(16).max(16),
    captureReason: Yup.string().required("Enter Captured Reason"),
    issueBranch: Yup.string().required("Select Issuer Branch"),
    issueBank: Yup.string().required("Select Issuer Bank"),
    captureDate: Yup.string().required("Select Capture Date & Time"),
})
export const sendToBranchForRiderDetail = Yup.object({
    branchDetail: Yup.string().required("Enter Details "),
    dispatchTimeRider: Yup.string().required("Enter Dispatch Time "),
    customerName: Yup.string().required("Enter Customer Name"),
    cardNumber: Yup.number().required("Enter Card Number"),
    expiry: Yup.string().required("Enter Expiry Date").min(4).max(5),
    cardType: Yup.string().required("Select Card Type"),
    note: Yup.string().required("Enter Note"),
    cif: Yup.string().required("Enter cif"),
    status: Yup.string().required("Select Card Status"),
    number: Yup.string().required("Enter 16 Digits ").min(16).max(16),
    captureReason: Yup.string().required("Enter Captured Reason"),
    issueBranch: Yup.string().required("Select Issuer Branch"),
    issueBank: Yup.string().required("Select Issuer Bank"),
    captureDate: Yup.string().required("Select Capture Date & Time"),
})

export const shredFormSchema = Yup.object({
    reason_late: Yup.string().required("Enter Reason For late"),
    // note: Yup.string().required("Enter Note"),
})

export const shredFormSchema2 = Yup.object({
    note: Yup.string().required("Enter Note"),
})

export const addCardInventorySchema = Yup.object({
    acq_bank: Yup.string().required("Enter Acquirer Bank"),
    acq_branch: Yup.string().required("Enter Acquirer Branch"),
    note: Yup.string().required("Enter Note"),
    // card_no: Yup.string()
    // .min(16, 'card no must have 16 digit')
    // // .max(16, 'card no must have 16 digit')
    // .required ('Enter Card Number')
})

export const addNewCardCourierDetail = Yup.object({

    acq_bank: Yup.string().required("Enter Acquirer Bank "),
    courierNumber: Yup.string().required("Enter Courier Number "),
    dispatchTime: Yup.string().required("Enter Dispatch Time "),
    customerName: Yup.string().required("Enter Customer Name"),
    cardNumber: Yup.number().required("Enter Card Number"),
    expiry: Yup.string().required("Enter Expiry Date").min(4).max(5),
    cardType: Yup.string().required("Select Card Type"),
    note: Yup.string().required("Enter Note"),
    cif: Yup.string().required("Enter cif"),
    status: Yup.string().required("Select Card Status"),
    number: Yup.string().required("Enter 16 Digits ").min(16).max(16),
    cnic: Yup.string().required("Enter 13 Digits ").min(13).max(13),
    captureReason: Yup.string().required("Enter Captured Reason"),
    issueBranch: Yup.string().required("Select Issuer Branch"),
    issueBank: Yup.string().required("Select Issuer Bank"),
    captureDate: Yup.string().required("Select Capture Date & Time"),
})

export const addNewCardRiderDetail = Yup.object({
    dispatchTimeRider: Yup.string().required("Enter Dispatch Time "),
    acq_bank: Yup.string().required("Enter Acquirer Bank "),
    customerName: Yup.string().required("Enter Customer Name"),
    cardNumber: Yup.number().required("Enter Card Number"),
    expiry: Yup.string().required("Enter Expiry Date").min(4).max(5),
    cardType: Yup.string().required("Select Card Type"),
    note: Yup.string().required("Enter Note"),
    cif: Yup.string().required("Enter cif"),
    status: Yup.string().required("Select Card Status"),
    number: Yup.string().required("Enter 16 Digits ").min(16).max(16),
    cnic: Yup.string().required("Enter 13 Digits ").min(13).max(13),
    captureReason: Yup.string().required("Enter Captured Reason"),
    issueBranch: Yup.string().required("Select Issuer Branch"),
    issueBank: Yup.string().required("Select Issuer Bank"),
    captureDate: Yup.string().required("Select Capture Date & Time"),
})
