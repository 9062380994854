import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../../utils/Enums'
import CardHold from '../../CFC/CFCScreen/forms/CardHold'
import FilterMenu from '../../../SharedComponent/Filter/FilterMenu'
import {convertISODate, convertISOTime, GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {API} from '../../../../utils/services'
import {useDispatch, useSelector} from 'react-redux'
import {CaptureBranch} from '../../BranchMain/BranchSlice/branch.slice'
import {RemainingDays, ResetState} from '../../CFC/Slices/CFC.slice'
import CardActive from '../../CFC/CFCScreen/forms/ActiveModal'
import TablesBranch from '../../../SharedComponent/DataTable/BranchMain'

const SambaCareHistory = () => {
  let user = GetCurrentUser()

  let {branchSlice, cfcSlice} = useSelector(({Reducers}) => Reducers)

  const [init, setInit] = useState({
    menuTabs: [],
    selectedTab: 0,
    isTabSelectedPending: true,
  })

  let {menuTabs, selectedTab, isTabSelectedPending} = init
  let selectedLocalStorageTab = localStorage.getItem('selectedTab')

  useEffect(() => {
    if (selectedLocalStorageTab !== undefined) {
      setInit({
        ...init,
        selectedTab: parseInt(selectedLocalStorageTab),
        isTabSelectedPending: false,
      })
    } else {
      setInit({
        ...init,
        selectedTab: 0,
        isTabSelectedPending: false,
      })
    }
  }, [selectedLocalStorageTab])

  // useEffect(() => {
  //   if (!isTabSelectedPending && tabs) {
  //     setInit({
  //       ...init,
  //       menuTabs: tabs,
  //     })
  //   }
  // }, [tabs, isTabSelectedPending])

  const [refreshData, setRefreshData] = useState(true)
  const [sambacareHistory, setSambacareHistory] = useState()

  const [showHold, setShowHold] = useState(false)
  const [showCardFromBranch, setShowCardFromBranch] = useState(true)

  let current_id = new URL(window.location).searchParams.get('id')

  let dispatch = useDispatch()

  let navigate = useNavigate()

  let CaptureBranchId = (id) => {
    dispatch(CaptureBranch(id))
  }

  const isoStr1 = new Date().toLocaleString()
  const date1 = new Date(isoStr1)
  let currentDate = convertISODate(date1)
  var checkSplit = currentDate.split('/').slice(2, 2)

  // console.log('=======' , checkSplit);
  // console.log(currentDate);
  // 01-10-2022

  const [pageNumber,setPageNumber]=useState(1);
  const [pageSize,setPageSize]=useState(30);

  const PageSize=(value)=>{
    setPageSize(value)
  }
  const PageNumber=(value)=>{
    setPageNumber(value)
  } 



  
  const [sambacareCount,setSambacareCount]=useState(0)

  useEffect(() => {
    API.get(`/Card/history/sambacare/tobehot?PageNumber=${pageNumber}&PageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setSambacareCount(res.data.result.totalRecords)
      setSambacareHistory(res.data.result.data.$values)
    })
  }, [pageSize,pageNumber])

  return (
    <>
      <div id='kt_app_content' className='app-content2 flex-column-fluid'>
        {/*<!--begin::Content container-->*/}
        <div id='kt_app_content_container' className='app-container container-xx'>
          {/* <div id="kt_app_toolbar" className=" py-3 py-lg-6">
					</div> */}
          {/*<!--begin::Navbar-->*/}
          <div className='card'>
            <div class='card-header border-0 pt-5'>
              <h3 class='card-title align-items-start flex-column'>
                <span class='card-label fw-bold fs-3 mb-1'>History</span>
              </h3>
              <div class='card-toolbar'>
                {/*<!--begin::Menu-->*/}
                <div class='d-flex align-items-center gap-2 gap-lg-3'>
                  {/*<!--begin::Secondary button-->*/}
                  {/*<!--end::Secondary button-->*/}
                  {/*<!--begin::Primary button-->*/}
                  <div class='d-flex align-items-center position-relative my-1'>
                    {/*<!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->*/}
                    <span class='svg-icon svg-icon-3 position-absolute ms-3'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        ></rect>
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                    </span>
                    {/*<!--end::Svg Icon-->*/}
                    <input
                      type='text'
                      id='kt_filter_search'
                      class='form-control form-control-solid form-select-sm w-150px ps-9'
                      placeholder='Search'
                    />
                  </div>
                  {/*<!--end::Primary button-->*/}
                </div>
              </div>
            </div>
            <div>
              <div className='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
                <div className='card'>
                  {/*<!--begin::Body-->*/}
                  <div className='card-body py-3 '>
                    {/*<!--begin::Table container-->*/}
                    <div className='d-flex'>
                      <div>
                        <FilterMenu />
                      </div>
                      <div>
                        <i
                          class='fa fa-refresh my-1'
                          style={{
                            color: 'rgb(24, 166, 137)',
                            fontSize: '24px',
                            cursor: 'pointer',
                            margin: '0px 1rem',
                          }}
                          aria-hidden='true'
                          onClick={() => setRefreshData(!refreshData)}
                        />
                      </div>
                    </div>

                    <div
                      className='table-responsive my-5'
                      style={{height: '500px', overflow: 'auto'}}
                    >
                      {/*<!--begin::Table-->*/}
                        {/*<!--begin::Head-->*/}
                        <TablesBranch  data={sambacareHistory} type='sambaPhoneHistory' PageSize={PageSize} PageNumber={PageNumber} totalRecords={sambacareCount} pageNumber={pageNumber} pageSize={pageSize}/>

                        {/*<!--end::Body-->*/}
                      {/*<!--end::Table-->*/}
                    </div>
                    {/*<!--end::Table container-->*/}
                  </div>
                  {/*<!--begin::Body-->*/}
                </div>
              </div>
            </div>
          </div>
          {/*<!--end::Navbar-->*/}
          {/*<!--begin::Row-->*/}
          {/*<!--Mytabcontent start-->*/}

          {/*<!--Mytabcontent end-->*/}
          {/*<!--end::Row-->*/}
          {/*<!--begin::Table-->*/}
        </div>
        {/*<!--end::Content container-->*/}
        {/*<!--end::Content-->*/}
      </div>
      {/* <CardActive cardId={cardId} show={showHold} close={() => setShowHold(false)} /> */}
    </>
  )
}

export default SambaCareHistory
