import React, {useState, useEffect} from 'react'
import {DataGrid, GridToolbar, GridToolbarExport} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {API} from '../../../utils/services'
import {Link} from 'react-router-dom'
import {STRINGS} from '../../../utils/Enums'
import Loader from '../Loader/Loader'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import moment from 'moment'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const BranchComplainTable = ({
  refreshData,
  data,
  PageSize = () => {},
  PageNumber = () => {},
  pageNumber,
  pageSize,
}) => {
  let user = GetCurrentUser()
  // const [pageSize, setPageSize] = React.useState(10);
  // const [data, setData] = useState()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(1)
  // const [pageNumber, setPageNumber] = useState(1)
  // const [pageSize, setPageSize] = useState(30)

  // const PageSize = (value) => {
  //   setPageSize(value)
  // }
  // const PageNumber = (value) => {
  //   setPageNumber(value)
  // }

  const [complaintCount, setComplaintCount] = useState(0)

  let navigate = useNavigate()
  const handleEditClick = (event, row, id) => {
    // alert('hello')
    event.preventDefault()

    navigate(`${STRINGS.ROUTES.BRANCH.VIEW_COMPLAINS}?id=${row.id}`)
  }

  console.log('data123', data)

  const columns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'complaintID', headerName: 'ComplainSerial', width: 100},
    {field: 'resolved', headerName: 'Resolved?', width: 100},
    {field: 'originator', headerName: 'Originator', width: 250},
    {field: 'addBy', headerName: 'Initiator', width: 250},
    {field: 'resolvedBy', headerName: 'Resolver', width: 250},
    {field: 'addDate', headerName: 'Compl Log Date', width: 250},
    {field: 'resolvedDate', headerName: 'Resolve Date', width: 250},
    {field: 'customerName', headerName: 'CIF/Customer Name', width: 250},
    {field: 'Address', headerName: 'Consignment no', width: 250},
    {field: 'customerCityName', headerName: 'City', width: 150},
    {field: 'cellNo', headerName: 'Complain ID', width: 250},
    {field: 'cardNo', headerName: 'Card Number', width: 250},
    {field: 'complaintBranch', headerName: 'Branch Name', width: 250},
    {field: 'complaintNote', headerName: 'CFC Note', width: 250},
    {field: 'resolutionRemarks', headerName: 'Branch Note', width: 250},
    {field: 'cnicNo', headerName: 'CNIC Number', width: 250},
    {field: 'sambaPhoneRemarks', headerName: 'Samba Phone/Care Note', width: 250},
    {field: 'complaintAging', headerName: 'Complaint Aging', width: 250},
    {field: 'resolveAging', headerName: 'Resolve Aging', width: 250},

    {
      field: 'action',
      headerName: 'Action',
      // width: 550,
      renderCell: (row) => (
        <Button
          //   className='btn btn-primary btn-sm'
          variant='contained'
          size='small'
          style={{backgroundColor: '#18A689'}}
          onClick={(e) => handleEditClick(e, row)}
          disabled={row.row.resolved === 'Yes'}
        >
          View
        </Button>
      ),
    },
  ]
  const complainData = []
  ;<GridToolbarExport
    printOptions={{
      hideFooter: true,
      hideToolbar: true,
    }}
  />

  useEffect(() => {
    API.get(`/Complaint?PageNumber=${pageNumber}&PageSize=${pageSize}`).then((res) => {
      setComplaintCount(res.data.data.totalRecords)
      // setData(res.data.data.data)
      setLoader(false)
    })
  }, [refreshData, pageSize, pageNumber])

  data?.forEach((element, index) => {
    complainData.push({
      id: element.id,
      SNo: index + 1 + (pageNumber - 1) * pageSize,
      complaintID: element.complaintID,
      resolved: element.resolved ? 'Yes' : 'No',
      customerName: element.customerName,
      Address: element.address,
      customerCityName: element.customerCityName,
      cellNo: element.cellNo,
      cardNo: element.cardNo,
      complaintBranch: element.complaintBranch,
      sambaPhoneRemarks: element.sambaPhoneRemarks,
      complaintNote: element.complaintNote,
      resolutionRemarks: element.resolutionRemarks,
      cnicNo: element.cnicNo,
      complaintAging:element.complaintAging,
      resolveAging:element.resolveAging,
      originator:
        element.originator === 1
          ? 'administrator'
          : element.originator === 2
          ? 'cfc'
          : element.originator === 3
          ? 'branch'
          : element.originator === 4
          ? 'sambaphone'
          : element.originator,
      addBy: element.addBy,
      resolvedBy: element.resolvedBy,
      addDate: moment(element.addDate).format('MMMM DD, YYYY, h:mm:ss '),
      resolvedDate: element.resolvedDate
        ? moment(element.resolvedDate).format('MMMM DD, YYYY, h:mm:ss ')
        : null,

    })
  })
  //     data && data.map((item,index)=>{
  //const handleBlur=()=>{
  //if (customerName===""){
  //alert('*');
  //}
  //}
  //    })
  return (
    <>
      <div style={{height: '60vh', width: '100%'}}>
        <DataGrid
          showCellRightBorder={true}
          rows={complainData}
          rowCount={complaintCount}
          columns={columns}
          // experimentalFeatures={{newEditingApi: true}}
          components={{Toolbar: GridToolbar}}
          // pageSize={pageSize}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 30]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          {...data}
        />
      </div>
    </>
  )
}

export default BranchComplainTable
