import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
// import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {convertISODate, convertISOTime, GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'
import {style} from '@mui/system'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import './style.css'
import {STRINGS} from '../../../utils/Enums'
import {RemainingDays} from '../../pages/CFC/Slices/CFC.slice'
import CardFromBranch from '../../pages/CFC/CFCScreen/forms/CardFromBranch'
import CardHold from '../../pages/CFC/CFCScreen/forms/CardHold'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import {Dropdown} from 'antd'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'
import PopupState, {bindToggle, bindPopper} from 'material-ui-popup-state'
import Fade from '@mui/material/Fade'
import CardActive from '../../pages/CFC/CFCScreen/forms/ActiveModal'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const TablesBranch = ({
  data,
  type,
  transitInType,
  PageSize,
  PageNumber,
  pageNumber,
  pageSize,
  totalRecords,
  tableType,
}) => {
  let current_id = new URL(window.location).searchParams.get('id')

  const [forceActions, setForceActions] = useState()
  const [cardId, setCardId] = useState({})
  const [showHold, setShowHold] = useState(false)
  const [showHold2, setShowHold2] = useState(false)
  const [showCardFromBranch, setShowCardFromBranch] = useState(true)
  const [decryptNumberPrint, setDecryptNumberPrint] = useState('')

  const decryptNumber = (id) => {
    API.get(`/Card/decryptNumber?id=${id}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setDecryptNumberPrint(res.data.data)
    })
  }

  let dispatch = useDispatch()
  //ROWS

  let user = GetCurrentUser()

  useEffect(() => {
    // console.log(`card details ${cardId}`)
  }, [cardId])
  // {params.row.cfc.includes("CFC")? "Card From CFC":"Card From Branch"}

  const ForceActionFunction = (activityId) => {
    // if(forceActions==null){
    tableType == 'cfc'
      ? API.post(
          `/ForceAction/getforceactions`,
          {
            activityId: activityId,
            forCFC: true,
          },
          {
            headers: {
              Authorization: `Bearer ${user.api_token}`,
            },
          }
        ).then((res) => {
          setForceActions(res.data.result.$values)
        })
      : API.post(
          `/ForceAction/getforceactions`,
          {
            activityId: activityId,
            forBranch: true,
          },
          {
            headers: {
              Authorization: `Bearer ${user.api_token}`,
            },
          }
        ).then((res) => {
          setForceActions(res.data.result.$values)
        })
    // }
  }
  //COLUMNS
  const deliveryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <td>
              <div className='btn-group'>
                <Link
                  to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                  type='button'
                  className='btn btn-light btn-sm'
                >
                  {params.row.action}
                </Link>
                {/* <PopupState variant='popper' popupId='demo-popup-popper'>
                  {(popupState) => (
                    <div onClick={() => ForceActionFunction(params.row.activityId)}>
                      <button
                        className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                        {...bindToggle(popupState)}
                      ></button>
                      <Popper {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper style={{padding: '5px'}}>
                              {forceActions &&
                                forceActions.map((nestedItem, index) => {
                                  return nestedItem.forceActivity.includes('Deliver') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Hold') ? (
                                    <button
                                      data-bs-toggle='modal'
                                      data-bs-target='#exampleModal'
                                      className='dropdown-item'
                                      onClick={() => {
                                        // dispatch(ResetState())
                                        setCardId(params.row)
                                        setShowHold(true)
                                      }}
                                    >
                                      {nestedItem.forceActivity}
                                    </button>
                                  ) : nestedItem.forceActivity.includes('CFC') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Branch') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Shred') ? (
                                    <Link
                                      onClick={() => dispatch(RemainingDays(params.row.aging))}
                                      to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : (
                                    <></>
                                  )
                                })}
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  )}
                </PopupState> */}
              </div>
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},
    {
      field: 'cardNumber',
      headerName: 'Card Number',
      width: 180,
      renderCell: (params) => {
        return (
          <p
            onMouseLeave={() => {
              setDecryptNumberPrint('')
            }}
            onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'Customer Name', width: 180},
    {field: 'cnic', headerName: 'CNIC', width: 180},
    {field: 'address1', headerName: 'Address 1', width: 180},
    {field: 'address2', headerName: 'Address 2', width: 180},
    {field: 'captureCardType', headerName: 'Card Type', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 250},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {field: 'cellNumber', headerName: 'Cell Number', width: 180},
    {field: 'accountNumber', headerName: 'Account Number', width: 180},
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]

  const shredColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
    {field: 'aging', headerName: 'Aging', width: 80},
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <td>
              <div className='btn-group'>
                <Link
                  to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                  type='button'
                  className='btn btn-light btn-sm'
                >
                  {params.row.action}
                </Link>

                {/* <PopupState variant='popper' popupId='demo-popup-popper'>
                  {(popupState) => (
                    <div onClick={() => ForceActionFunction(params.row.activityId)}>
                      <button
                        className='btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split'
                        {...bindToggle(popupState)}
                      ></button>
                      <Popper {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper style={{padding: '5px'}}>
                              {forceActions &&
                                forceActions.map((nestedItem, index) => {
                                  return nestedItem.forceActivity.includes('Deliver') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Hold') ? (
                                    <button
                                      data-bs-toggle='modal'
                                      data-bs-target='#exampleModal'
                                      className='dropdown-item'
                                      onClick={() => {
                                        // dispatch(ResetState())
                                        setCardId(params.row)
                                        setShowHold(true)
                                      }}
                                    >
                                      {nestedItem.forceActivity}
                                    </button>
                                  ) : nestedItem.forceActivity.includes('CFC') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.SENDTOCFCFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Branch') ? (
                                    <Link
                                      to={`${STRINGS.ROUTES.BRANCH.SENDTOBRANCHFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : nestedItem.forceActivity.includes('Shred') ? (
                                    <Link
                                      onClick={() => dispatch(RemainingDays(params.row.aging))}
                                      to={`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${params.row.id}`}
                                      className='dropdown-item'
                                    >
                                      {nestedItem.forceActivity}
                                    </Link>
                                  ) : (
                                    <></>
                                  )
                                })}
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  )}
                </PopupState> */}
              </div>
            </td>
          </>
        )
      },
    },
    {field: 'processStatus', headerName: 'Process Status', width: 180},
    {
      field: 'cardNumber',
      headerName: 'Card Number',
      width: 180,
      renderCell: (params) => {
        return (
          <p
            onMouseLeave={() => {
              setDecryptNumberPrint('')
            }}
            onMouseEnter={() => decryptNumber(params.row.id)}
            // class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'black',
              fontSize: '13px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={decryptNumberPrint}
            aria-hidden='true'
          >
            {params.row.cardNumber}{' '}
          </p>
        )
      },
    },
    {field: 'customerName', headerName: 'Customer Name', width: 180},
    {field: 'cnic', headerName: 'CNIC', width: 180},
    {field: 'address1', headerName: 'Address 1', width: 180},
    {field: 'address2', headerName: 'Address 2', width: 180},
    {field: 'captureCardType', headerName: 'Card Type', width: 180},
    {field: 'issuingBranch', headerName: 'Issuing Branch', width: 250},
    {field: 'cifNumber', headerName: 'CIF Number', width: 180},
    {field: 'cellNumber', headerName: 'Cell Number', width: 180},
    {field: 'accountNumber', headerName: 'Account Number', width: 180},
    {
      field: 'notes',
      headerName: 'Notes',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]

  const shredHistoryColumns = [
    // Day 1 cloumn
    {
      field: 'SNo',
      headerName: 'S.no',
      width: 50,
      border: '2px solid black',
      headerClassName: 'day1-header',
      disableExport: true,
    },
    {field: 'category', headerName: 'CARD TYPE', width: 150, headerClassName: 'day1-header'},
    {field: 'cifNumber', headerName: 'CIF/REF', width: 150, headerClassName: 'day1-header'},
    {field: 'requestDate', headerName: 'REQUEST DATE', width: 150, headerClassName: 'day1-header'},
    {
      field: 'dispatchDate',
      headerName: 'PRODUCTION DATE/DISPATCHED DATE',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'waivedOrRecovered',
      headerName: 'CHARGES WAIVED OR RECOVERED',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'accountStatusRemarks',
      headerName: 'ACCOUNT STATUS',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'accountNumber', headerName: 'A/C NUMBER', width: 150, headerClassName: 'day1-header'},
    {
      field: 'customerName',
      headerName: 'CUSTOMER NAMES',
      width: 500,
      headerClassName: 'day1-header',
    },
    {
      field: 'newOrReplacement',
      headerName: 'NEW/REPLACEMENT',
      width: 150,
      headerClassName: 'day1-header',
    },
    {
      field: 'requestChannel',
      headerName: 'REQUESTS CHANNEL',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'branch', headerName: 'BRANCH NAME', width: 400, headerClassName: 'day1-header'},
    {
      field: 'accountProduct',
      headerName: 'ACCOUNT PRODUCT',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'ccy', headerName: 'CCY', width: 50, headerClassName: 'day1-header'},
    {field: 'addressOne', headerName: 'ADDRESS-1', width: 500, headerClassName: 'day1-header'},
    {field: 'addressTwo', headerName: 'ADDRESS-2', width: 500, headerClassName: 'day1-header'},
    {
      field: 'cityAndCountry',
      headerName: 'CITY & COUNTRY',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'cellNo', headerName: 'CONTACT NO', width: 150, headerClassName: 'day1-header'},
    {
      field: 'sambaMDCPanNo',
      headerName: 'SAMBA MDC PAN #',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'channel', headerName: 'CHANNEL', width: 150, headerClassName: 'day1-header'},

    // Day 2 cloumn
    {
      field: 'consignmentNo',
      headerName: '1st CONSIGNMENT #',
      width: 150,
      headerClassName: 'day2-header',
    },

    // Day 3 cloumn
    {
      field: 'deliveryAging',
      headerName: 'Delivery/Return Aging',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'cnicNo',
      headerName: 'RECEIVER CNIC NUMBER',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'relation',
      headerName: 'RECEIVED BY (RELATION)',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'receiverName',
      headerName: 'RECEIVER NAME/REASON FOR RTN',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY/RTN DATE',
      width: 150,
      headerClassName: 'day3-header',
    },
    {
      field: 'status',
      headerName: '1st DELIVERY STATUS',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnCN',
      headerName: '2nd CONSIGNMENT #',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnBranchName',
      headerName: 'RETURN BRANCH NAME',
      width: 350,
      headerClassName: 'day3-header',
    },
    {
      field: 'address3',
      headerName: 'BRANCH ADDRESS',
      width: 250,
      renderCell: (params) => <div style={{whiteSpace: 'normal'}}>{params.value}</div>,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnDispatchDate',
      headerName: 'DISPATCH TO BRANCH DATE',
      width: 250,
      headerClassName: 'day3-header',
    },
    {field: 'quantity', headerName: 'PACKET QTY', width: 150, headerClassName: 'day3-header'},
    {
      field: 'receiverName2',
      headerName: 'BRANCH RECEIVER NAME',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate2',
      headerName: 'BRANCH DELIVERY DATE',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'status2',
      headerName: 'BRANCH DELIVERY STATUS',
      width: 200,
      headerClassName: 'day3-header',
    },
    {field: 'rsAging', headerName: 'RTN AGING', width: 80, headerClassName: 'day3-header'},

    // Other Columns
    {
      field: 'finalReturnedCardStatus',
      headerName: 'CONSIGNMENT FINAL STATUS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {
      field: 'deliveryOrShreddedDate',
      headerName: 'DELIVERY/SHREDDED DATE',
      width: 250,
      headerClassName: 'grey-header',
    },
    {field: 'region', headerName: 'REGION', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedRemarksOPS',
      headerName: 'CARD OPS REMARKS FOR RETURNED CARDS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'cardStatus', headerName: 'CARD STATUS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedBranchRemarks',
      headerName: 'BRANCH REMARKS FOR RETURNED CARDS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'remarks', headerName: 'REMARKS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'remarksShred',
      headerName: 'Remarks Shred',
      width: 150,
      headerClassName: 'grey-header',
    },
  ]

  // const shredHistoryColumns = [
  //   {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
  //   {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
  //   {field: 'aging', headerName: 'Aging', width: 80},
  //   {field: 'processStatus', headerName: 'Process Status', width: 180},
  //   {
  //     field: 'cardNumber',
  //     headerName: 'Card Number',
  //     width: 180,
  //     renderCell: (params) => {
  //       console.log(params)
  //       return (
  //         <p
  //           onMouseLeave={() => {
  //             console.log('1', decryptNumberPrint)
  //             setDecryptNumberPrint('')
  //           }}
  //           onMouseEnter={() => decryptNumber(params.row.id)}
  //           // class='fa fa-light fa-circle-info my-1'
  //           style={{
  //             color: 'black',
  //             fontSize: '13px',
  //             cursor: 'pointer',
  //             margin: '0px 1rem',
  //           }}
  //           data-toggle='tooltip'
  //           data-bs-placement='top'
  //           title={decryptNumberPrint}
  //           aria-hidden='true'
  //         >
  //           {params.row.cardNumber}{' '}
  //         </p>
  //       )
  //     },
  //   },
  //   {field: 'customerName', headerName: 'Customer Name', width: 180},
  //   {field: 'cnic', headerName: 'CNIC', width: 180},
  //   {field: 'address1', headerName: 'Address 1', width: 180},
  //   {field: 'address2', headerName: 'Address 2', width: 180},
  //   {field: 'captureCardType', headerName: 'Card Type', width: 180},
  //   {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
  //   {field: 'cifNumber', headerName: 'CIF Number', width: 180},
  //   {field: 'cellNumber', headerName: 'Cell Number', width: 180},
  //   {field: 'accountNumber', headerName: 'Account Number', width: 180},
  //   {
  //     field: 'notes',
  //     headerName: 'Notes',
  //     width: 100,
  //     renderCell: (params) => {
  //       const notes = params.value
  //       return (
  //         <i
  //           class='fa fa-light fa-circle-info my-1'
  //           style={{
  //             color: 'white',
  //             fontSize: '24px',
  //             cursor: 'pointer',
  //             margin: '0px 1rem',
  //           }}
  //           data-toggle='tooltip'
  //           data-bs-placement='top'
  //           title={notes}
  //           aria-hidden='true'
  //         />
  //       )
  //     },
  //   },
  // ]
  // const deliveryHistoryColumns = [
  //   {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
  //   {field: 'remainingDays', headerName: 'Remaining Days', width: 80},
  //   {field: 'aging', headerName: 'Aging', width: 80},
  //   {field: 'processStatus', headerName: 'Process Status', width: 180},
  //   {
  //     field: 'cardNumber',
  //     headerName: 'Card Number',
  //     width: 180,
  //     renderCell: (params) => {
  //       console.log(params)
  //       return (
  //         <p
  //           onMouseLeave={() => {
  //             console.log('1', decryptNumberPrint)
  //             setDecryptNumberPrint('')
  //           }}
  //           onMouseEnter={() => decryptNumber(params.row.id)}
  //           // class='fa fa-light fa-circle-info my-1'
  //           style={{
  //             color: 'black',
  //             fontSize: '13px',
  //             cursor: 'pointer',
  //             margin: '0px 1rem',
  //           }}
  //           data-toggle='tooltip'
  //           data-bs-placement='top'
  //           title={decryptNumberPrint}
  //           aria-hidden='true'
  //         >
  //           {params.row.cardNumber}{' '}
  //         </p>
  //       )
  //     },
  //   },
  //   {field: 'customerName', headerName: 'Customer Name', width: 180},
  //   {field: 'cnic', headerName: 'CNIC', width: 180},
  //   {field: 'address1', headerName: 'Address 1', width: 180},
  //   {field: 'address2', headerName: 'Address 2', width: 180},
  //   {field: 'captureCardType', headerName: 'Card Type', width: 180},
  //   {field: 'issuingBranch', headerName: 'Issuing Branch', width: 180},
  //   {field: 'cifNumber', headerName: 'CIF Number', width: 180},
  //   {field: 'cellNumber', headerName: 'Cell Number', width: 180},
  //   {field: 'accountNumber', headerName: 'Account Number', width: 180},
  //   {
  //     field: 'notes',
  //     headerName: 'Notes',
  //     width: 100,
  //     renderCell: (params) => {
  //       const notes = params.value
  //       return (
  //         <i
  //           class='fa fa-light fa-circle-info my-1'
  //           style={{
  //             color: 'white',
  //             fontSize: '24px',
  //             cursor: 'pointer',
  //             margin: '0px 1rem',
  //           }}
  //           data-toggle='tooltip'
  //           data-bs-placement='top'
  //           title={notes}
  //           aria-hidden='true'
  //         />
  //       )
  //     },
  //   },
  // ]

  const deliveryHistoryColumns = [
    // Day 1 cloumn
    {
      field: 'SNo',
      headerName: 'S.no',
      width: 50,
      border: '2px solid black',
      headerClassName: 'day1-header',
      disableExport: true,
    },
    {field: 'category', headerName: 'CARD TYPE', width: 150, headerClassName: 'day1-header'},
    {field: 'cifNumber', headerName: 'CIF/REF', width: 150, headerClassName: 'day1-header'},
    {field: 'requestDate', headerName: 'REQUEST DATE', width: 150, headerClassName: 'day1-header'},
    {
      field: 'dispatchDate',
      headerName: 'PRODUCTION DATE/DISPATCHED DATE',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'waivedOrRecovered',
      headerName: 'CHARGES WAIVED OR RECOVERED',
      width: 250,
      headerClassName: 'day1-header',
    },
    {
      field: 'accountStatusRemarks',
      headerName: 'ACCOUNT STATUS',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'accountNumber', headerName: 'A/C NUMBER', width: 150, headerClassName: 'day1-header'},
    {
      field: 'customerName',
      headerName: 'CUSTOMER NAMES',
      width: 500,
      headerClassName: 'day1-header',
    },
    {
      field: 'newOrReplacement',
      headerName: 'NEW/REPLACEMENT',
      width: 150,
      headerClassName: 'day1-header',
    },
    {
      field: 'requestChannel',
      headerName: 'REQUESTS CHANNEL',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'branch', headerName: 'BRANCH NAME', width: 400, headerClassName: 'day1-header'},
    {
      field: 'accountProduct',
      headerName: 'ACCOUNT PRODUCT',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'ccy', headerName: 'CCY', width: 50, headerClassName: 'day1-header'},
    {field: 'addressOne', headerName: 'ADDRESS-1', width: 500, headerClassName: 'day1-header'},
    {field: 'addressTwo', headerName: 'ADDRESS-2', width: 500, headerClassName: 'day1-header'},
    {
      field: 'cityAndCountry',
      headerName: 'CITY & COUNTRY',
      width: 500,
      headerClassName: 'day1-header',
    },
    {field: 'cellNo', headerName: 'CONTACT NO', width: 150, headerClassName: 'day1-header'},
    {
      field: 'sambaMDCPanNo',
      headerName: 'SAMBA MDC PAN #',
      width: 150,
      headerClassName: 'day1-header',
    },
    {field: 'channel', headerName: 'CHANNEL', width: 150, headerClassName: 'day1-header'},

    // Day 2 cloumn
    {
      field: 'consignmentNo',
      headerName: '1st CONSIGNMENT #',
      width: 150,
      headerClassName: 'day2-header',
    },

    // Day 3 cloumn
    {
      field: 'deliveryAging',
      headerName: 'DELIVERY/RETURN AGING',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'cnicNo',
      headerName: 'RECEIVER CNIC NUMBER',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'relation',
      headerName: 'RECEIVED BY (RELATION)',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'receiverName',
      headerName: 'RECEIVER NAME/REASON FOR RTN',
      width: 250,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERED/RTN DATE',
      width: 150,
      headerClassName: 'day3-header',
    },
    {
      field: 'status',
      headerName: '1st DELIVERY STATUS',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnCN',
      headerName: '2nd CONSIGNMENT #',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnBranchName',
      headerName: 'RETURN BRANCH NAME',
      width: 350,
      headerClassName: 'day3-header',
    },
    {
      field: 'address3',
      headerName: 'BRANCH ADDRESS',
      width: 250,
      renderCell: (params) => <div style={{whiteSpace: 'normal'}}>{params.value}</div>,
      headerClassName: 'day3-header',
    },
    {
      field: 'returnDispatchDate',
      headerName: 'DISPATCH TO BRANCH DATE',
      width: 250,
      headerClassName: 'day3-header',
    },
    {field: 'quantity', headerName: 'PACKET QTY', width: 150, headerClassName: 'day3-header'},
    {
      field: 'receiverName2',
      headerName: 'BRANCH RECEIVER NAME',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'deliveryDate2',
      headerName: 'BRANCH DELIVERY DATE',
      width: 200,
      headerClassName: 'day3-header',
    },
    {
      field: 'status2',
      headerName: 'BRANCH DELIVERY STATUS',
      width: 200,
      headerClassName: 'day3-header',
    },
    {field: 'rsAging', headerName: 'RTN AGING', width: 80, headerClassName: 'day3-header'},

    // Other Columns
    {
      field: 'finalReturnedCardStatus',
      headerName: 'CONSIGNMENT FINAL STATUS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {
      field: 'deliveryOrShreddedDate',
      headerName: 'SHREDDED/DELIVERY DATE',
      width: 250,
      headerClassName: 'grey-header',
    },
    {field: 'region', headerName: 'REGION', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedRemarksOPS',
      headerName: 'CARD OPS REMARKS FOR RETURNED CARDS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'cardStatus', headerName: 'CARD STATUS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'returnedBranchRemarks',
      headerName: 'BRANCH REMARKS FOR RETURNED CARDS',
      width: 300,
      headerClassName: 'grey-header',
    },
    {field: 'remarks', headerName: 'REMARKS', width: 150, headerClassName: 'grey-header'},
    {
      field: 'remarksDeliver',
      headerName: 'REMARKS DELIVER',
      width: 150,
      headerClassName: 'grey-header',
    },
  ]
  const sambaPhoneHistoryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'remainingDays', headerName: 'REMAINING DAYS', width: 80},
    {field: 'aging', headerName: 'AGING', width: 80},
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 220,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <td>
    //           <div>
    //             <button
    //               data-bs-toggle='modal'
    //               data-bs-target='#exampleModal'
    //               className='btn btn-sm'
    //               style={{backgroundColor: 'white'}}
    //               onClick={() => {
    //                 setCardId(params.row)
    //                 setShowHold(true)
    //               }}
    //             >
    //               Active
    //             </button>
    //           </div>
    //         </td>
    //       </>
    //     )
    //   },
    // },
    {
      field: 'captureCardStatus',
      headerName: 'CARD STATUS',
      width: 150,
      renderCell: (params) => {
        const status = params.value
        let badgeClass

        if (status === 'Warm') {
          badgeClass = 'badge-warning'
        } else if (status !== 'Active') {
          badgeClass = 'badge-danger'
        } else {
          badgeClass = 'badge-success'
        }

        return (
          <td>
            <span className={`badge ${badgeClass}`}>{status}</span>
          </td>
        )
      },
    },
    {
      field: 'destructionReason',
      headerName: 'DESTRUCTION REASON',
      width: 180,
      renderCell: (params) => {
        const status = params.value
        return (
          <td>
            {`${status == 'Hot' ? 'Card Hot' : status == 'Active' ? 'Customer not Recieved' : ''}`}
          </td>
        )
      },
    },
    {field: 'cardExpiry', headerName: 'EXPIRY', width: 180},
    {field: 'cardNumber', headerName: 'CARD NUMBER', width: 180},
    {field: 'customerName', headerName: 'CUSTOMER NAME', width: 180},
    {field: 'acquirerBank', headerName: 'ACQUIRER BANK', width: 180},
    {field: 'acquirerBranch', headerName: 'CAPTURED BRANCH', width: 180},
    {field: 'issuingBank', headerName: 'ISSUING BANK', width: 180},
    {field: 'issuingBranch', headerName: 'ISSUING BRANCH', width: 180},
    {field: 'captureDate', headerName: 'CAPTURED DATE', width: 180},
    {field: 'captureTime', headerName: 'CAPTURED TIME', width: 180},
    {field: 'reasonForCaptured', headerName: 'REASON FOR CAPTURED', width: 180},
    {field: 'captureCardChannel', headerName: 'PAYMENT TYPE', width: 180},
    {field: 'cifNumber', headerName: 'CIF NUMBER', width: 180},
    {
      field: 'notes',
      headerName: 'NOTES',
      width: 100,
      renderCell: (params) => {
        const notes = params.value
        return (
          <i
            class='fa fa-light fa-circle-info my-1'
            style={{
              color: 'white',
              fontSize: '24px',
              cursor: 'pointer',
              margin: '0px 1rem',
            }}
            data-toggle='tooltip'
            data-bs-placement='top'
            title={notes}
            aria-hidden='true'
          />
        )
      },
    },
  ]
  //DATA TYPE
  let tableRows = []

  if (type) {
    data?.forEach((element, index) => {
      tableRows.push({
        id: element.id,
        SNo: index + 1 + (pageNumber - 1) * pageSize,
        cifNumber: element.cifNumber,
        consignmentNo: element.consignmentNo,
        customerName: element.customerName,
        address1: element.address1,
        address2: element.address2,
        cityName: element.cityName,
        cellNo: element.cellNo,
        cardNo: element.cardNo,
        branch: element.branch,
        notes: element.notes,
        sendCompanyName: element.sendCompanyName,
        bkgDate: `${element.bkgDate ? convertISODate(element.bkgDate) : ''}`,
        cnicNo: element.cnicNo,
        receiverName: element.receiverName,
        status: element.status,
        remarks: element.remarks,
        branch: element.branch,
        bkgDate2: `${element.bkgDate2 ? convertISODate(element.bkgDate2) : ''}`,
        receiverName2: element.receiverName2,
        status2: element.status2,
        quantity: element.quantity ? element.quantity : '',
        relation: element.relation,
        consignmentNo2: element.consignmentNo2,
        branch2: element.branch2,
        address3: element.address3,
        deliveryDate: `${element.deliveryDate ? convertISODate(element.deliveryDate) : ''}`,
        deliveryDate2: `${element.deliveryDate2 ? convertISODate(element.deliveryDate2) : ''}`,
        requestDate: `${element.requestDate ? convertISODate(element.requestDate) : ''}`,
        dispatchDate: `${element.dispatchDate ? convertISODate(element.dispatchDate) : ''}`,
        waivedOrRecovered: element.waivedOrRecovered,
        accountStatus: element.accountStatus,
        accountNumber: element.accountNumber,
        newOrReplacement: element.newOrReplacement,
        requestChannel: element.requestChannel,
        channel: element.channel,
        branch: element.branch,
        accountProduct: element.accountProduct,
        ccy: element.ccy,
        addressOne: element.addressOne,
        addressTwo: element.addressTwo,
        category: element.category,
        cityAndCountry: element.cityAndCountry,
        sambaMDCPanNo: element.sambaMDCPanNo,
        cnicNo: element.cnicNo ? element.cnicNo : '',
        returnCN: element.returnCN,
        returnBranchName: element.returnBranchName,
        returnDispatchDate: `${
          element.returnDispatchDate ? convertISODate(element.returnDispatchDate) : ''
        }`,
        finalReturnedCardStatus:
          element.finalReturnedCardStatus ||
          (element.dispatchDate && element.status === null
            ? 'Delivered to Courier'
            : element.dispatchDate && element.status && element.deliveryDate2
            ? 'To be received by branch'
            : element.dispatchDate && element.status === 'RS'
            ? 'Consignment Return'
            : element.dispatchDate && element.status === 'OK'
            ? 'Consignment Delivered'
            : ''),
        // finalReturnedCardStatus: element.finalReturnedCardStatus,
        deliveryOrShreddedDate: `${
          element.deliveryOrShreddedDate ? convertISODate(element.deliveryOrShreddedDate) : ''
        }`,
        region: element.region,
        cardStatus: element.cardStatus,
        branchRemarks: element.branchRemarks,
        rsAging: element.rsAging,
        accountStatusRemarks: element.accountStatusRemarks,
        returnedRemarksOPS: element.returnedRemarksOPS,
        returnedBranchRemarks: element.returnedBranchRemarks,
        deliveryAging: element.deliveryAging,
        isDelivered: element.isDelivered,
        isShredded: element.isShredded,
        remarksShred: element.remarksShred,
        remarksDeliver: element.remarksDeliver,
      })
    })
  }

  return (
    <div style={{height: '70vh', width: '100%'}}>
      {type == 'shred' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[30, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={shredColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'shredHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[30, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={shredHistoryColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'deliveryHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[30, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'yellow-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={deliveryHistoryColumns}
          showCellRightBorder={true}
        />
      )}
      {type == 'sambaPhoneHistory' && (
        <DataGrid
          // autoHeight
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[30, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'red-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={sambaPhoneHistoryColumns}
          showCellRightBorder={true}
        />
      )}

      {type == 'delivery' && (
        <DataGrid
          rows={tableRows}
          rowCount={totalRecords}
          // loading={pageState.isLoading}
          rowsPerPageOptions={[30, 50, 70, 100]}
          pagination
          page={pageNumber - 1}
          pageSize={pageSize}
          paginationMode='server'
          onPageChange={(newPage) => {
            PageNumber(newPage + 1)
          }}
          onPageSizeChange={(newPageSize) => {
            PageSize(newPageSize)
          }}
          getRowClassName={(params) => 'yellow-row'}
          cellClassName='dark-text'
          headerClassName='dark-text'
          columns={deliveryColumns}
          showCellRightBorder={true}
        />
      )}

      <CardHold
        token={user.api_token}
        cardId={cardId}
        show={showHold}
        close={() => setShowHold(false)}
      />

      <CardActive
        token={user.api_token}
        cardId={cardId}
        show={showHold2}
        close={() => setShowHold2(false)}
      />

      <CardFromBranch
        type={tableType ? tableType : 'branch'}
        cardId={cardId}
        branchId={current_id}
        // Refresh={()=>Refresh(refreshData)}
        show
        {...showCardFromBranch}
        close={() => setShowCardFromBranch(false)}
      />
    </div>
  )
}

export default TablesBranch
