import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {API} from '../../../../../utils/services'
import {useNavigate} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {STRINGS} from '../../../../../utils/Enums'
import {GetCurrentUser} from '../../../../../utils/DefaultFunctions'

const BranchAddCard = () => {
  let user = GetCurrentUser()
  let navigate = useNavigate()
  let currentBranchId = sessionStorage.getItem('branchId')
  const {cfcSlice} = useSelector(({Reducers}) => Reducers)

  const [cfcId, setCfcId] = useState()
  const [customerName, setCustomerName] = useState()
  const [cardNumber, setCardNumber] = useState()
  const [cnic, setCnic] = useState()
  const [cif, setCif] = useState()
  const [accountNumber, setAccountNumber] = useState()
  const [note, setNote] = useState()
  const [address, setAddress] = useState()
  const [address2, setAddress2] = useState()
  const [cellNo, setCellNo] = useState()
  const [allBranches, setAllBranches] = useState()
  const [branchId, setBranchId] = useState()
  const [cardType, setCardType] = useState()
  const [cardTypeId, setCardTypeId] = useState()

  // toast functions
  const notifySuccess = () =>
    toast.success('Card Added Successfuly!', {
      position: 'bottom-right',
      theme: 'colored',
    })

  const notifyError = () =>
    toast.error('Something Went Wrong!', {
      position: 'bottom-right',
      theme: 'colored',
    })
  // end toast functions

  let body = {
    pageNumber: 1,
    pageSize: 100,
  }

  useEffect(() => {
    API.post('/Branch/Search', body, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setAllBranches(res.data.data.data)
    })

    API.get(`/Card/cardtypes`).then((res) => {
      setCardType(res.data.data)
    })
  }, [])

  const addCFC = () => {
    API.post(`/Card/create`, {
      capturedDate: '2023-04-02T07:24:08.966Z',
      capturedBranchId: currentBranchId,
      customerName: customerName,
      cardNumber: cardNumber,
      cnic: cnic,
      cardExpiry: '',
      cifNumber: cif,
      cardTypeId: cardTypeId,
      cardChannelId: '',
      nativeBranchId: branchId,
      address1: address,
      address2: address2,
      cellNumber: cellNo,
      accountNumber: accountNumber,
      receivingDate: '2023-04-02T07:24:08.966Z',
      notes: note,
    })
      .then((res) => {
        notifySuccess()
        setTimeout(() => {
          navigate(`${STRINGS.ROUTES.BRANCH.MAIN}?id=${currentBranchId}`)
        }, 1000)
      })
      .catch((e) => {
        notifyError()
      })
  }
  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/* <!--begin::Content container-->  */}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/* <!--begin::Row-->             */}
          {/* <!--Mytabcontent start--> */}
          <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
            {/* <!--Mytabcontent start tab1--> */}
            <div class='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {/* <!--begin::Col--> */}
              <div class='card mb-5 mb-xl-10'>
                {/* <!--begin::Card header--> */}
                <div
                  class='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  {/* <!--begin::Card title--> */}
                  <div class='card-title m-0'>
                    <h3 class='fw-bold m-0'>Add Card Form</h3>
                  </div>

                  {/* <!--end::Card title--> */}
                </div>
                <hr />
                {/* <!--begin::Card header--> */}
                {/* <!--begin::Content--> */}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/* <!--begin::Form--> */}
                  <form class='' style={{backgroundColor: 'white', padding: '50px'}}>
                    <div class='mb-' style={{display: 'inline-flex', width: '100%'}}>
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Customer Name</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Abdul Wasay'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => setCustomerName(e.target.value)}
                          value={customerName}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Card Number</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='11133355556666'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={20}
                          minLength={16}
                          type='text'
                          value={cardNumber}
                          onChange={(e) => setCardNumber(e.target.value)}
                        />
                      </div>

                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Card Type</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => setCardTypeId(e.target.value)}
                          style={{border: '1px solid darkgrey'}}
                          // value={allBranches}
                          data-placeholder='Select Designation'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select CardType
                          </option>
                          {cardType &&
                            cardType.map((item) => {
                              return <option value={item.id}>{item.description}</option>
                            })}
                        </select>
                      </div>

                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Account Number</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='0009991188811'
                          type='text'
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </div>

                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CIF</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='123456'
                          type='text'
                          maxLength={6}
                          value={cif}
                          onChange={(e) => setCif(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      {/* <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>City</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => setCityId(e.target.value)}
                          style={{border: '1px solid darkgrey'}}
                          // value={allBranches}
                          data-placeholder='Select Designation'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select City
                          </option>
                          {allCities &&
                            allCities.map((item) => {
                              return (
                                <option value={item.id}>{item.city}</option>
                              )
                            })}
                        </select>
                      </div> */}
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Cell Number</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='03218976543'
                          type='text'
                          onChange={(e) => setCellNo(e.target.value)}
                          value={cellNo}
                        />
                      </div>
                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>CNIC</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='4210115678952'
                          maxLength={13}
                          type='text'
                          value={cnic}
                          onChange={(e) => setCnic(e.target.value)}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div className='me-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Address 1</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='D-103 Model Town Lahore...'
                          type='text'
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </div>

                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Address 2</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='D-103 Model Town Lahore...'
                          type='text'
                          onChange={(e) => setAddress2(e.target.value)}
                          value={address2}
                        />
                      </div>
                      <div style={{width: '500%', paddingLeft: '20px'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => setBranchId(e.target.value)}
                          style={{border: '1px solid darkgrey'}}
                          // value={allBranches}
                          data-placeholder='Select Designation'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select Branch
                          </option>
                          {allBranches &&
                            allBranches.map((item) => {
                              return <option value={item.id}>{item.branchName}</option>
                            })}
                        </select>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Note</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type='button'
                      style={{float: 'right'}}
                      class='mb-5 my-5 btn btn-primary requestformitem'
                      onClick={(e) => {
                        addCFC()
                      }}
                    >
                      Submit
                    </button>
                  </form>
                  {/* <!--end::Form--> */}
                </div>
                {/* <!--end::Content--> */}
              </div>
            </div>
          </div>
          {/* <!--Mytabcontent end--> */}
          {/* <!--end::Row--> */}
          {/* <!--begin::Table--> */}
        </div>
        {/* <!--end::Content container--> */}
      </div>
      <ToastContainer />
    </>
  )
}

export default BranchAddCard
