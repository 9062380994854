import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const BranchTable = ({data, type, getDataById}) => {
  // const [data, setData] = useState()

  const handleClick = (event, cellValues) => {
    console.log(cellValues)
  }

  const banks = []
  const organization = []
  const branches = []
  const provinces = []
  const regions = []

  let user = GetCurrentUser()

  const bankColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'bank', headerName: 'Bank', width: 150},
  ]

  const OrganizationColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'organization', headerName: 'Organization Name', width: 100},
  ]
  const branchColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'bank', headerName: 'Bank Name', width: 150},
    {field: 'branch', headerName: 'Branch Name', width: 180},
    {field: 'managerName', headerName: 'Manager Name', width: 180},
    {field: 'city', headerName: 'City', width: 100},
    {field: 'branchCode', headerName: 'Branch Code', width: 100},
    {field: 'branchAccountNumber', headerName: 'Branch Account Number', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id, 'branch')
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
  ]

  if (type == 'bank') {
    data?.forEach((element, index) => {
      banks.push({
        id: element.id,
        SNo: index + 1,
        bank: element.bank,
      })
    })
  }

  if (type == 'organization') {
    data?.forEach((element, index) => {
      organization.push({
        id: element.id,
        SNo: index + 1,
        organization: element.organization,
      })
    })
  }

  if (type == 'branch') {
    data?.forEach((element, index) => {
      branches.push({
        id: element.id,
        SNo: index + 1,
        bank: element.bank,
        branch: element.branchName,
        managerName: element.managerName,
        city: element.city,
        branchCode: element.branchCode,
        branchAccountNumber: element.branchAccountNumber,
      })
    })
  }

  return (
    <div style={{height: 420, width: '100%'}}>
      {type == 'bank' && <DataGrid rows={banks} columns={bankColumns} showCellRightBorder={true} />}
      {type == 'branch' && (
        <DataGrid rows={branches} columns={branchColumns} showCellRightBorder={true} />
      )}
      {type == 'organization' && (
        <DataGrid rows={organization} columns={OrganizationColumns} showCellRightBorder={true} />
      )}
      {/* 
      {type == 'province' && (
        <DataGrid rows={provinces} columns={provinceColumns} showCellRightBorder={true} />
      )}

      {type == 'region' && (
        <DataGrid rows={regions} columns={regionColumns} showCellRightBorder={true} />
      )} */}
    </div>
  )
}

export default BranchTable
