/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {STRINGS} from '../../../../utils/Enums'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
// import branchSlice from '../../../../app/pages/BranchMain/BranchSlice/branch.slice'
import {useAppSelector} from '../../../../utils/hook'

type Slices = {branchSlice: any}

export function AsideMenuMain() {
  const intl = useIntl()
  const auth = GetCurrentUser()

  const {branchSlice} = useAppSelector(({Reducers}) => Reducers as Slices)
  let current_id = new URL(window.location as any).searchParams.get('id')
  // useEffect((/
  let branchId = sessionStorage.getItem('branchId')
  const selectedId = current_id ? current_id : branchId

  // let idBranch = sessionStorage.setItem('branchId', current_id as any)

  return (
    <>
      {auth.type == 'cfc' ? (
        <AsideMenuItem
          to={`/dashboard`}
          icon='/media/icons/duotune/general/gen025.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : auth.type == 'branch' ? (
        <AsideMenuItem
          to={`/dashboard?id=${branchId}`}
          icon='/media/icons/duotune/general/gen025.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      ) : (
        <></>
      )}

      {/*<AsideMenuItem*/}
      {/*  to='/builder'*/}
      {/*  icon='/media/icons/duotune/general/gen019.svg'*/}
      {/*  title='Layout Builder'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {auth.type !== 'administrator' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Process</span>
          </div>
        </div>
      )}
      {auth.type === 'cfc' && (
        <>
          <AsideMenuItem to={`${STRINGS.ROUTES.CFC.VIEW_MIS}`} title='MIS' hasBullet={true} />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.SAMBACARE.COMPLAINS}`}
            title='Complains'
            hasBullet={true}
          />
          {/* <AsideMenuItem to={`${STRINGS.ROUTES.CFC_SCREEN}`} title='Main' hasBullet={true} />       
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.COMPLAINS}`}
            title='Complains'
            hasBullet={true}
          /> */}
          {/* <AsideMenuItem to={`${STRINGS.ROUTES.BRANCH_SCREEN}`} title='Branch' hasBullet={true} /> */}
          <AsideMenuItem to={`${STRINGS.ROUTES.HISTORY_SCREEN}`} title='History' hasBullet={true} />
        </>
      )}

      {auth.type === 'branch' && (
        <>
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.INVENTORY}?id=${branchId}`}
            title='Inventory'
            hasBullet={true}
          /> */}
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.VIEW_MIS}?id=${selectedId}`}
            title='MIS'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.SAMBACARE.COMPLAINS}`}
            title='Complains'
            hasBullet={true}
          />
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.COMPLAINS}?id=${selectedId}`}
            title='Complains'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.MAIN}?id=${selectedId}`}
            title='Main Screen'
            hasBullet={true}
          />
          */}
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.HISTORY}?id=${selectedId}`}
            title='History'
            hasBullet={true}
          />

          {/* <AsideMenuItem to='' title='User Tasks' hasBullet={true} /> */}

          {/* <AsideMenuItem to='' title='Branches' hasBullet={true} /> */}
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.HISTORY}?id=${branchId}`}
            title='History'
            hasBullet={true}
          /> */}
        </>
      )}

      {auth.type === 'branch checker' && (
        <>
          {/* <AsideMenuItem
            to={`${STRINGS.ROUTES.MANAGEMENT.DASHBOARD}`}
            title='Dashboard'
            hasBullet={true}
          /> */}
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.MAIN_SCREEN}`}
            title='Main Screen'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.FORCE_TASK}`}
            title='Force Task'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.CHECKER.HISTORY}`}
            title='History'
            hasBullet={true}
          />
        </>
      )}

      {auth.type === 'management' && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.MANAGEMENT.VIEW_MIS}`}
            title='MIS'
            hasBullet={true}
          />
          <AsideMenuItem to={`${STRINGS.ROUTES.HISTORY_SCREEN}`} title='History' hasBullet={true} />
        </>
      )}

      {['samba phone', 'amd'].includes(auth.type) && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.SAMBACARE.COMPLAINS}`}
            title='Complains'
            hasBullet={true}
          />
          <AsideMenuItem to={`${STRINGS.ROUTES.SAMBACARE.VIEW_MIS}`} title='MIS' hasBullet={true} />
          {/* <AsideMenuItem to={`${STRINGS.ROUTES.SAMBACARE.MAIN}`} title='Main ' hasBullet={true} />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.SAMBACARE.SAMBA_CARE_HISTORY}`}
            title='History '
            hasBullet={true}
          /> */}
        </>
      )}

      {/* {auth.type === 'cfc' && (
        <AsideMenuItem to={`${STRINGS.ROUTES.CARD_EMBOSE}`} title='Card Embose' hasBullet={true} />
      )} */}
      {/* {auth.type !== 'administrator' && 

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CARDS</span>
        </div>
      </div>
      }

      {['branch', 'cfc'].includes(auth.type) && (
        <>
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.REQUEST_FORM}`}
            title='Request Form'
            hasBullet={true}
          />
          <AsideMenuItem
            to={`${STRINGS.ROUTES.BRANCH.MAIN_CARDS}`}
            title='Main Screen'
            hasBullet={true}
          />
        </>
      )} */}

      {auth.type === 'branch' ||
        (auth.type === 'administrator' && (
          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
              </div>
            </div>
            <AsideMenuItemWithSub
              to={`${STRINGS.ROUTES.SETTINGS.CONFIG}`}
              title='Configuration'
              fontIcon='bi-chat-left'
              icon='/media/icons/duotune/communication/com012.svg'
            >
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.ON_APPROVAL}`}
                title='On Approval'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_COUNTRY}`}
                title='Configure Country'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_PROVINCE}`}
                title='Configure Province'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_REGION}`}
                title='Configure Region'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CITY}`}
                title='Configure City'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CARD_TYPE}`}
                title='Configure Card Type'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_NEW_REPLACEMENT}`}
                title='Configure New/Replacement'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_REQUEST_CHANNEL}`}
                title='Configure Request Channels'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_CARD_RETURNED}`}
                title='Configure Returned Card Branch Status'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_DEPARTMENT}`}
                title='Department Config'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_DESIGNATION}`}
                title='Designation Config'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
                title='User Config'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH}`}
                title='Branch Config'
                hasBullet={true}
              />
              {/* <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_REGION}`}
                title='Configure Region'
                hasBullet={true}
              /> */}
            </AsideMenuItemWithSub>

            {/* <AsideMenuItemWithSub
              to={`${STRINGS.ROUTES.SETTINGS.CONFIG}`}
              title='Configure'
              fontIcon='bi-chat-left'
              icon='/media/icons/duotune/communication/com012.svg'
            > */}
            {/* <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_INFO}`}
                title='Configure Country Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_USER_INFO}`}
                title='Configure User Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_BRANCH_INFO}`}
                title='Configure Bank/Branch Info'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CONFIGURE_ORGANIZATION_INFO}`}
                title='Configure Organization Info'
                hasBullet={true}
              /> */}
            {/* <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CREATE_ROLES}`}
                title='Create Roles'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.ADMINISTRATOR.CARDS_TIMELINE}`}
                title='Cards Timeline'
                hasBullet={true}
              />
              <AsideMenuItem
                to={`${STRINGS.ROUTES.SETTINGS.CONFIG.ALERT_AND_ESCALATIONS.CREATE_ALERT_DESINATION}`}
                title='Create alert & designation'
                hasBullet={true}
              /> */}

            {/* </AsideMenuItemWithSub> */}
          </>
        ))}

      {/*<AsideMenuItem*/}
      {/*  to='/apps/user-management/users'*/}
      {/*  icon='/media/icons/duotune/general/gen051.svg'*/}
      {/*  title='User management'*/}
      {/*  fontIcon='bi-layers'*/}
      {/*/>*/}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content'>*/}
      {/*    <div className='separator mx-1 my-4'></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )
}
