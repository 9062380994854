import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {API} from '../../../utils/services'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useNavigate} from 'react-router-dom'
import {STRINGS} from '../../../utils/Enums'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import dayjs from 'dayjs'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import './index.css'
import {GetCurrentUser, convertISODateWithSlashed} from '../../../utils/DefaultFunctions'

const AddMisRecord = () => {
  const auth = GetCurrentUser()
  const isBranch = auth.type === 'branch'

  let navigate = useNavigate()
  let current_id = new URL(window.location).searchParams.get('id')

  const initialData = {
    cifNumber: '',
    consignmentNo: '',
    customerName: '',
    addressOne: '',
    addressTwo: '',
    cityId: '',
    branchId: '',
    cityName: '',
    cellNo: '',
    cardNo: '',
    branch: '',
    notes: '',
    sendCompanyName: '',
    bkgDate: null,
    bkgDate2: null,
    quantity: '',
    remarks: '',
    remarks2: '',
    status: '',
    status2: '',
    receiverName: '',
    receiverName2: '',
    cnicNo: '',
    rowKey: '',
    relation: '',
    address3: '',
    consignmentNo2: '',
    branch2: '',
    deliveryDate: null,
    deliveryDate2: null,
    waivedOrRecovered: '',
    accountStatusRemarks: '',
    newOrReplacement: '',
    accountNumber: '',
    category: '',
    requestDate: null,
    dispatchDate: null,
    requestChannel: '',
    cityAndCountry: '',
    sambaMDCPanNo: '',
    channel: '',
    accountProduct: '',
    ccy: '',
    returnCN: '',
    returnBranchName: '',
    returnDispatchDate: null,
    finalReturnedCardStatus: '',
    deliveryOrShreddedDate: null,
    region: '',
    returnedBranchRemarks: '',
    cardStatus: '',
    returnedRemarksOPS: '',
    rsAging: 0,
    isActive: true,
    cardTypeId: '',
  }

  const [date, setDate] = useState(dayjs().format())
  const [minDate2, setMinDate2] = useState(dayjs().format())
  const [statusValue, setStatusValue] = useState('')
  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 3)

  const maxDateProduction = new Date()

  const [data, setData] = useState(initialData)
  let branchCardType = [
    {
      id: 'dZ5p5rVQR',
      returnedCardStatus: 'Received at Branch',
      isActive: true,
    },
  ]

  let branchCardTypeV2 = [
    {
      id: 'rL6BbdV1e',
      returnedCardStatus: 'Delivered to customer',
      isActive: true,
    },
    {
      id: 'Me5BO0p0Z',
      returnedCardStatus: 'Re-dispatched to customer address',
      isActive: true,
    },
    {
      id: '4NPB8LB1z',
      returnedCardStatus: 'Send to other branch',
      isActive: true,
    },
    {
      id: '7lqBA3VeA',
      returnedCardStatus: 'Shredded',
      isActive: true,
    },
    {
      id: 'PK0VWOVZ1',
      returnedCardStatus: 'Status Pending',
      isActive: true,
    },
  ]

  const [requestChannels, setRequestChannels] = useState()
  const [cardTypes, setCardTypes] = useState()
  const [regions, setRegions] = useState()
  const [returnCards, setReturnCards] = useState()
  const [branches, setBranches] = useState()
  const [newReplacements, setNewReplacements] = useState()

  // toast functions
  const notifySuccess = (val) =>
    toast.success(`Record ${val} Successfuly!`, {
      position: 'bottom-right',
      theme: 'colored',
    })

  const notifyError = () =>
    toast.error('Something Went Wrong!', {
      position: 'bottom-right',
      theme: 'colored',
    })
  // end toast functions

  const onDateChange = (field, newValue) => {
    setMinDate2(newValue)
    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let convertedDate = new Date(`${selectedDate} UTC`).toISOString()
    setData((prevData) => ({
      ...prevData,
      [field]: convertedDate,
    }))
    // setDateOfPurchasing(convertedDate)
  }

  let user = GetCurrentUser()
  useEffect(() => {
    current_id &&
      API.get(`/UserTaskMIS/getById?Id=${current_id}`).then((res) => {
        setData(res.data.data)
      })

    API.get(`/branch?PageNumber=1&pageSize=100`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setBranches(res.data.data.data)
    })

    API.get(`/UserTaskMIS/finalstatuslist?id=${current_id}&userType=${auth.type}`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      console.log('res status', res)
      setReturnCards(res.data.data)
    })
    // API.get(`/ReturnedCardStatus`, {
    //   headers: {
    //     Authorization: `Bearer ${user.api_token}`,
    //   },
    // }).then((res) => {
    //   setReturnCards(res.data.data.data)
    // })

    API.get(`/CardType`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setCardTypes(res.data.data.data)
    })

    API.get(`/RequestType`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setNewReplacements(res.data.data.data)
    })
    API.get(`/RequestChannel`, {
      headers: {
        Authorization: `Bearer ${user.api_token}`,
      },
    }).then((res) => {
      setRequestChannels(res.data.data.data)
    })

    // API.get(`/region`, {
    //   headers: {
    //     Authorization: `Bearer ${user.api_token}`,
    //   },
    // }).then((res) => {
    //   setRegions(res.data.data.data)
    // })
  }, [])

  const validateFields = () => {
    if (statusValue === 'Received at Branch' && !data.deliveryOrShreddedDate) {
      // notifyError("Please select a date for 'Received at Branch'.")
      toast.error('Received at branch: Date is required!', {
        position: 'bottom-right',
        theme: 'colored',
      })
      return false
    }
    return true
  }

  const updateRecord = () => {
    if (!validateFields()) return
    current_id
      ? API.put(`/UserTaskMIS`, data)
          .then((res) => {
            if (res.status === 201) {
              notifySuccess('Updated')
              setTimeout(() => {
                navigate(`${STRINGS.ROUTES.CFC.VIEW_MIS}`)
              }, 1000)
            } else if (res.status !== 204) {
              // notifyError()
              toast.error('Received at branch: Date is required!', {
                position: 'bottom-right',
                theme: 'colored',
              })
            }
          })
          .catch((error) => {
            if (error.response && error.response.status !== 204) {
              notifyError()
            }
          })
      : API.post(`/UserTaskMIS`, data)
          .then((res) => {
            if (res.status === 201) {
              notifySuccess('Added ')
              initialData('')
            } else if (res.status !== 204) {
              // notifyError()
              toast.error('Received at branch: Date is required!', {
                position: 'bottom-right',
                theme: 'colored',
              })
            }
          })
          .catch((error) => {
            if (error.response && error.response.status !== 204) {
              notifyError()
            }
          })
  }

  console.log('ooo', data)
  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/* <!--begin::Content container-->  */}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/* <!--begin::Row-->             */}
          {/* <!--Mytabcontent start--> */}
          <div class='row g-6 g-xl-9 tab-content' id='myTabContent'>
            {/* <!--Mytabcontent start tab1--> */}
            <div class='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              {/* <!--begin::Col--> */}
              <div class='card mb-5 mb-xl-10'>
                {/* <!--begin::Card header--> */}
                <div
                  class='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  {/* <!--begin::Card title--> */}
                  <div class='card-title m-0'>
                    <h3 class='fw-bold m-0'>{current_id ? 'Update Record' : 'Add Record'}</h3>
                  </div>

                  {/* <!--end::Card title--> */}
                </div>
                <hr />
                {/* <!--begin::Card header--> */}
                {/* <!--begin::Content--> */}
                <div id='kt_account_settings_profile_details' class='collapse show'>
                  {/* <!--begin::Form--> */}
                  <form class='' style={{backgroundColor: 'white', padding: '50px'}}>
                    <h1>Day 1 Info</h1>
                    <hr />
                    <div class='mb-3 my-3' style={{display: 'inline-flex', width: '100%'}}>
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Card Type</strong>
                          </strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              category: e.target.value,
                            }))
                          }
                          value={data.category}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Card Type
                          </option>

                          {cardTypes &&
                            cardTypes.map((item) => {
                              return <option value={item.cardType}>{item.cardType}</option>
                            })}
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CIF/REF</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='123456'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          maxLength={6}
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cifNumber: e.target.value,
                            }))
                          }}
                          value={data.cifNumber}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Request Date</strong>
                          </strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              maxDate={date}
                              minDate={minDate}
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('requestDate', newValue)}
                              value={data.requestDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={isBranch}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Production/Dispatch Date</strong>
                          </strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              minDate={minDate2}
                              onChange={(newValue) => onDateChange('dispatchDate', newValue)}
                              value={data.dispatchDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={isBranch}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Charges Waived or Recovered</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              waivedOrRecovered: e.target.value,
                            }))
                          }
                          value={data.waivedOrRecovered}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Waived or Recovered
                          </option>
                          <option value={'Waived'}>Waived</option>
                          <option value={'Recovered'}>Recovered</option>
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Charges/Reason for Waiver</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='ABC/000'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountStatusRemarks: e.target.value,
                            }))
                          }}
                          value={data.accountStatusRemarks}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Account Number</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='1234567890'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={10}
                          onKeyDown={(e) => {
                            if (e.target.value.length === 10) {
                              e.preventDefault()
                            }
                          }}
                          type='number'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountNumber: e.target.value,
                            }))
                          }}
                          value={data.accountNumber}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Customer Name</strong>
                          </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Abdul Rauf'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              customerName: e.target.value,
                            }))
                          }}
                          value={data.customerName}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>New/Replacement/Renewel</strong>
                          </strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              newOrReplacement: e.target.value,
                            }))
                          }
                          value={data.newOrReplacement}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Request Type
                          </option>
                          {newReplacements &&
                            newReplacements.map((item) => {
                              return <option value={item.requestType}>{item.requestType}</option>
                            })}
                        </select>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Requests Channel</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              requestChannel: e.target.value,
                            }))
                          }
                          value={data.requestChannel}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Request Channel
                          </option>
                          {requestChannels &&
                            requestChannels.map((item) => {
                              return (
                                <option value={item.requestChannel}>{item.requestChannel}</option>
                              )
                            })}
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Name</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              branch: e.target.value,
                            }))
                          }
                          value={data.branch}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Branch Name
                          </option>
                          {branches &&
                            branches.map((item) => {
                              return <option value={item.branchName}>{item.branchName}</option>
                            })}
                        </select>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Account Product</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='SAMBA CURRENT ACCOUNT(PKR)'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              accountProduct: e.target.value,
                            }))
                          }}
                          value={data.accountProduct}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>CCY</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='PKR/USD'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              ccy: e.target.value,
                            }))
                          }}
                          value={data.ccy}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>Address-1</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='House No 123, ABC Society'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              addressOne: e.target.value,
                            }))
                          }}
                          value={data.addressOne}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Address-2</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='House No 123, ABC Society'
                          style={{border: '1px solid darkgrey'}}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              addressTwo: e.target.value,
                            }))
                          }}
                          value={data.addressTwo}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3 requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1 ' class='form-label'>
                          <strong>City & Country</strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Karachi Pakistan'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cityAndCountry: e.target.value,
                            }))
                          }}
                          value={data.cityAndCountry}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Contact Number</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='+92-2678923033'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={30}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cellNo: e.target.value,
                            }))
                          }}
                          value={data.cellNo}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Samba MDC PAN #</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='534564XXXXXX7012'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={16}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              sambaMDCPanNo: e.target.value,
                            }))
                          }}
                          value={data.sambaMDCPanNo}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>
                            <strong>Courier Channel</strong>
                          </strong>
                        </label>
                        <input
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Leoperd/Tcs'
                          style={{border: '1px solid darkgrey'}}
                          maxLength={200}
                          minLength={0}
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              channel: e.target.value,
                            }))
                          }}
                          value={data.channel}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Day 2 Info</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>1st Consignment #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='03218976543'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              consignmentNo: e.target.value,
                            }))
                          }}
                          value={data.consignmentNo}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Day 3 Info</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Receiver CNIC #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='4210156789181'
                          type='text'
                          maxLength={13}
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cnicNo: e.target.value,
                            }))
                          }}
                          value={data.cnicNo}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Received By (Relation)</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Brother/Sister etc'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              relation: e.target.value,
                            }))
                          }}
                          value={data.relation}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Receiver Name / Reason For RTN</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Irfan'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              receiverName: e.target.value,
                            }))
                          }}
                          value={data.receiverName}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>DELIVERY/RTN DATE</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('deliveryDate', newValue)}
                              value={data.deliveryDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={isBranch}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>1st Delivery Status</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='OK'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              status: e.target.value,
                            }))
                          }}
                          value={data.status}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>2nd Consignment #</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='KI866994341'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnCN: e.target.value,
                            }))
                          }}
                          value={data.returnCN}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Name: For Card Send To Branch & CFC</strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              returnBranchName: e.target.value,
                            }))
                          }
                          value={data.returnBranchName}
                          data-placeholder='Select Country'
                          data-hide-search='true'
                          disabled={isBranch}
                        >
                          <option selected hidden>
                            Select Branch Name
                          </option>
                          {branches &&
                            branches.map((item) => {
                              return <option value={item.branchName}>{item.branchName}</option>
                            })}
                        </select>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Address</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='D-103 Model Town Lahore...'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              address3: e.target.value,
                            }))
                          }}
                          value={data.address3}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Date: Dispatched to Br by Courier/CFC</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('returnDispatchDate', newValue)}
                              value={data.returnDispatchDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={isBranch}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Packet QTY</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='1'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              quantity: e.target.value,
                            }))
                          }}
                          value={data.quantity}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Receiver Name</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='Umar'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              receiverName2: e.target.value,
                            }))
                          }}
                          value={data.receiverName2}
                          disabled={isBranch}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Date: Deliver To Br by Courier/CFC</strong>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              onChange={(newValue) => onDateChange('deliveryDate2', newValue)}
                              value={data.deliveryDate2}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={isBranch}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Status: Deliver to Br by Courier/CFC </strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder='OK'
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              status2: e.target.value,
                            }))
                          }}
                          value={data.status2}
                          disabled={isBranch}
                        />
                      </div>
                    </div>

                    <h1 className='my-5'>Other Details</h1>
                    <hr />
                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Final Returned Card Branch Status </strong>
                        </label>
                        <select
                          class='form-select me-3 form-select-solid'
                          data-control='select2'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              finalReturnedCardStatus: e.target.value,
                            }))
                            setStatusValue(e.target.value)
                            console.log('kkk', e.target.value);
                            if(e.target.value === "Shredded"){
                              navigate(`${STRINGS.ROUTES.BRANCH.SHREDFORM}/${current_id}`)
                            }
                            if(e.target.value === "Delivered to customer"){
                              navigate(`${STRINGS.ROUTES.BRANCH.DELIVERYFORM}/${current_id}`)
                            }
                          }}
                          value={data.finalReturnedCardStatus}
                          placeholder='Select Country'
                          data-hide-search='true'
                        >
                          <option selected hidden>
                            Select Return Card Status
                          </option>
                          {/* {data.finalReturnedCardStatus === 'To be received by branch'
                            ? branchCardTypeV2 &&
                              branchCardTypeV2.map((item) => (
                                <option value={item.returnedCardStatus}>
                                  {item.returnedCardStatus}
                                </option>
                              ))
                            : data.status === 'RS' || data.status2 === 'RS'
                            ? branchCardType.map((item) => (
                                <option value={item.returnedCardStatus}>
                                  {item.returnedCardStatus}
                                </option>
                              ))
                            : returnCards &&
                              returnCards.map((item) => (
                                <option value={item.returnedCardStatus}>
                                  {item.returnedCardStatus}
                                </option>
                              ))} */}

                          {returnCards &&
                            returnCards.map((item) => {
                              return <option value={item.description}>{item.description}</option>
                            })}
                        </select>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Received at branch: Date</strong>
                          <span style={{color:"red"}}> *</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} style={{width: '100%'}}>
                            <DesktopDatePicker
                              className='date-height form-control form-control-solid mb-lg-0'
                              inputFormat='MM/DD/YYYY'
                              // value={dateOfPurchasing && dateOfPurchasing}
                              // onChange={onChange3}
                              minDate={dayjs().format()}
                              maxDate={dayjs().format()}
                              onChange={(newValue) =>
                                onDateChange('deliveryOrShreddedDate', newValue)
                              }
                              value={data.deliveryOrShreddedDate}
                              renderInput={(params) => (
                                <TextField style={{height: '44px'}} {...params} />
                              )}
                              disabled={statusValue !== 'Received at Branch' || data.deliveryOrShreddedDate}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Region</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder=''
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              region: e.target.value,
                            }))
                          }}
                          value={data.region}
                        />
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Card Status</strong>
                        </label>
                        <input
                          style={{border: '1px solid darkgrey'}}
                          class='form-control form-control-solid flatpickr-input'
                          placeholder=''
                          type='text'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              cardStatus: e.target.value,
                            }))
                          }}
                          value={data.cardStatus}
                        />
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>CFC Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnedRemarksOPS: e.target.value,
                            }))
                          }}
                          value={data.returnedRemarksOPS}
                          disabled={isBranch}
                        ></textarea>
                      </div>

                      <div className='ms-5' style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Branch Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              returnedBranchRemarks: e.target.value,
                            }))
                          }}
                          value={data.returnedBranchRemarks}
                        ></textarea>
                      </div>
                    </div>

                    <div
                      class='my-5 mb-3  requestformitem'
                      style={{display: 'inline-flex', width: '100%'}}
                    >
                      <div style={{width: '500%'}}>
                        <label for='exampleInputEmail1' class='form-label'>
                          <strong>Remarks</strong>
                        </label>
                        <textarea
                          class='form-control form-control-solid'
                          style={{border: '1px solid darkgrey'}}
                          id='exampleFormControlTextarea1'
                          rows='5'
                          onChange={(e) => {
                            setData((prevData) => ({
                              ...prevData,
                              remarks: e.target.value,
                            }))
                          }}
                          value={data.remarks}
                        ></textarea>
                      </div>
                    </div>

                    <button
                      type='button'
                      style={{float: 'right'}}
                      class='mb-5 my-5 btn btn-primary requestformitem'
                      onClick={(e) => {
                        updateRecord()
                      }}
                    >
                      {current_id ? 'Update' : 'Add'}
                    </button>
                  </form>
                  {/* <!--end::Form--> */}
                </div>
                {/* <!--end::Content--> */}
              </div>
            </div>
          </div>
          {/* <!--Mytabcontent end--> */}
          {/* <!--end::Row--> */}
          {/* <!--begin::Table--> */}
        </div>
        {/* <!--end::Content container--> */}
      </div>
      <ToastContainer />
    </>
  )
}

export default AddMisRecord
