import React, {useState, useEffect} from 'react'
import {DataGrid} from '@mui/x-data-grid'
import axios from 'axios'
import {Button} from '@mui/material'
import {Params} from 'react-router-dom'
import {GetCurrentUser} from '../../../utils/DefaultFunctions'
import {API} from '../../../utils/services'

// { id: 1, col1: 'Hello', title: 'World', subject:'Physics', author:'Mark Twain', edition: '16th', language: 'English', bookType: 'Physical', publishingYear: '2016', publisher: 'Oxford' },

const CityTable = ({data, type, getDataById, DeleteItem}) => {
  // const [data, setData] = useState()

  const handleClick = (event, cellValues) => {
    console.log(cellValues)
  }

  const rows = []
  const cities = []
  const provinces = []
  const regions = []

  let user = GetCurrentUser()

  const cardTypeColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'cardType', headerName: 'Card Type', width: 150},
    {
      field: 'action1',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const newReplacementColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'requestType', headerName: 'New/Replacement', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const requestChannelColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'requestChannel', headerName: 'Request Channel', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const departmentColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'department', headerName: 'Department', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const designationColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'designation', headerName: 'Designation', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const regionColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'region', headerName: 'Region', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const returnCardColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'returnedCardStatus', headerName: 'Return Card Status', width: 150},
    {
      field: 'action1',
      headerName: 'Edit',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Delete',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const countryColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {
      field: 'action1',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const provinceColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'country', headerName: 'Country', width: 150},
    {field: 'province', headerName: 'Province', width: 150},
    {
      field: 'action1',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  const cityColumns = [
    {field: 'SNo', headerName: 'S.no', width: 50, border: '2px solid black'},
    {field: 'region', headerName: 'Region', width: 150},
    {field: 'city', headerName: 'City', width: 150},
    {
      field: 'action1',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: '#18A689'}}
            onClick={() => {
              // AddButton(false)
              getDataById(e.id)
            }}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'action2',
      headerName: 'Action',
      renderCell: (e) => (
        <strong>
          <Button
            //   className='btn btn-primary btn-sm'
            variant='contained'
            size='small'
            style={{backgroundColor: 'red'}}
            onClick={() => {
              // AddButton(false)
              const userConfirmed = window.confirm('Are you sure you want to delete this record?')
              if (userConfirmed) {
                DeleteItem(e.id)
              }
            }}
          >
            Delete
          </Button>
        </strong>
      ),
    },
  ]

  if (type) {
    data?.forEach((element, index) => {
      rows.push({
        id: element.id,
        SNo: index + 1,
        cardType: element.cardType,
        requestType: element.requestType,
        region: element.region,
        requestChannel: element.requestChannel,
        returnedCardStatus: element.returnedCardStatus,
        designation: element.designation,
        department: element.department,
        country: element.country,
        province: element.province,
        city: element.city,
      })
    })
  }

  return (
    <div style={{height: '70vh', width: '100%'}}>
      {type == 'cardType' && (
        <DataGrid rows={rows} columns={cardTypeColumns} showCellRightBorder={true} />
      )}

      {type == 'returnCard' && (
        <DataGrid rows={rows} columns={returnCardColumns} showCellRightBorder={true} />
      )}

      {type == 'region' && (
        <DataGrid rows={rows} columns={regionColumns} showCellRightBorder={true} />
      )}

      {type == 'requestChannel' && (
        <DataGrid rows={rows} columns={requestChannelColumns} showCellRightBorder={true} />
      )}

      {type == 'newReplacement' && (
        <DataGrid rows={rows} columns={newReplacementColumns} showCellRightBorder={true} />
      )}

      {type == 'department' && (
        <DataGrid rows={rows} columns={departmentColumns} showCellRightBorder={true} />
      )}
      {type == 'designation' && (
        <DataGrid rows={rows} columns={designationColumns} showCellRightBorder={true} />
      )}
      {type == 'country' && (
        <DataGrid rows={rows} columns={countryColumns} showCellRightBorder={true} />
      )}
      {type == 'province' && (
        <DataGrid rows={rows} columns={provinceColumns} showCellRightBorder={true} />
      )}
      {type == 'city' && <DataGrid rows={rows} columns={cityColumns} showCellRightBorder={true} />}
    </div>
  )
}

export default CityTable
