import React from 'react'
import * as ReactBootstrap from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner';

const Loader = () => {
  return (
    <Spinner className='my-3' animation='border' role='status' style={{justifyContent: 'center' , width: '100px', height: '100px', marginLeft: '45%'}}>
      <span className='visually-hidden' >Loading...</span>
    </Spinner>
  )
}

export default Loader
