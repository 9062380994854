import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {
  convertISODate,
  convertISOTime,
  GetCurrentUser,
  convertISODateWithSlashed,
} from '../../../../../utils/DefaultFunctions'
import {STRINGS} from '../../../../../utils/Enums'
import {API} from '../../../../../utils/services'
import EditForm from '../forms/EditForm'
import moment from 'moment'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {ResetState} from '../../BranchSlice/branch.slice'
import Loader from '../../../../SharedComponent/Loader/Loader'
import MisTable from '../../../../SharedComponent/DataTable/MisTable'
import {Button, Modal} from 'antd'
import {DatePicker, Space} from 'antd'
import dayjs from 'dayjs'

const MisMain = () => {
  let auth = GetCurrentUser()
  const [description, setDescription] = useState()
  const [taskDate, setTaskDate] = useState(dayjs('2023-01-04T00:00:00'))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    addTask()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const [addTaskUpdate, setAddTaskUpdate] = useState(false)

  const addTaskFunc = (value) => {
    setAddTaskUpdate(value)
  }
  // add task function API
  const addTask = () => {
    API.post(`UserTask`, {
      description: description,
      taskDate: taskDate,
    }).then((res) => {
      setTaskDate('')
      setAddTaskUpdate(!addTaskUpdate)
    })
  }

  const onChange = (newValue) => {
    let selectedDate = convertISODateWithSlashed(new Date(newValue))
    let convertedDate = new Date(`${selectedDate} UTC`).toISOString()
    setTaskDate(convertedDate)
  }

  return (
    <>
      <div id='kt_app_content' class='app-content flex-column-fluid'>
        {/*<!--begin::Content container-->*/}
        <div id='kt_app_content_container' class='app-container container-xx'>
          {/*<!--begin::Tables Widget 12-->*/}
          <div class='card mb-5 mb-xl-8'>
            <div class='card-header border-0 pt-5' style={{height: '10px'}}>
              <h3 class='card-title align-items-start flex-column'>
                <span class='card-label fw-bold fs-3 mb-1'>MIS</span>
              </h3>
              <div class='card-toolbar'>
                <div class='d-flex align-items-center gap-2 gap-lg-3'>
                  <div class='d-flex align-items-center position-relative my-1'>
                    {auth.type === 'cfc' && (
                      <Button
                        style={{backgroundColor: '#18A689'}}
                        type='primary'
                        onClick={showModal}
                      >
                        Add Task
                      </Button>
                    )}
                    <Modal
                      title='Create Task'
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={[
                        <Button key='back' onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button
                          style={{backgroundColor: '#18A689'}}
                          key='submit'
                          type='primary'
                          onClick={handleOk}
                        >
                          Create Task
                        </Button>,
                      ]}
                    >
                      <hr />
                      <div class='d-flex flex-column  my-6 fv-row fv-plugins-icon-container'>
                        {/* <!--begin::Label--> */}
                        <label class='fs-6 fw-semibold form-label mb-2'>Task Name</label>
                        {/* <!--end::Label--> */}
                        {/* <!--begin::Input wrapper--> */}
                        <div class='position-relative'>
                          {/* <!--begin::Input--> */}
                          <input
                            type='text'
                            class='form-control form-control-solid'
                            placeholder='Enter Task name'
                            name='card_number'
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                          />
                          {/* <!--end::Input--> */}
                          {/* <!--begin::Card logos--> */}
                          <div class='position-absolute translate-middle-y top-50 end-0 me-5'>
                            <img
                              src='/html/demo1/dist/assets/media/svg/card-logos/visa.svg'
                              alt=''
                              class='h-25px'
                            />
                            <img
                              src='/html/demo1/dist/assets/media/svg/card-logos/mastercard.svg'
                              alt=''
                              class='h-25px'
                            />
                            <img
                              src='/html/demo1/dist/assets/media/svg/card-logos/american-express.svg'
                              alt=''
                              class='h-25px'
                            />
                          </div>
                          {/* <!--end::Card logos--> */}
                        </div>
                        {/* <!--end::Input wrapper--> */}
                        <div class='fv-plugins-message-container invalid-feedback'></div>
                      </div>

                      <div class='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
                        {/* <!--begin::Label--> */}
                        <label class='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span class=''>Date</span>
                        </label>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3} >
                                <DesktopDatePicker
                                className='form-control form-control-solid  mb-3 mb-lg-0'
                                inputFormat="MM/DD/YYYY"
                                value={taskDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField  {...params} />}
                                />
                            </Stack>
                          </LocalizationProvider> */}
                        <Space direction='vertical' style={{backgroundColor: 'white'}}>
                          <DatePicker
                            className='form-control form-control-solid  mb-3 mb-lg-0'
                            onChange={onChange}
                            showTimezone
                          />
                        </Space>
                        <div class='fv-plugins-message-container invalid-feedback'></div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
            {/*<!--end::Header-->*/}
            <hr />
            {/*<!--begin::Body-->*/}
            <div className='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
              <div className='card' style={{height: '100%'}}>
                {/*<!--begin::Body-->*/}
                <div className='card-body py-3 '>
                  {/*<!--begin::Table container-->*/}
                  {/* <FilterMenu /> */}

                  <div className='table-responsive my-5' style={{height: '70vh', overflow: 'auto'}}>
                    {/*<!--begin::Table-->*/}
                    <MisTable addTaskUpdate={addTaskUpdate} />
                    {/*<!--end::Table-->*/}
                  </div>
                  {/*<!--end::Table container-->*/}
                </div>
                {/*<!--begin::Body-->*/}
              </div>
            </div>
            {/*<!--begin::Body-->*/}
          </div>
        </div>
        {/*<!--end::Content container-->*/}
      </div>
    </>
  )
}

export default MisMain
