import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {GetCurrentUser} from '../../../../utils/DefaultFunctions'
import {useAppSelector} from '../../../../utils/hook'
import {API} from '../../../../utils/services'
import {resetDashboard} from '../../../GlobalSlices/global.slice'
import {CaptureBranch} from '../../../pages/BranchMain/BranchSlice/branch.slice'

// type Slices = { authSlice: any, globalSlice: any };

const BranchSelect = ({onSelect}) => {
  const [currentBranchId, setCurrentBranchId] = useState('')
  const [captureBranches, setCaptureBranches] = useState([])

  const {authSlice, globalSlice} = useAppSelector(({Reducers}) => Reducers)

  const dispatch = useDispatch()

  let user = GetCurrentUser()

  useEffect(() => {
    if (authSlice !== null) {
      API.get('/Branch/getCurrentUserBranches', {
        headers: {Authorization: `Bearer ${authSlice.token}`},
      }).then((res) => {
        console.log('branchess all', res)
        setCaptureBranches(res.data.data)
      })
    }
  }, [authSlice.token])

  useEffect(() => {
    // console.log('ÜSERRRRR', authSlice.token)
    if (currentBranchId) {
      dispatch(CaptureBranch(currentBranchId))
      // setLo
    }
  }, [currentBranchId])

  // console.log(select);

  return (
    <>
      <form class='form w-100' novalidate='novalidate' id='kt_sign_in_form' action='#'>
        <div class='text-center mb-11'>
          <h1 class='text-dark fw-bolder mb-3'>Branch Select</h1>
        </div>

        <div class='fv-row mb-8'>
          <select
            onChange={(e) => {
              dispatch(resetDashboard())
              setCurrentBranchId(e.target.value)
              const selectedBranch = captureBranches.find((branch) => branch.id === e.target.value)
              if (selectedBranch) {
                console.log('Selected Branch:', selectedBranch.branchName)
              }
            }}
            name='status'
            aria-label='Select a Currency'
            data-placeholder='Select a currency..'
            class='form-select form-select-solid form-select-lg '
            data-select2-id='select2-data-121-2l6u'
            tabindex='-1'
            aria-hidden='true'
          >
            <option selected hidden>
              Select Branch
            </option>
            {captureBranches &&
              captureBranches.map((item, index) => {
                return (
                  <>
                    <option value={item.id}>{item.branchName}</option>
                  </>
                )
              })}
          </select>
        </div>

        <br />
        <div class='d-grid mb-10'>
          <div
            class='btn btn-primary me-2 mb-2'
            onClick={() => {
              if (!currentBranchId) {
                // alert("Please select a branch first.");
                return;
              }
          
              onSelect()
              const selectedBranch = captureBranches.find((branch) => branch.id === currentBranchId)
              if (selectedBranch) {
                sessionStorage.setItem('selectedBranchName', selectedBranch.branchName)
                // console.log(
                //   'Saved selected branch name to session storage:',
                //   selectedBranch.branchName
                // )
              }
            }}
          >
            Proceed
          </div>
          {/* <div class='btn btn-primary me-2 mb-2' 
          onClick={Proceed}
          >
            Proceed
          </div> */}
        </div>
      </form>
    </>
  )
}

export default BranchSelect
