import React from 'react';
import DefaultTitleBar from '../../SharedComponent/DefaultTitleBar/DefaultTitleBar';
import Main from './items/Main';
import { Link } from 'react-router-dom';
import { STRINGS } from '../../../utils/Enums';
import Button from '@mui/material/Button';
import { API } from '../../../utils/services';
import { GetCurrentUser } from '../../../utils/DefaultFunctions';



const tabs = [`Shred`, `Delivery`, `Dispatch`];


const ComplainBranchDefault = () => {
    let user = GetCurrentUser()
    const exportReportFunc = (type) => {
        API.post(`/${type}/export/excel`, {
            "customerName": "",
            "cellNo": "",
            "cardNo": "",
            "cnicNo": "",
            "cityId": "",
            "customerCityId": "",
            "branchId": "",
            "branch": "",
            "accountNo": ""
        }, {
            headers: {
                Authorization: `Bearer ${user.api_token}`,
            }
        })


            .then((res) => {
                console.log("excelData", res)
                console.log(res.data.success)
                if (res.data.success) {
                    //TODO: excel export work
                    const link = document.createElement('a')
                    link.href = `${res.data.data}`
                    link.download = `${type}Report.xlsx`
                    link.target = '_blank'
                    link.click()
                }
            })
            .catch((err) => {
                console.log(err, 'err')
            })
    }

    const PageRightTitleContent = () => {
        return (
            <div class="d-flex align-items-center gap-2 gap-lg-3">
                {/*<!--begin::Filter menu-->*/}
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    {/*<!--begin::Secondary button-->*/}
                    {/*<!--end::Secondary button-->*/}
                    {/*<!--begin::Primary button-->*/}
                    {/* <DefaultButton label={"Add Card"} onClick={() => {
                    setInit({
                        ...init,
                        modalType: 1,
                        showAddModal: true
                    })
                }} /> */}
                    <Link to={`${STRINGS.ROUTES.BRANCH.ADD_COMPLAINS}`} className="btn btn-primary btn-sm">Add Complains</Link>
                    {/*<!--end::Primary button-->*/}
                    <Button
                        className='text-white ms-3'
                        style={{ backgroundColor: '#18A689' }}
                        type='primary'
                        onClick={() => exportReportFunc('Complaint')}
                    >
                        Export To Excel
                    </Button>






                </div>
            </div>
        )
    }






    return (
        <>
            <DefaultTitleBar title="Complains" showActions={true} rightContent={PageRightTitleContent} />
            <Main tabs={tabs} />
        </>
    );
};

export default ComplainBranchDefault;